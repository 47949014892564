import { Box, IconButton, Typography } from "@mui/material";

import { useSelector } from "react-redux";
import {
  GlobalState,
  authorizedFetch,
  drawer,
  getDarkModePreference,
} from "utils";

import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { useState } from "react";

import { useQuery } from "react-query";

import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import AddModelDetails from "./AddModelDetails";
import { RETAIL_URL } from "utils/constants";

const InfoDrawer = ({ model, companies }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );
  const [modalopen, setModalOpen] = useState({
    open: false,
    data: { companyId: "", modelId: "" },
  });

  const variantsUrl = `${RETAIL_URL}/assembly/model/${model._id}/variants`;
  const { data: variantsData } = useQuery(["getModelVariants", model], () =>
    authorizedFetch(variantsUrl),
  );

  const table = [
    { header: "Basic Information" },
    {
      label: "Company Name",
      value: companies.find((el: any) => el._id === model.company)?.name || "-",
    },
    {
      label: "Vehicle Model",
      value: model?.name || "-",
    },
    {
      label: "No. Variants",
      value: model?.variants?.length || 0,
    },
    { header: "Variants" },
  ];

  console.log(model);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {model?.name || "-"}
          <IconButton
            children={<CloseIcon />}
            color="inherit"
            size="small"
            onClick={() => drawer.close()}
          />
        </Box>
        <Box flexGrow={1} overflow="auto" pb={4}>
          <Box
            sx={{
              px: 3,
              pt: 2.5,
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.5,
                  px: 2,
                  "&.secondary": {
                    color: "text.secondary",
                  },
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  position: "relative",

                  "& td": {
                    py: 2,
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 8.5,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {table.map(({ header, label, value }, i) => {
                  const isFirst = table[i - 1]?.header;
                  const isLast = !table[i + 1] || table[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td
                          colSpan={2}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            ...(header === "Variants" && {
                              padding: "10px 20px 10px 20px",
                            }),
                          }}
                        >
                          <span>{header.toUpperCase()}</span>
                          {header === "Variants" && (
                            <Box>
                              <IconButton
                                children={<EditIcon />}
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setModalOpen({
                                    open: true,
                                    data: {
                                      companyId: model.company,
                                      modelId: model._id,
                                    },
                                  });
                                }}
                              />
                            </Box>
                          )}
                        </td>
                      ) : (
                        <>
                          <td className="bold">{label}</td>
                          <td
                            className={
                              value === "Loading..." ? "secondary" : ""
                            }
                            style={{ width: "50%" }}
                          >
                            {value}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
                {variantsData?.data?.length !== 0 ? (
                  variantsData?.data?.map((variant: any, i: number) => {
                    return (
                      <Box mt={9} ml={2}>
                        <Typography fontSize={14}>
                          {i + 1}. {variant.name}
                        </Typography>

                        <img
                          src={variant.icon}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "contain",
                          }}
                          alt={`Variant ${i + 1}`}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Box mt={9} ml={2}>
                    <Typography sx={{ fontSize: 14, color: "#00000050" }}>
                      No variants added yet
                    </Typography>
                  </Box>
                )}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
      <AddModelDetails
        open={modalopen.open}
        handleClose={() =>
          setModalOpen({ open: false, data: { companyId: "", modelId: "" } })
        }
        companyId={modalopen.data.companyId}
        modelId={modalopen.data.modelId}
      />
    </>
  );
};

export default InfoDrawer;
