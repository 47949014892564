import {
  Autocomplete,
  // Avatar,
  Box,
  Button,
  CircularProgress,
  // Button,
  // MenuItem,
  Paper,
  // Select,
  // Switch,
  TextField,
  Typography,
  // Typography,
  // Typography,
  useTheme,
} from "@mui/material";
// import { gql, useQuery as gqlUseQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Breadcrumbs from "components/Breadcrumbs";
import {
  // DoDisturbAlt,
  ExploreOutlined,
  // Info,
  InfoOutlined,
  LockOpenOutlined,
  LockOutlined,
  // LockOpenOutlined,
  // LockOutlined,
  // PowerSettingsNewOutlined,
} from "@mui/icons-material";
import { LEASE_URL, RETAIL_URL } from "utils/constants";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";

// const GET_VEHICLES = gql`
//   query VehicleSnapshotV2($where: VehicleSnapshotInput!) {
//     track {
//       vehicleSnapshotV2(where: $where) {
//         ignition {
//           lock
//         }
//       }
//     }
//   }
// `;

const RemoteControl = () => {
  const [assetList, setAssetList] = useState<any>([]);
  const [selectedAsset, setSelectedAsset] = useState<string | null>(null);
  const [vehicleId, setVehicleId] = useState<string | null>(null);
  const [lockChecked, setLockChecked] = useState(false);
  const [unlockChecked, setUnlockChecked] = useState(false);

  const [isLocked, setIsLocked] = useState<boolean | null>(null);

  // const {
  //   data: snapData,
  //   loading,
  //   refetch,
  // } = gqlUseQuery(GET_VEHICLES, {
  //   variables: {
  //     where: {
  //       vin: selectedAsset || "",
  //     },
  //   },
  // });

  // useEffect(() => {
  //   if (snapData && snapData?.track?.vehicleSnapshotV2?.ignition?.[0]?.lock) {
  //     setIsLocked(snapData?.track?.vehicleSnapshotV2?.ignition?.[0]?.lock);
  //   }
  // }, [snapData]);

  // useEffect(() => {
  //   if (selectedAsset) {
  //     console.log(snapData);
  //   }
  //   // eslint-disable-next-line
  // }, [selectedAsset]);

  // console.log(loading);

  // useEffect(() => {
  //   if (assetData) {
  //     let arr: any = [];
  //     // eslint-disable-next-line
  //     assetData?.vehicles?.getAll?.map((el: any) => {
  //       arr.push(el.vin);
  //     });
  //     setAssetList(arr);
  //   }
  // }, [assetData]);

  const vehiclesUrl = `${LEASE_URL}/company/vehicles?all=true`;

  const { isLoading, data: vehiclesData } = useQuery("getVehicles", () =>
    authorizedFetch(vehiclesUrl)
  );

  // const snapshotUrl = `${DATAFEED_URL}/v2/vehicleSnapshot?token=1234&vin=${selectedAsset}`;

  // const {
  //   isLoading: snapshotLoading,
  //   data: snapshotData,
  //   refetch: refetchSnapshot,
  // } = useQuery(["getSnapshot", selectedAsset], () =>
  //   fetch(snapshotUrl).then((res) => res.json())
  // );

  const snapshotUrl = `${RETAIL_URL}/vehicles/snapshot/${selectedAsset}`;

  const {
    isLoading: snapshotLoading,
    data: snapshotData,
    refetch: refetchSnapshot,
  } = useQuery(
    ["getVMSTrip", "getSnapshot", selectedAsset],
    () => authorizedFetch(snapshotUrl),
    {
      enabled: selectedAsset !== null,
    }
  );

  useEffect(() => {
    if (snapshotData && snapshotData?.data[0]?.ignition?.ignition) {
      if (snapshotData?.data[0]?.ignition?.ignition === 0) {
        setIsLocked(true);
      } else {
        setIsLocked(false);
      }
    }
  }, [snapshotData]);

  useEffect(() => {
    if (vehiclesData?.data?.vehicles?.constructor === Array) {
      let arr = vehiclesData.data.vehicles
        .map((el: any) => ({
          vin: el.vin,
          protocol: el?.model?.protocol,
          id: el?._id,
        }))
        .sort((a: any, b: any) => a.vin.localeCompare(b.vin));

      console.log(vehiclesData);

      setAssetList(arr);
    }
  }, [vehiclesData]);

  const lockURL = `${LEASE_URL}/vehicles/${selectedAsset}/lock`;

  const mutationLock = useMutation(
    `lockVehicle`,
    () =>
      authorizedFetch(lockURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (String(res.msg || "").includes("SUCCESS")) {
          snackbar.success(`Asset Locked`);
          setTimeout(() => {
            refetchSnapshot();
          }, 1000);
        } else {
          snackbar.error(res.msg);
        }
      },
      onError: () => {
        snackbar.error(`Error locking asset`);
        setLoader(false);
      },
    }
  );

  const unlockURL = `${LEASE_URL}/vehicles/${selectedAsset}/unlock`;

  const mutationUnlock = useMutation(
    `unlockVehicle`,
    () =>
      authorizedFetch(unlockURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (String(res.msg || "").includes("SUCCESS")) {
          snackbar.success(`Asset Unocked`);
          setTimeout(() => {
            refetchSnapshot();
          }, 1000);
        } else {
          snackbar.error(res.msg);
        }
      },
      onError: () => {
        snackbar.error(`Error locking asset`);
        setLoader(false);
      },
    }
  );

  const locateURL = `${LEASE_URL}/vehicles/${selectedAsset}/locate`;

  const mutationLocate = useMutation(
    `locateVehicle`,
    () =>
      authorizedFetch(locateURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: (data) => {
        if (String(data.msg || "").includes("SUCCESS")) {
          snackbar.success(`Asset Location Activated`);
          // refetch();
          console.log(data);
        } else {
          snackbar.error(data.msg);
          console.log(data);
          // refetch();
        }
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error locating asset`);
        setLoader(false);
      },
    }
  );

  useEffect(() => {
    if (selectedAsset && lockChecked) {
      setLoader(true);
      mutationLock.mutate();
    }
    // eslint-disable-next-line
  }, [lockChecked]);

  useEffect(() => {
    if (selectedAsset && unlockChecked) {
      setLoader(true);
      mutationUnlock.mutate();
    }
    // eslint-disable-next-line
  }, [unlockChecked]);

  useEffect(() => {
    if (selectedAsset) {
      let current = assetList.filter((el: any) => {
        return el.vin === selectedAsset;
      });

      setVehicleId(current?.[0]?.id || "");
    }
    // eslint-disable-next-line
  }, [selectedAsset]);

  // useEffect(() => {
  //   if (snapData) {
  //     refetch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [snapData]);

  const url = `${RETAIL_URL}/assembly/vehicle/${vehicleId}`;

  const { data: vehicleData } = useQuery(
    ["getVehicle", vehicleId],
    () => authorizedFetch(url),
    {
      enabled: vehicleId !== null,
    }
  );

  const deviceType =
    vehicleData?.data[0]?.components?.find(
      (component: any) => component.category === "TELEMATICS"
    )?.id?.parentModel?.key || "";

  const theme = useTheme();

  return (
    <>
      <Box
        width={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Breadcrumbs />
      </Box>
      <Box
        sx={{
          display: "flex",
          "& > .MuiPaper-root": {
            borderRadius: 2,
            boxShadow: (theme) => theme.customShadows.small,
          },
        }}
      >
        <Paper
          sx={{
            p: 3,
            mt: 2,
            width: { xs: "100%", md: "50%" },
            maxWidth: 350,
          }}
        >
          <Autocomplete
            sx={{ my: 1 }}
            loading={isLoading}
            disablePortal
            value={selectedAsset}
            onChange={(e, newValue) => setSelectedAsset(newValue)}
            options={assetList.map((el: any) => el.vin)}
            getOptionLabel={(option: any) => option}
            renderInput={(params) => (
              <TextField {...params} label="Select Asset" />
            )}
          />
          {selectedAsset && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                // // gap: 3,
                mt: 4,
                // mb: 1,
              }}
            >
              {/* <Box
              sx={{
                height: 200,
                borderRadius: 2,
                border: "1px solid #00000020",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar variant="icon" className="icon">
                <LockOpenOutlined sx={{ fontSize: 22 }} />
              </Avatar>
              <Typography className="label" mt={2} sx={{ fontSize: 15 }}>
                Unlock
              </Typography>
              <Typography sx={{ fontSize: 12 }}>Off</Typography>
              <Switch
                sx={{ mt: 1 }}
                disabled={[selectedAsset].includes("")}
                onChange={() => {
                  setUnlockChecked(!unlockChecked);
                }}
              />
            </Box>
            <Box
              sx={{
                height: 200,
                borderRadius: 2,
                border: "1px solid #00000020",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar variant="icon" className="icon">
                <LockOutlined sx={{ fontSize: 22 }} />
              </Avatar>
              <Typography className="label" mt={2} sx={{ fontSize: 15 }}>
                Lock
              </Typography>
              <Typography sx={{ fontSize: 12 }}>Off</Typography>
              <Switch
                sx={{ mt: 1 }}
                onChange={() => {
                  setLockChecked(!lockChecked);
                }}
                disabled={[selectedAsset].includes("")}
              />
            </Box>
            <Box
              sx={{
                height: 200,
                borderRadius: 2,
                border: "1px solid #00000020",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar variant="icon" className="icon">
                <ExploreOutlined sx={{ fontSize: 22 }} />
              </Avatar>
              <Typography className="label" mt={2} sx={{ fontSize: 15 }}>
                Location
              </Typography>
              <Typography sx={{ fontSize: 12 }}>Off</Typography>
              <Switch sx={{ mt: 1 }} disabled={[selectedAsset].includes("")} />
            </Box>  */}
              {snapshotLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    width: 1,
                    height: "60px",
                    gridColumn: "span 2",
                  }}
                >
                  <CircularProgress
                    color="primary"
                    sx={{ maxWidth: 15, maxHeight: 15 }}
                  />
                </Box>
              ) : (
                <>
                  {Object.keys(snapshotData?.data[0]?.ignition).length !== 0 ? (
                    isLocked ? (
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => {
                          setUnlockChecked(!unlockChecked);
                        }}
                        sx={{
                          gridColumn: "span 2",
                          backgroundColor: theme.customColors.green,
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.customColors.green,
                          },
                        }}
                      >
                        <LockOpenOutlined
                          sx={{ fontSize: 16, mr: 0.5, mt: -0.5 }}
                        />
                        Unlock
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => {
                          setLockChecked(!lockChecked);
                        }}
                        sx={{
                          gridColumn: "span 2",
                          backgroundColor: theme.customColors.redSecondary,
                          color: "#fff",
                          borderColor: theme.customColors.redSecondary,
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.customColors.redSecondary,
                            borderColor: theme.customColors.redSecondary,
                          },
                        }}
                      >
                        <LockOutlined
                          sx={{ fontSize: 16, mr: 0.5, mt: -0.5 }}
                        />
                        Lock
                      </Button>
                    )
                  ) : (
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        setLockChecked(!lockChecked);
                      }}
                      disabled
                      sx={{
                        gridColumn: "span 2",
                      }}
                    >
                      <LockOutlined sx={{ fontSize: 16, mr: 0.5, mt: -0.5 }} />
                      Lock
                    </Button>
                  )}
                  {Object.keys(snapshotData?.data[0]?.ignition).length !== 0 ? (
                    <Button
                      variant="text"
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        fontSize: 12,
                        width: 75,
                      }}
                      onClick={() => {
                        refetchSnapshot();
                        // console.log(loading);
                      }}
                    >
                      Refresh
                    </Button>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: 12,
                        mt: 1,
                        opacity: 0.7,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <InfoOutlined sx={{ fontSize: 12, mr: 0.5 }} /> Device
                      Unavailable
                    </Typography>
                  )}
                </>
              )}

              {["TBIT", "ICONCOX", "LCE13"].includes(deviceType) && (
                <Button
                  variant="outlined"
                  sx={{ gridColumn: "span 2" }}
                  size="large"
                  onClick={() => {
                    setLoader(true);
                    mutationLocate.mutate();
                  }}
                >
                  <ExploreOutlined sx={{ fontSize: 16, mr: 0.5, mt: -0.5 }} />
                  LOCATE
                </Button>
              )}
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default RemoteControl;
