import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import TableComponent from "components/Table";

import moment from "moment";

import { drawer } from "utils";
import DrawerContent from "./DrawerContent";
import { InfoOutlined } from "@mui/icons-material";
import Search from "components/Search";

const List = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  setSearch,
  tab,
  diagnosticsDataV2,
  diagnosticsDataV3,
  diagnosticsDataLoadingV2,
  diagnosticsDataLoadingV3,
}: any) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Paper
      sx={{
        borderRadius: "14px",
        boxShadow: (theme) => theme.customShadows.card,
        pt: 3,
      }}
    >
      <Box
        sx={{
          px: isMdDown ? 1 : 2.5,
          pb: 2.5,
          display: isMdDown ? "" : "flex",
          flexDirection: isMdDown ? "" : "row-reverse",
          width: "100%",
        }}
      >
        <Search
          handleSearch={setSearch}
          persist
          enableClear
          placeholder="Search a Mac Id or Phone Number..."
          width={230}
        />
      </Box>
      <TableComponent
        px={isMdDown ? 1 : 2.5}
        idKey="_id"
        rowCount={diagnosticsDataV2?.data?.documentCount || 0}
        serverSidePagination={true}
        activePage={page}
        activePageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        loading={
          tab === 0 ? diagnosticsDataLoadingV3 : diagnosticsDataLoadingV2
        }
        rows={
          tab === 0
            ? diagnosticsDataV3?.data?.data
            : diagnosticsDataV2?.data?.data || []
        }
        columns={[
          {
            key: "bleMac",
            label: "Mac Id",
          },
          {
            key: "userId",
            label: "User ID",
          },
          ...(tab === 0
            ? [
                {
                  key: "chargerUID",
                  label: "UID",
                },
                {
                  key: "communicationTypeSelectedToDiagnose",
                  label: "Comm Type",
                },
              ]
            : []),
          {
            key: "phoneNumber",
            label: "Phone Number",
            format: (value) => value || "N/A",
          },
          {
            key: "chargerModel",
            label: "Charger Model",
            format: (value) => value || "N/A",
          },
          ...(tab === 0
            ? [
                {
                  key: "diagnosticsTestStatus.reportType",
                  label: "Test Status",
                },
              ]
            : [
                {
                  key: "testsCompleted",
                  label: "Tests Completed",
                  Render: (row: any) => {
                    let passCount = 0;
                    let failCount = 0;

                    for (let key in row?.data) {
                      if (row?.data[key] === "PASS") {
                        passCount++;
                      } else if (row?.data[key] === "FAIL") {
                        failCount++;
                      }
                    }

                    return (
                      <>
                        {passCount} / {passCount + failCount}
                      </>
                    );
                  },
                },
              ]),

          {
            key: "createdAt",
            label: "time",
            format: (value) => moment(value).format("Do MMM, hh:mm a"),
          },

          {
            key: "actions",
            label: "Actions",
            Render: (row: any) => {
              return (
                <Box display="flex">
                  <Tooltip title="Info" followCursor>
                    <IconButton
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={() => drawer.open(<DrawerContent data={row} />)}
                    >
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            },
          },
        ]}
      />
    </Paper>
  );
};

export default List;
