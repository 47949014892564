import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Add, HighlightOff, Remove } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Hidden,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  GlobalState,
  titleCase,
} from "utils";

import { LEASE_URL } from "utils/constants";
import { useQuery } from "react-query";
import DeleteDialog from "./DeleteDialog";

import Table from "components/Table";
import moment from "moment";
import AssignDialog from "./AssignDialog";
import UnAssignVehicles from "./UnAssignVehicles";
import Search from "components/Search";

const InfoDrawer = ({
  vendor,
  refetchVendors,
  refetchVendorCounts,
  refetchGraphVendors,
}: any) => {
  const [table, setTable] = useState([
    { header: "Company Info" },
    { label: "Company Name", value: "" },

    { label: "Phone", value: "" },
    { label: "Address", value: "" },
    { label: "City/District", value: "" },
    { label: "State", value: "" },
    { label: "Pincode", value: "" },
    { label: "Created At", value: "" },
    { header: "User Info" },
    { label: "First Name", value: "" },
    { label: "Last Name", value: "" },
    { label: "Phone", value: "" },
    { label: "Email", value: "" },
  ]);

  const open = useSelector((state: GlobalState) => state.global.drawer.open);

  const [tab, setTab] = useState(0);

  const [deleteDialog, setDeleteDialog] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const vendorsUrl = `${LEASE_URL}/company/vendor/${vendor?._id}`;

  const { isLoading: vendorsLoading, data: vendorData } = useQuery(
    ["getVendor", vendor],
    () => authorizedFetch(vendorsUrl)
  );

  const vendorVehicleUrl = `${LEASE_URL}/company/vendor/${
    vendor?._id
  }/assigned-vehicles?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}`;

  const {
    isLoading: vendorsVehicleLoading,
    data: vendorVehicleData,
    // refetch: refetchVendorVehicles,
  } = useQuery(["getVendorVehicle", vendor, page, pageSize, search], () =>
    authorizedFetch(vendorVehicleUrl)
  );

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  useEffect(() => {
    if (vendorData) {
      setTable([
        { header: "Company Info" },
        {
          label: "Company Name",
          value: vendorData?.data[0]?.distributor?.name,
        },

        { label: "Phone", value: vendorData?.data[0]?.distributor?.phone },
        {
          label: "Address",
          value: vendorData?.data[0]?.distributor?.address?.local,
        },
        {
          label: "City/District",
          value: vendorData?.data[0]?.distributor?.address?.district,
        },
        {
          label: "State",
          value: vendorData?.data[0]?.distributor?.address?.state,
        },
        {
          label: "Pincode",
          value: vendorData?.data[0]?.distributor?.address?.pincode,
        },
        {
          label: "Created At",
          value: moment(vendorData?.data[0]?.distributor?.createdAt).format(
            "DD/MM/YY, hh:mm a"
          ),
        },
        { header: "User Info" },
        { label: "First Name", value: vendorData?.data[0]?.user?.firstName },
        { label: "Last Name", value: vendorData?.data[0]?.user?.lastName },
        { label: "Phone", value: vendorData?.data[0]?.user?.phone },
        { label: "Email", value: vendorData?.data[0]?.user?.email },
      ]);
    }
  }, [vendorData, vendor]);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  useEffect(() => {
    if (open) {
      setTab(0);
    }
  }, [open]);

  const [assignDialog, setAssignDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [unassignVehicles, setUnassignVehicles] = useState({
    open: false,
    data: [],
  });

  let rowCount = vendorVehicleData?.data?.count || 0;
  let rows =
    vendorVehicleData?.data?.vehicles?.constructor === Array
      ? vendorVehicleData.data.vehicles
      : [];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {vendor?.name ? vendor?.name : "Vendor"}
          <Box display="grid" gridTemplateColumns="repeat(2, auto)" gap={1}>
            <Box>
              {/* <IconButton
                sx={{ mr: 1 }}
                children={<DeleteOutline />}
                color="inherit" 
                size="small"
                onClick={() => {
                  setDeleteDialog({
                    open: true,
                  }); 
                }}
              /> */}
              <Button
                variant="contained"
                size="small"
                sx={{ mr: 1 }}
                onClick={() => setDeleteDialog(true)}
              >
                {vendor?.status === "ACTIVE" ? "DEACTIVATE" : "ACTIVATE"}
              </Button>
              <IconButton
                children={<HighlightOff />}
                color="inherit"
                size="small"
                onClick={() => drawer.close()}
              />
            </Box>
          </Box>
        </Box>
        <Box px={2} pt={2}>
          <Tabs
            className="less-dense"
            value={tab}
            onChange={(e, tab) => setTab(tab)}
          >
            <Tab label="Basic Details" />

            <Tab
              label="Assigned Vehicles"
              className="hasCount"
              sx={{
                "&:after": {
                  content: `"${vendorVehicleData?.data?.count || 0}"`,
                },
              }}
            />
            {/* <Button sx={{ ml: "auto" }} color="inherit">
              <DeleteOutline />
            </Button> */}
          </Tabs>
        </Box>
        {tab === 0 &&
          (vendorsLoading ? (
            <Box mt={5}>
              <CircularLoader />
            </Box>
          ) : (
            <Box flexGrow={1} overflow="auto">
              <Box
                sx={{
                  px: 3,
                  pt: 2.5,
                  "& .table": {
                    borderCollapse: "collapse",
                    width: 1,
                    fontSize: 14,
                    lineHeight: "16px",
                    "& td": {
                      py: 2,
                      px: 2,
                    },
                    "& .bold": {
                      fontWeight: 500,
                    },
                    "& .header": {
                      px: 2,
                      py: 1,
                      position: "relative",
                      "& td ": {
                        position: "absolute",
                        verticalAlign: "middle",
                        backgroundColor: (theme) => theme.customColors.header,
                        width: 1,
                        borderRadius: "4px",
                        fontSize: 16,
                        fontWeight: 600,
                        "& span": {
                          display: "inline-block",
                          transform: "translateY(1px)",
                        },
                      },
                      "& .textVehicle ": {
                        position: "absolute",
                        verticalAlign: "middle",
                        backgroundColor: (theme) => theme.customColors.header,
                        width: 1,
                        borderRadius: "4px",
                        fontSize: 16,
                        fontWeight: 600,
                        "& span": {
                          display: "inline-block",
                          transform: "translateY(1px)",
                        },
                      },
                    },
                    "& .first > td": {
                      pt: 9,
                    },
                    "& .last > td": {
                      pb: 3,
                    },
                  },
                }}
              >
                <table className="table">
                  <tbody>
                    {table.map(({ header, label, value }, i) => {
                      const isFirst = table[i - 1]?.header;
                      const isLast = !table[i + 1] || table[i + 1].header;

                      return (
                        <>
                          <tr
                            key={i}
                            className={
                              header
                                ? "header"
                                : `${isFirst ? "first" : ""} ${
                                    isLast ? "last" : ""
                                  }`
                            }
                          >
                            {[value].includes("") ? (
                              ""
                            ) : header ? (
                              <td colSpan={2}>
                                <span>{header}</span>
                              </td>
                            ) : (
                              <>
                                <td className="bold">{label}</td>

                                <td>{value}</td>
                              </>
                            )}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            </Box>
          ))}

        {tab === 1 && (
          <Box mt={1.5} flexGrow={1} overflow="auto">
            <Box
              sx={{
                px: 3,
                pr: 4,

                // py: 1,
                mb: 1.5,
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
              }}
            >
              <Box display="flex" sx={{ mr: "auto", ml: 0 }}>
                <Hidden implementation="css" mdDown>
                  <Box>
                    <Search
                      handleSearch={(value) => {
                        setSearch(value);
                      }}
                      persist
                      enableClear
                      disableFocus
                    />
                  </Box>
                </Hidden>
              </Box>
              <Button
                color="primary"
                sx={{
                  textTransform: "none",
                  mr: 0,
                  ml: "auto",
                  borderRadius: 10,
                }}
                startIcon={
                  selectedRows.length ? (
                    <Remove sx={{ mr: -0.5, mt: -0.2 }} />
                  ) : (
                    <Add sx={{ mr: -0.5, mt: -0.2 }} />
                  )
                }
                onClick={() => {
                  selectedRows.length
                    ? setUnassignVehicles({
                        open: true,
                        data: selectedRows,
                      })
                    : setAssignDialog(true);
                }}
                variant="outlined"
              >
                {selectedRows.length ? "Unassign" : "Assign New"}
              </Button>
            </Box>
            <Table
              idKey="_id"
              px={0}
              small
              smallPagination
              selectable
              selectOnClick
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={vendorsVehicleLoading}
              rowCount={rowCount}
              serverSidePagination={true}
              activePage={page}
              activePageSize={pageSize}
              onPageChange={(value) => setPage(value)}
              onPageSizeChange={(value) => setPageSize(value)}
              rows={rows}
              columns={[
                { key: "vin", label: "VIN" },

                {
                  key: "health",
                  label: "Health",
                  Render: ({ health }: any) => {
                    return (
                      <Avatar
                        variant="status"
                        className={
                          health === "active"
                            ? ""
                            : health === "moderate"
                            ? "yellow"
                            : health === "critical"
                            ? "red"
                            : "grey"
                        }
                      >
                        {health ? titleCase(health) : "Inactive"}
                      </Avatar>
                    );
                  },
                },
              ]}
            />
          </Box>
        )}
      </Box>
      <DeleteDialog
        open={deleteDialog}
        handleClose={() => {
          setDeleteDialog(false);
          refetchVendorCounts();
        }}
        vendor={vendor}
        refetchVendors={refetchVendors}
        refetchVendorCounts={refetchVendorCounts}
        refetchGraphVendors={refetchGraphVendors}
      />
      <AssignDialog
        open={assignDialog}
        handleClose={() => {
          setAssignDialog(false);
          refetchVendors();
          refetchVendorCounts();
          refetchGraphVendors();
        }}
        vendor={vendor}
      />
      <UnAssignVehicles
        open={unassignVehicles.open}
        handleClose={() => {
          setUnassignVehicles({ ...unassignVehicles, open: false });
          refetchVendors();
          refetchVendorCounts();
          refetchGraphVendors();
          setSelectedRows([]);
        }}
        selected={unassignVehicles.data}
        vendor={vendor}
      />
    </>
  );
};

export default InfoDrawer;
