import { Wrapper } from "@googlemaps/react-wrapper";
import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import ChargerAvailableIcon from "assets/images/charger-available.svg";

class AutocompleteDirectionsHandler {
  map: google.maps.Map;
  originPlaceId: string;
  destinationPlaceId: string;
  startMarker: google.maps.Marker | null;
  endMarker: google.maps.Marker | null;
  chargerMarkers: google.maps.Marker[];
  waypointMarkers: google.maps.Marker[];
  waypoints: google.maps.DirectionsWaypoint[];
  directionsService: google.maps.DirectionsService;
  directionsRenderer: google.maps.DirectionsRenderer;
  alternateRouteRenderers: google.maps.DirectionsRenderer[];

  constructor(map: google.maps.Map) {
    this.map = map;
    this.originPlaceId = "";
    this.destinationPlaceId = "";
    this.startMarker = null;
    this.endMarker = null;
    this.chargerMarkers = [];
    this.waypointMarkers = [];
    this.waypoints = [];
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      map,
    });
    this.alternateRouteRenderers = [];

    const originInput = document.getElementById(
      "origin-input"
    ) as HTMLInputElement;
    const destinationInput = document.getElementById(
      "destination-input"
    ) as HTMLInputElement;

    // Specify just the place data fields that you need.
    const originAutocomplete = new google.maps.places.Autocomplete(
      originInput,
      { fields: ["place_id"] }
    );

    // Specify just the place data fields that you need.
    const destinationAutocomplete = new google.maps.places.Autocomplete(
      destinationInput,
      { fields: ["place_id"] }
    );

    this.setupPlaceChangedListener(originAutocomplete, "ORIG");
    this.setupPlaceChangedListener(destinationAutocomplete, "DEST");

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(originInput);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(
      destinationInput
    );
  }

  setupPlaceChangedListener(
    autocomplete: google.maps.places.Autocomplete,
    mode: string
  ) {
    autocomplete.bindTo("bounds", this.map);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (!place.place_id) {
        window.alert("Please select an option from the dropdown list.");
        return;
      }

      for (let charger of this.chargerMarkers) {
        charger.setMap(null);
      }
      for (let waypoint of this.waypointMarkers) {
        waypoint.setMap(null);
      }
      this.waypoints = [];

      let request = {
        placeId: place.place_id,
        fields: ["geometry"],
      };

      let service = new google.maps.places.PlacesService(this.map);
      const callback = (
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (mode === "ORIG") {
            this.startMarker?.setMap(null);
            this.startMarker = new google.maps.Marker({
              position: place?.geometry?.location,
              label: "A",
              map: this.map,
            });
          } else {
            this.endMarker?.setMap(null);
            this.endMarker = new google.maps.Marker({
              position: place?.geometry?.location,
              label: "B",
              map: this.map,
            });
          }
        }
      };
      service.getDetails(request, callback);

      if (mode === "ORIG") {
        this.originPlaceId = place.place_id;
      } else {
        this.destinationPlaceId = place.place_id;
      }

      this.route();
    });
  }

  route(shouldGetChargers = true) {
    if (!this.originPlaceId || !this.destinationPlaceId) {
      return;
    }

    this.directionsService.route(
      {
        origin: { placeId: this.originPlaceId },
        destination: { placeId: this.destinationPlaceId },
        travelMode: google.maps.TravelMode.DRIVING,

        waypoints: this.waypoints,
        optimizeWaypoints: true,
        provideRouteAlternatives: true,
      },
      (response, status) => {
        if (status === "OK" && response) {
          // Render main route
          this.directionsRenderer.setDirections(response);

          // Clear existing alternate routes
          for (let renderer of this.alternateRouteRenderers) {
            renderer.setMap(null);
          }

          const summaryPanel = document.getElementById(
            "directions-panel"
          ) as HTMLElement;
          summaryPanel.innerHTML = "";

          for (let j = 0; j < response.routes.length; j++) {
            let route = response.routes[j];

            // Render alternate routes
            if (j !== 0) {
              this.alternateRouteRenderers.push(
                new google.maps.DirectionsRenderer({
                  directions: response,
                  routeIndex: j,
                  map: this.map,
                  suppressMarkers: true,
                })
              );
            }

            const routeIndex = j + 1;
            summaryPanel.innerHTML +=
              "<b>Route Index: " + routeIndex + "</b><br>";

            // For each route, display summary information.
            for (let i = 0; i < route.legs.length; i++) {
              const routeSegment = i + 1;

              summaryPanel.innerHTML +=
                "<b>Route Segment: " + routeSegment + "</b><br>";
              summaryPanel.innerHTML += route.legs[i].start_address + " to ";
              summaryPanel.innerHTML += route.legs[i].end_address + "<br>";
              summaryPanel.innerHTML +=
                route.legs[i].distance!.text + "<br><br>";
            }
          }
          if (shouldGetChargers) {
            this.getChargers(response.routes[0].bounds);
          }
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
  }

  getChargers(bounds: google.maps.LatLngBounds) {
    const lat_top = bounds.getNorthEast().lat();
    const lat_bottom = bounds.getSouthWest().lat();
    const lng_left = bounds.getSouthWest().lng();
    const lng_right = bounds.getNorthEast().lng();

    fetch(
      `${"https://bolt.revos.in"}/charger/getAvailable?lat_top=${lat_top}&lat_bottom=${lat_bottom}&lng_left=${lng_left}&lng_right=${lng_right}`,
      {
        headers: {
          token: "1234",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1Y2ZhNDNjNmE3YjExYjAwMDczZjk2NTYiLCJpYXQiOjE2OTEwNjYxOTIsImV4cCI6MTY5MzY1ODE5Mn0.BcyOino6mUX2xy2_zd4UXz_0Cgki35NxX2FCAVv7NSs",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (!res.data.length) window.alert("No chargers found");
        else
          res.data.forEach((el: any, i: number) => {
            const position = new google.maps.LatLng({
              lat: el.station.location.latitude,
              lng: el.station.location.longitude,
            });
            this.createChargerMarker(position, el.charger.chargerId);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  createChargerMarker(position: google.maps.LatLng, chargerId: string) {
    let chargerMarker = new google.maps.Marker({
      position,
      icon: {
        url: ChargerAvailableIcon,
      },
      map: this.map,
    });

    const infoWindow = new google.maps.InfoWindow({
      content: `<b>${chargerId}</b><br/>Click to add waypoint`,
      position,
    });

    chargerMarker.addListener("mouseover", () => {
      infoWindow.open(chargerMarker.get("map"), chargerMarker);
    });
    chargerMarker.addListener("mouseout", () => {
      infoWindow.close();
    });
    chargerMarker.addListener("click", () => {
      if (this.waypoints.length === 25) {
        window.alert("Max limit of 25 waypoints reached");
      } else {
        let markerIndex = this.chargerMarkers.findIndex(
          (el) => el.getPosition()?.toString() === position.toString()
        );
        chargerMarker.setMap(null);
        this.chargerMarkers.splice(markerIndex, 1);

        this.createWaypointMarker(position, chargerId);
        this.route(false);
        infoWindow.close();
      }
    });

    this.chargerMarkers.push(chargerMarker);
  }

  createWaypointMarker(position: google.maps.LatLng, chargerId: string) {
    let waypointMarker = new google.maps.Marker({
      position,
      map: this.map,
    });

    const infoWindow = new google.maps.InfoWindow({
      content: `<b>${chargerId}</b><br/>Click to remove waypoint`,
      position,
    });

    waypointMarker.addListener("mouseover", () => {
      infoWindow.open(waypointMarker.get("map"), waypointMarker);
    });
    waypointMarker.addListener("mouseout", () => {
      infoWindow.close();
    });
    waypointMarker.addListener("click", () => {
      let markerIndex = this.waypointMarkers.findIndex(
        (el) => el.getPosition()?.toString() === position.toString()
      );
      if (markerIndex !== -1) this.waypointMarkers.splice(markerIndex, 1);

      let waypointIndex = this.waypoints.findIndex(
        (el) => el.location?.toString() === position.toString()
      );
      if (waypointIndex !== -1) this.waypoints.splice(waypointIndex, 1);

      waypointMarker.setMap(null);

      this.createChargerMarker(position, chargerId);
      this.route(false);
      infoWindow.close();
    });

    this.waypoints.push({
      location: position,
      stopover: true,
    } as google.maps.DirectionsWaypoint);
    this.waypointMarkers.push(waypointMarker);
  }
}

const Test = () => {
  return (
    <Box
      sx={{
        height: 1,
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "none",
          "& .controls": {
            marginTop: 1.25,
            border: "1px solid transparent",
            borderRadius: "2px 0 0 2px",
            boxSizing: "border-box",
            height: "32px",
            outline: "none",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
          },
          "& input": {
            backgroundColor: "#fff",
            fontFamily: "Roboto",
            fontSize: "15px",
            fontWeight: 300,
            marginLeft: "12px",
            padding: "0 11px 0 13px",
            textOverflow: "ellipsis",
            width: "200px",
            "&:focus": {
              borderColor: "#4d90fe",
            },
          },
        }}
      >
        <input
          id="origin-input"
          className="controls"
          type="text"
          placeholder="Enter an origin location"
        />
        <input
          id="destination-input"
          className="controls"
          type="text"
          placeholder="Enter a destination location"
        />
      </Box>
      <Wrapper
        libraries={["visualization", "places", "drawing", "geometry"]}
        apiKey={"AIzaSyDqq6Ywsf6L3lrgg_P8BI1Z7hvNbsjMQzY"}
      >
        <GoogleMap />
      </Wrapper>
      <Box
        id="sidebar"
        sx={{
          flexBasis: "15rem",
          flexGrow: 1,
          padding: "1rem",
          maxWidth: "30rem",
          height: 1,
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        <Box
          id="directions-panel"
          sx={{
            mt: 1.25,
          }}
        />
      </Box>
    </Box>
  );
};

const GoogleMap = () => {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const map = new google.maps.Map(ref.current as HTMLElement, {
      mapTypeControl: false,
      zoom: 14,
      center: { lat: 13.073393, lng: 77.744647 },
    });
    setTimeout(() => {
      new AutocompleteDirectionsHandler(map);
    }, 1000);
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        flexBasis: 0,
        flexGrow: 4,
        height: 1,
      }}
    />
  );
};

export default Test;
