import { Box } from "@mui/material";

import TestOverview from "./TestOverview";
import FailedTests from "./FailedTests";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { BOLT_URL } from "utils/constants";

const Cards = ({ dateFrom, dateTo, filters, tab }: any) => {
  //   const BOLT_URL_2 = "http://localhost:3001";
  const url = `${BOLT_URL}/v2/misc/charger/diagnostics/stats?startTime=${dateFrom}&endTime=${dateTo}&latest=${
    filters.tests === "Latest" ? true : false
  }`;
  const { isLoading, data: statsData } = useQuery(
    ["getDiagnosticsStats", filters, dateTo, dateFrom],
    () => authorizedFetch(url),
  );
  return (
    <>
      {tab === 1 && (
        <Box
          sx={{
            display: "grid",
            mb: { xs: 3, md: 4 },
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: { xs: 3, md: 4 },
            "& .MuiPaper-root": {
              borderRadius: "14px",
              boxShadow: (theme) => theme.customShadows.card,
              height: { xs: 260, md: 438 },
            },
          }}
        >
          <TestOverview
            {...{
              isLoading,
              statsData,
            }}
          />
          <FailedTests
            {...{
              isLoading,
              statsData,
            }}
          />
        </Box>
      )}
    </>
  );
};

export default Cards;
