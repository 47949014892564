import {
  EventAvailableOutlined,
  EventBusyOutlined,
  InventoryOutlined,
} from "@mui/icons-material";
import { alpha, Box, Paper, Skeleton } from "@mui/material";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { authorizedFetch, GlobalState } from "utils";
import { LEASE_URL } from "utils/constants";
import TotalDistance from "views/Rental/Overview/Cards/TotalDistance";
import TotalEarnings from "views/Rental/Overview/Cards/TotalEarnings";
import LeasesChart from "./LeasesChart";

const Cards = ({ dateFrom, dateTo, masterView }: any) => {
  const { drawer } = useSelector((state: GlobalState) => state.global);

  const { isLoading: leasesStatsLoading, data: leasesStatsData } = useQuery(
    ["GET FMS /company/bookings/stats"],
    () => authorizedFetch(`${LEASE_URL}/company/bookings/stats`)
  );

  const { isLoading: leasesChartLoading, data: leasesChartData } = useQuery(
    ["GET FMS /company/bookings/count", dateFrom, dateTo, masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/bookings/count?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}&master=${masterView}`
      )
  );

  const { isLoading: earningsStatsLoading, data: earningsStatsData } = useQuery(
    ["GET FMS /company/bookings/earnings", dateFrom, dateTo, masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/bookings/earnings?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}&master=${masterView}`
      )
  );

  const { isLoading: distanceChartLoading, data: distanceChartData } = useQuery(
    ["GET FMS /company/bookings/distance", dateFrom, dateTo, masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/bookings/distance?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}&master=${masterView}`
      )
  );

  const totalBooked = leasesStatsData?.data?.booked;
  const totalActive = leasesStatsData?.data?.active;
  const totalEnded = leasesStatsData?.data?.ended;

  return (
    <Box
      sx={{
        display: "grid",
        mb: { xs: 3, md: 4 },
        gridTemplateColumns: {
          lg: drawer.open ? "1fr" : "1fr 1fr",
        },
        gap: { xs: 3, md: 4 },
        "& .MuiPaper-root": {
          borderRadius: "14px",
          boxShadow: (theme) => theme.customShadows.card,
          height: { xs: 260, md: 438 },
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr 1fr",
            lg: "repeat(3, 1fr)",
          },
          gap: { xs: 1.75, md: 4 },
          gridColumn: "1 / -1",
          "& .card": {
            height: "auto !important",
            width: 1,
            // borderRadius: 0,
            py: { xs: 1.25, md: 2 },
            px: { xs: 1.25, md: 3 },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .icon": {
              borderRadius: 50,
              height: { xs: 32, md: 72, xl: 98 },
              width: { xs: 32, md: 72, xl: 98 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
              color: (theme) => theme.palette.primary.main,
              fontSize: { xs: 18, md: 32, xl: 44 },
            },
            "& .description": {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              "& .label": {
                mb: { xs: 1, md: 1.5 },
                textAlign: "end",
                fontSize: { xs: "12px", md: 16, xl: 18 },
                fontWeight: 600,
                lineHeight: { xs: "16px", md: "24.55px" },
                color: (theme) => theme.customColors.text.grey,
              },
              "& .value": {
                textAlign: "end",
                fontSize: { xs: "16px", md: "24px", xl: "32px" },
                fontWeight: 700,
                lineHeight: { xs: "19.1px", md: "44px" },
                letterSpacing: "0em",
              },
              "& .greyed": {
                color: (theme) => theme.palette.text.disabled,
                userSelect: "none",
              },
            },
          },
        }}
      >
        <Paper className="card">
          <Box className="icon">
            <InventoryOutlined fontSize="inherit" />
          </Box>
          <Box className="description">
            <span className="label">Total Booked</span>
            <span className="value">
              {leasesStatsLoading ? <Skeleton width={60} /> : totalBooked}
            </span>
          </Box>
        </Paper>
        <Paper className="card">
          <Box className="icon">
            <EventAvailableOutlined fontSize="inherit" />
          </Box>
          <Box className="description">
            <span className="label">Total Active</span>
            <span className="value">
              {leasesStatsLoading ? <Skeleton width={60} /> : totalActive}
            </span>
          </Box>
        </Paper>
        <Paper className="card">
          <Box className="icon">
            <EventBusyOutlined fontSize="inherit" />
          </Box>
          <Box className="description">
            <span className="label">Total Ended</span>
            <span className="value">
              {leasesStatsLoading ? <Skeleton width={60} /> : totalEnded}
            </span>
          </Box>
        </Paper>
      </Box>
      <Box sx={{ gridColumn: "1/-1" }}>
        <LeasesChart isLoading={leasesChartLoading} data={leasesChartData} />
      </Box>
      <TotalEarnings
        secondaryColor
        isLoading={earningsStatsLoading}
        data={earningsStatsData}
      />
      <TotalDistance
        secondaryColor
        isLoading={distanceChartLoading}
        data={distanceChartData}
      />
    </Box>
  );
};

export default Cards;
