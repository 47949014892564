import {
  Box,
  CircularProgress,
  Drawer,
  FormControlLabel,
  Paper,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import VehicleMonitoring from "./VehicleMonitoring";
// import BasicDetails from "./BasicDetails";

// import Breadcrumbs from "components/Breadcrumbs";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import {
  authorizedFetch,
  getDarkModePreference,
  GlobalState,
  appBar,
} from "utils";
import RecentTrips from "./RecentTrips";
import VehicleDrawer from "./VehicleDrawer";
import VehicleSettings from "./VehicleSettings";
import { useQuery } from "react-query";
import { RETAIL_URL } from "utils/constants";

import AppBar from "./AppBar";
import moment from "moment";

const LiveButton = ({ isLive, setIsLive, timeDifference }: any) => {
  // const [hover, setHover] = useState(false);

  return (
    <Box
      sx={{
        // width: 100,
        // heihgt: "auto",
        border: 1,
        borderColor: (theme) => theme.customColors.border,
        borderRadius: 2,
        pl: 2,
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={isLive}
            onClick={() => {
              setIsLive(!isLive);
            }}
          />
        }
        label="Live"
      />
    </Box>

    // <Button
    //   size="small"
    //   variant="outlined"
    //   color="secondary"
    //   sx={{
    //     ml: 2,
    //     // color: theme => theme.customColors.grey,
    //   }}
    //   startIcon={
    //     isLive && hover ? (
    //       <Pause />
    //     ) : (
    //       <FiberManualRecord color={isLive ? "error" : "disabled"} />
    //     )
    //   }
    //   onClick={() => {
    //     setIsLive(!isLive);
    //     setHover(false);
    //   }}
    //   onMouseEnter={() => setHover(true)}
    //   onMouseLeave={() => setHover(false)}
    // >
    //   Live
    // </Button>
  );
};

const VehicleView = ({ match }: any) => {
  const id = match.params.id;
  // const history = useHistory();
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const { activeSection } = useSelector((state: GlobalState) => state.global);
  let isRetail = activeSection === "retail";

  const [tab, setTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dataSource, setDataSource] = useState<any>("Loading...");
  const [timestamp, setTimestamp] = useState<any>("");
  const [timeDifference, setTimeDifference] = useState<any>(null);

  const [vehicleSettingsDialog, setVehicleSettingsDialog] = useState(false);

  const url = `${RETAIL_URL}/assembly/vehicle/${id}`;
  const { isLoading, data } = useQuery(["getVehicle", id], () =>
    authorizedFetch(url)
  );

  const vehicle = data?.data?.[0];

  const [isLive, setIsLive] = useState<boolean>();

  useEffect(() => {
    console.log(timeDifference);
    if (timeDifference !== "N/A") {
      timeDifference > 5 ? setIsLive(false) : setIsLive(true);
    }
    if (timeDifference === "N/A") {
      setIsLive(false);
    }
  }, [timeDifference]);

  useEffect(() => {
    if (isLive && timeDifference > 5) {
      setIsLive(!isLive);
    }
  }, [isLive, timeDifference]);

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          vehicle,
          isLoading,
          setDrawerOpen,

          isDarkMode,
          isRetail,
          data,
          setVehicleSettingsDialog,
        }}
      />
    );
    return () => {
      appBar.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle, isLoading, isDarkMode, isRetail, data]);

  return (
    <>
      <VehicleSettings
        open={vehicleSettingsDialog}
        isLoading={isLoading}
        vehicle={vehicle}
        onClose={() => {
          setVehicleSettingsDialog(false);
        }}
      />
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="right"
        PaperProps={{
          sx: {
            maxWidth: 550,
            width: 1,
          },
        }}
      >
        <VehicleDrawer id={id} onClose={() => setDrawerOpen(false)} />
      </Drawer>
      <Box
        width={1}
        mb={{ xs: 1.5, md: 2 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <Breadcrumbs
            isVehicleView
            vin={isLoading ? "Loading..." : vehicle?.vin}
          /> */}
          {/* <Tooltip title="Info" followCursor>
            <IconButton
              sx={{ ml: 1 }}
              size="small"
              children={<InfoOutlined fontSize="small" />}
              // onClick={() => drawer.open(<VehicleDrawer vin={vin} />)}
              onClick={() => setDrawerOpen(true)}
            />
          </Tooltip> */}
          {/* <Divider flexItem orientation="vertical" sx={{ mx: 2 }} /> */}
          <Tabs
            className="less-dense"
            value={tab}
            onChange={(e, tab) => setTab(tab)}
          >
            <Tab label="Monitor" />
            <Tab label="Recent Trips" />
          </Tabs>
        </Box>

        {tab === 0 && (
          <Box display="flex" alignItems="center">
            {/* <RangePicker
              range={range}
              setRange={setRange}
              presets={["12H", "1D", "4D"]}
              initialRange={isLive ? undefined : "12H"}
            /> */}
            {timeDifference !== null && timeDifference > 5 && (
              <Box mr={3}>
                <Typography>
                  TimeStamp :{" "}
                  {moment(timestamp).format("DD / MMM / YYYY , hh:mm A")}
                </Typography>
              </Box>
            )}
            <Box mr={3}>
              <Typography>Source : {dataSource}</Typography>
            </Box>

            <LiveButton
              isLive={isLive}
              setIsLive={setIsLive}
              timeDifference={timeDifference}
            />
          </Box>
        )}
        {/* <Box>
          <Button
            sx={{
              minWidth: 40,
              height: 40,
              p: 0,
              border: 1,
              mr: 1,
              borderColor: (theme) => theme.customColors.border,
              bgcolor: isDarkMode ? "background.default" : "#fff",
            }}
            disabled={!data}
            onClick={() => setVehicleSettingsDialog(true)}
          >
            <Settings />
          </Button>
          <Button
            sx={{
              minWidth: 40,
              height: 40,
              p: 0,
              border: 1,
              borderColor: (theme) => theme.customColors.border,
              bgcolor: isDarkMode ? "background.default" : "#fff",
            }}
            onClick={() => {
              history.push(isRetail ? "/sold-vehicles" : "/vehicles");
            }}
          >
            <Close />
          </Button>
        </Box> */}
      </Box>
      <Paper
        sx={{
          width: 1,
          boxShadow: "none",
          // borderRadius: 2,
          // p: 3,
        }}
      >
        {/* <Box display="flex" alignItems="center">
          <Box mb={2} width="fit-content">
            <Tabs
              className="less-dense"
              value={tab}
              onChange={(e, tab) => setTab(tab)}
            >
              <Tab label="Monitor" />
              <Tab label="Recent Trips" />
            </Tabs>
          </Box>

        </Box> */}
        {isLoading ? (
          <CircularProgress size={30} />
        ) : (
          vehicle && (
            <>
              {tab === 0 && (
                <VehicleMonitoring
                  vehicle={vehicle}
                  isLive={isLive}
                  setIsLive={setIsLive}
                  setDataSource={setDataSource}
                  setTimestamp={setTimestamp}
                  setTimeDifference={setTimeDifference}
                />
              )}
              {tab === 1 && <RecentTrips vehicle={vehicle} />}
            </>
          )
        )}
      </Paper>
    </>
  );
};

export default VehicleView;
