import { Box } from "@mui/material";
// import { gql, useQuery as gqlUseQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import AppBar from "./AppBar";
import { appBar, authorizedFetch, drawer } from "utils";
import List from "./List";
import { SCM_URL } from "utils/constants";
import { useQuery } from "react-query";

const filterLabels: any = {
  sortParams: {
    // in the format of "<sort>|<sortBy>"
    "Date Created (Newest First)": "-1|createdAt",
    "Date Created (Oldest First)": "1|createdAt",
  },
  operation: {
    All: "",
    Approved: "APPROVED",
    Rejected: "REJECTED",
  },
  feature: {
    All: "",
    KYC: "KYC",
    CHARGER: "CHARGER",
  },
};

const Tracking = () => {
  const [filters, setFilters] = useState<any>({
    sortParams: filterLabels.sortParams["Date Created (Newest First)"],
    operation: filterLabels.operation.All,
    feature: filterLabels.feature.All,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const first = pageSize;
  const skip = pageSize * (page - 1);

  const { sortParams, operation, feature } = filters;

  const sort = sortParams.split("|")[0];
  const sortBy = sortParams.split("|")[1];

  const scmURL = `${SCM_URL}/user-activity/cms/activity?first=${first}&skip=${skip}&search=${search}&operation=${operation}&feature=${feature}&sort=${sort}&sortBy=${sortBy}`;

  const { data: userData, isLoading: userDataLoading } = useQuery(
    ["GET Charger KYC", first, skip, search, sort, sortBy, operation, feature],
    () => authorizedFetch(scmURL)
  );

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          filters,
          setFilters,
          filterLabels,
        }}
      />
    );
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [filters]);
  return (
    <Box>
      <List
        {...{
          page,
          setPage,
          pageSize,
          setPageSize,
          setSearch,
          userData,
          userDataLoading,
        }}
      />
    </Box>
  );
};

export default Tracking;
