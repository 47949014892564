import {
  Add,
  CheckCircleOutline,
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  InfoOutlined,
  Remove,
  WarningAmber,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  // Slider,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  // Tooltip,
  Typography,
} from "@mui/material";
// import InputAdornment from "@mui/material";
import PhoneTextField from "components/PhoneTextField";
import { queryClient } from "index";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar, titleCase } from "utils";
import { BOLT_URL, PAYMENTS_URL } from "utils/constants";
import validator from "validator";
import SetAvailability from "./SetAvailability";
import PaymentDialog from "views/Charger/Users/PaymentDialog";
import CurrencyInput from "components/CurrencyInput";
// import ChargerHealth from "../Overview/ChargerHealth";
import getSymbolFromCurrency from "currency-symbol-map";

const inputObj: any = {
  // User info
  name: "",
  phone: "",
  email: "",
  maintainerName: "",
  maintainerPhone: "",
  maintainerEmail: "",

  // Charger info
  chargerId: "",
  chargerName: "",
  chargerSerial: "",
  rfid: "",
  manufacturer: "",
  model: "",
  connectors: [],
  communicationMode: "",
  usageType: "",
  costPerkWh: "",
  ownerCostPerkWh: "",
  settlementCycle: "WEEKLY",
  paymentMethodId: "",

  // Station info
  address: "",
  city: "",
  pincode: "",
  district: "",
  state: "",
  country: "",
  latitude: "",
  longitude: "",
};

const AddChargerDialog = ({ open, handleClose }: any) => {
  const [step, setStep] = useState<number>(0);
  const steps = ["User Info", "Charger Info", "Station Info", "Finish"];

  const [twentyFourSeven, setTwentyFourSeven] = useState(true);
  const [days, setDays] = useState<any>([]);

  const [input, setInput] = useState({ ...inputObj });
  const [user, setUser] = useState<any>(null);

  const [currency, setCurrency] = useState("INR");

  const [paymentDialog, setPaymentDialog] = useState<any>({
    open: false,
    data: null,
  });

  const {
    // User info
    name,
    phone,
    email,
    maintainerName,
    maintainerPhone,
    maintainerEmail,

    // Charger info
    chargerId,
    chargerName,
    chargerSerial,
    rfid,
    manufacturer,
    model,
    connectors,
    communicationMode,
    usageType,
    costPerkWh,
    ownerCostPerkWh,
    // baseAmount,
    // chargePerHour,
    settlementCycle,
    paymentMethodId,

    // Station info
    // stationName,
    address,
    city,
    pincode,
    district,
    state,
    country,
    latitude,
    longitude,
  } = input;

  const isPaid = ["PUBLIC_PAID", "RESTRICTED_PAID"].includes(usageType);

  const { data: specsData } = useQuery(["getOCPPSpecs"], () =>
    authorizedFetch(`${BOLT_URL}/v2/charger/ocpp/specs`),
  );
  const { isLoading: paymentMethodsLoading, data: paymentMethodsData } =
    useQuery(
      ["getPaymentDetails", user?._id],
      () =>
        authorizedFetch(
          `${PAYMENTS_URL}/v1/payments/paymentmeta/default/getAll?userId=${user?._id}`,
        ),
      {
        enabled: !!user?._id && isPaid,
      },
    );

  const selectedModel =
    specsData?.data?.constructor === Array && !!manufacturer && !!model
      ? specsData?.data
          ?.find((el: any) => el.manufacturer === manufacturer)
          ?.models.find((el: any) => el.model === model)
      : {};

  const { powerRating = 0 } = selectedModel;

  useEffect(() => {
    if (!open) return;
    setStep(0);
    setInput({ ...inputObj });
    setTwentyFourSeven(true);
    setDays([]);
  }, [open]);

  const validateEmail = (email: any) => {
    return validator.isEmail(email);
  };

  const validateChargerId = (chargerId: any) => {
    return /^([A-Z0-9_]){8,10}$/.test(chargerId);
  };

  function formatDays() {
    let data = [...days];
    data.forEach((el: any) => {
      delete el.id;
      el.fromTime = moment(el.fromTime).format("HH:mm");
      el.toTime = moment(el.toTime).format("HH:mm");
    });
    return data;
  }

  function handleNext() {
    if (step === 0) {
      setLoader(true);
      authorizedFetch(`${BOLT_URL}/company/users/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          phone,
          email,
        },
      })
        .then((res) => {
          setLoader(false);
          if (res.status !== 200) {
            snackbar.error(res.message);
          } else {
            setUser(res.data);
            setStep(step + 1);
          }
        })
        .catch((err) => {
          snackbar.error("An error ocurred.");
          setLoader(false);
          console.log(err);
        });
    } else if (step === steps.length - 1) {
      onSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isPriceValid(price: any) {
    return currency === "INR"
      ? parseFloat(price) >= 1 && parseFloat(price) <= 30
      : true;
  }

  function isDisabled() {
    if (step === 0) {
      return !isComplete(0);
    } else if (step === steps.length - 1) {
      if (!validateChargerId(chargerId)) return true;

      if (
        [
          // Charger info
          chargerId,
          chargerName,
          chargerSerial,
          rfid,
          manufacturer,
          model,
          communicationMode,
          usageType,

          // Station info
          // stationName,
          address,
          city,
          pincode,
          district,
          state,
          country,
          latitude,
          longitude,
        ].includes("")
      )
        return true;

      if (isPaid) {
        if (
          [costPerkWh, ownerCostPerkWh].some((el) => !isPriceValid(el)) ||
          parseFloat(ownerCostPerkWh) >= parseFloat(costPerkWh) ||
          !paymentMethodId
        )
          return true;
      }

      if (!twentyFourSeven && days.length === 0) return true;
    }

    return false;
  }

  function isComplete(step: number) {
    if (step === 0) {
      if (name === "") return false;
      if (!isValidPhoneNumber(phone)) return false;
      if (!validateEmail(email)) return false;
      return true;
    } else if (step === 1) {
      if (!validateChargerId(chargerId)) return false;
      if (
        [
          chargerId,
          chargerName,
          chargerSerial,
          rfid,
          manufacturer,
          model,
          communicationMode,
          usageType,
        ].includes("")
      )
        return false;
      if (isPaid && [costPerkWh, ownerCostPerkWh, paymentMethodId].includes(""))
        return false;
      return true;
    } else if (step === 2) {
      if (!twentyFourSeven && days.length === 0) return false;
      return ![
        address,
        city,
        pincode,
        district,
        state,
        latitude,
        longitude,
        country,
      ].includes("");
    }
  }

  function returnTime(day: number) {
    return twentyFourSeven
      ? "All Day"
      : !days.some((el: any) => el.day === day)
        ? "Unavailable"
        : days
            .filter((el: any) => el.day === day)
            .map((el: any, i: number) => (
              <div key={i}>
                {moment(el.fromTime).format("h:mm A") +
                  "-" +
                  moment(el.toTime).format("h:mm A")}
              </div>
            ));
  }

  const onSave = () => {
    setLoader(true);

    const body = {
      station: {
        // stationName: stationName,
        address: address,
        city: city,
        postalCode: pincode,
        district: district,
        state: state,
        location: {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        },
        country: country,
        establishmentType: "Others",
        parkingSpaceType: "Other",
        availableParkingSpace: ["TWO_WHEELER", "THREE_WHEELER", "FOUR_WHEELER"],
        facilities: [],
      },
      host: {
        name: name,
        phoneNumber: phone,
        email: email,
      },
      incharge: {
        name: maintainerName,
        phoneNumber: maintainerPhone || "",
        email: maintainerEmail,
      },
      charger: {
        chargerId: chargerId,
        chargerName: chargerName,
        ocppChargerId: chargerSerial,
        idTag: rfid,
        manufacturer: manufacturer,
        model: model,
        connectors: connectors.map((el: any) => ({
          id: el.id,
          status: "",
          type: el.type,
          meterValues: "",
          transactionId: -1,
        })),
        powerRating: powerRating,
        primaryComm: communicationMode,
        usageType: usageType,
        modelId: selectedModel.modelId,
      },
      availability: {
        days: formatDays(),
        timeZone: "Asia/Calcutta",
        twentyFourSeven: twentyFourSeven,
      },
      paymentDetails: {
        paymentMethodId: isPaid ? paymentMethodId : "NA",
        tariffMode: "KWH",
        acceptedPaymentModes: ["INTEGRATED_PAYMENT_PORTAL"],
        costPerkWh: isPaid ? parseFloat(costPerkWh) || 0 : 0, // Default to 0 if usageType free
        ownerCostPerkWh: isPaid ? parseFloat(ownerCostPerkWh) || 0 : 0,
        baseAmount: 0,
        chargePerHour: 0,
        parkingCharges: 0,
        currency: currency,
        settlementCycle: settlementCycle,
      },
    };

    // authorizedFetch(`${OCPP_URL}/charger/register`, {
    authorizedFetch(`${"https://ocpp-test.dev.revos.in"}/charger/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // stage: "test",
        stage: "prod",
      },
      body,
    })
      .then((res) => {
        setLoader(false);
        if (res.status === 201) {
          queryClient.resetQueries("getChargers");
          snackbar.success(`OCPP charger added`);
          handleClose();
        } else {
          snackbar.error(
            `Error${res.message ? ": " + res.message : " adding OCPP charger"}`,
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error(`Error adding OCPP charger`);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Add OCPP Charger
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          // nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i} completed={isComplete(i)}>
              <StepButton
              // onClick={() => setStep(i)}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        {step === 0 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box>
              <Typography className="label">Owner Name</Typography>
              <TextField
                fullWidth
                size="small"
                name="Full Name"
                placeholder="Owner Name"
                value={name}
                onChange={(e: any) => {
                  setInput({ ...input, name: e.target.value });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Owner Phone</Typography>
              <PhoneInput
                placeholder="Enter phone number"
                value={phone}
                onChange={(value) => setInput({ ...input, phone: value || "" })}
                defaultCountry="IN"
                international
                limitMaxLength
                inputComponent={PhoneTextField}
                error={!!phone && !isValidPhoneNumber(phone)}
              />
            </Box>
            <Box>
              <Typography className="label">Owner Email</Typography>
              <TextField
                fullWidth
                size="small"
                type="email"
                placeholder="Owner Email"
                value={email}
                onChange={(e: any) => {
                  setInput({ ...input, email: e.target.value });
                }}
                error={!!email && !validateEmail(email)}
                helperText={
                  !!email && !validateEmail(email) ? "Invalid email" : undefined
                }
              />
            </Box>
            <Box sx={{ gridColumnStart: 1 }}>
              <Typography className="label">
                Maintainer Name (Optional)
              </Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Maintainer Name"
                value={maintainerName}
                onChange={(e: any) => {
                  setInput({ ...input, maintainerName: e.target.value });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">
                Maintainer Phone (Optional)
              </Typography>
              <PhoneInput
                placeholder="Enter phone number"
                value={maintainerPhone}
                onChange={(value) =>
                  setInput({ ...input, maintainerPhone: value || "" })
                }
                defaultCountry="IN"
                international
                limitMaxLength
                inputComponent={PhoneTextField}
                error={
                  !!maintainerPhone && !isValidPhoneNumber(maintainerPhone)
                }
              />
              {/* <TextField
                fullWidth
                size="small"
                placeholder="Maintainer Phone"
                value={maintainerPhone}
                onChange={(e: any) => {
                  if (/^[0-9]{0,10}$/.test(e.target.value))
                    setInput({ ...input, maintainerPhone: e.target.value });
                }}
                error={!!maintainerPhone && !validatePhone(maintainerPhone)}
                helperText={
                  !!maintainerPhone && !validatePhone(maintainerPhone)
                    ? "Incomplete phone number"
                    : undefined
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        "& > .MuiTypography-root": {
                          ml: 0.5,
                          fontSize: "14px !important",
                        },
                      }}
                    >
                      +91
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Box>
            <Box>
              <Typography className="label">
                Maintainer Email (Optional)
              </Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Maintainer Email"
                value={maintainerEmail}
                onChange={(e: any) => {
                  setInput({ ...input, maintainerEmail: e.target.value });
                }}
                error={!!maintainerEmail && !validateEmail(maintainerEmail)}
                helperText={
                  !!maintainerEmail && !validateEmail(maintainerEmail)
                    ? "Invalid email"
                    : undefined
                }
              />
            </Box>
          </Box>
        )}

        {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box>
              <Box display="flex" alignItems="center">
                <Typography className="label">Charger UID</Typography>
                <Tooltip title="UID must start with L2 or L3, followed by 5 to 8 alphanumeric characters.">
                  <InfoOutlined
                    fontSize="inherit"
                    sx={{
                      ml: 0.5,
                      mt: -1.7,
                      cursor: "pointer",
                      color: "#00000080",
                    }}
                  />
                </Tooltip>
              </Box>
              <TextField
                fullWidth
                size="small"
                placeholder="L300001"
                value={chargerId}
                onChange={(e: any) => {
                  if (/^([A-Za-z0-9_]){0,19}$/.test(e.target.value))
                    setInput({
                      ...input,
                      chargerId: e.target.value.toUpperCase(),
                    });
                }}
                error={!!chargerId && !validateChargerId(chargerId)}
                InputProps={
                  !!chargerId
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            {validateChargerId(chargerId) ? (
                              <CheckCircleOutline color="primary" />
                            ) : (
                              <HighlightOff color="error" />
                            )}
                          </InputAdornment>
                        ),
                      }
                    : undefined
                }
              />
            </Box>
            <Box>
              <Typography className="label">Charger Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Charger Name"
                value={chargerName}
                onChange={(e: any) => {
                  setInput({
                    ...input,
                    chargerName: e.target.value,
                  });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Charger Serial No.</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Charger Serial No."
                value={chargerSerial}
                onChange={(e: any) => {
                  if (/^[a-zA-Z0-9]*$/.test(e.target.value))
                    setInput({
                      ...input,
                      chargerSerial: e.target.value,
                    });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">RFID</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="RFID"
                value={rfid}
                onChange={(e: any) => {
                  if (/^[a-zA-Z0-9]*$/.test(e.target.value))
                    setInput({
                      ...input,
                      rfid: e.target.value,
                    });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Manufacturer</Typography>
              <Select
                size="small"
                fullWidth
                displayEmpty
                renderValue={manufacturer ? undefined : () => <em>Select</em>}
                value={manufacturer}
                onChange={(e: any) =>
                  setInput({
                    ...input,
                    manufacturer: e.target.value,
                    model: "",
                    connectors: [],
                  })
                }
              >
                <MenuItem disabled value="">
                  <em>Select</em>
                </MenuItem>
                {(specsData?.data?.constructor === Array
                  ? specsData.data
                  : []
                ).map((el: any, i: any) => (
                  <MenuItem key={i} value={el.manufacturer}>
                    {el.manufacturer}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box width={1} maxWidth={1} overflow="hidden">
              <Typography className="label">Model</Typography>
              <Select
                size="small"
                fullWidth
                displayEmpty
                renderValue={model ? undefined : () => <em>Select</em>}
                value={model}
                onChange={(e: any) => {
                  const selectedModel = e.target.value;
                  setInput({
                    ...input,
                    model: selectedModel,
                    connectors: specsData.data
                      .find((el: any) => el.manufacturer === manufacturer)
                      ?.models.find((el: any) => el.model === selectedModel)
                      .connectors,
                  });
                }}
                disabled={!manufacturer}
              >
                <MenuItem disabled value="">
                  <em>Select</em>
                </MenuItem>
                {(!!specsData?.data && !!manufacturer
                  ? specsData.data.find(
                      (el: any) => el.manufacturer === manufacturer,
                    )?.models
                  : []
                ).map((el: any, i: any) => (
                  <MenuItem key={i} value={el.model}>
                    {el.model}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {!!model && (
              <Box
                sx={{
                  gridColumn: "1/-1",
                }}
              >
                <Typography className="label">Connectors</Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    gap: 2.5,
                  }}
                >
                  <Typography mb={-1.5} fontSize={14} fontWeight={600}>
                    ID
                  </Typography>
                  <Typography mb={-1.5} fontSize={14} fontWeight={600}>
                    Type
                  </Typography>
                  {connectors.map((el: any, i: any) => (
                    <Fragment key={i}>
                      <TextField
                        size="small"
                        placeholder="ID"
                        value={connectors[i]?.id}
                        onChange={(e) => {
                          let newConnectors = [...connectors];
                          newConnectors[i] = {
                            id: e.target.value,
                            type: el.type,
                          };
                          setInput({ ...input, connectors: newConnectors });
                        }}
                      />
                      <TextField
                        size="small"
                        placeholder="Type"
                        value={el.type}
                        disabled
                      />
                    </Fragment>
                  ))}
                </Box>
              </Box>
            )}
            <Box>
              <Typography className="label">Communication Mode</Typography>
              <Select
                size="small"
                fullWidth
                displayEmpty
                value={communicationMode}
                onChange={(e) =>
                  setInput({ ...input, communicationMode: e.target.value })
                }
                renderValue={
                  communicationMode ? undefined : () => <em>Select</em>
                }
              >
                <MenuItem disabled value="">
                  <em>Select</em>
                </MenuItem>
                {[
                  { label: "SIM", value: "SIM" },
                  { label: "Wi-Fi", value: "WIFI" },
                  { label: "Ethernet", value: "ETHERNET" },
                ].map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Typography className="label">Usage Type</Typography>
              <Select
                size="small"
                fullWidth
                displayEmpty
                value={usageType}
                onChange={(e) =>
                  setInput({ ...input, usageType: e.target.value })
                }
                renderValue={usageType ? undefined : () => <em>Select</em>}
              >
                <MenuItem disabled value="">
                  <em>Select</em>
                </MenuItem>
                {[
                  "PUBLIC_FREE",
                  "PUBLIC_PAID",
                  "RESTRICTED_FREE",
                  "RESTRICTED_PAID",
                  "PRIVATE",
                ].map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {el.split("_").map(titleCase).join(" ")}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ gridColumn: "1/-1" }}>
              <Collapse in={isPaid}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 3,
                  }}
                >
                  <Box sx={{ mt: 1, gridColumn: "1/-1" }}>
                    <Typography variant="overline">Payment Details</Typography>
                    <Divider />
                  </Box>
                  {[
                    {
                      key: "ownerCostPerkWh",
                      label: "Energy Tariff",
                    },
                    {
                      key: "costPerkWh",
                      label: "Cost Per Unit (kWh)",
                    },
                  ].map(({ key, label }, i) => (
                    <Box key={i}>
                      <Typography className="label">{label}</Typography>
                      <Box sx={{ display: "flex", alignItems: "start" }}>
                        <CurrencyInput
                          currency={currency}
                          setCurrency={setCurrency}
                          amount={input[key]}
                          setAmount={(value: any) => {
                            if (value === "" || /^[0-9.]{0,5}$/.test(value))
                              setInput({ ...input, [key]: value });
                          }}
                          placeholder={label}
                          error={!!input[key] && !isPriceValid(input[key])}
                          helperText={
                            currency === "INR"
                              ? "Enter a value between 1 and 30"
                              : undefined
                          }
                        />
                        <IconButton
                          size="small"
                          color="primary"
                          sx={{ ml: 0.5, mt: 0.75 }}
                          onClick={() =>
                            setInput({
                              ...input,
                              [key]:
                                parseFloat(input[key]) >= 2
                                  ? String(parseFloat(input[key]) - 1)
                                  : "1",
                            })
                          }
                        >
                          <Remove />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="primary"
                          sx={{ ml: 0.5, mt: 0.75 }}
                          onClick={() =>
                            setInput({
                              ...input,
                              [key]:
                                input[key] === ""
                                  ? "1"
                                  : parseFloat(input[key]) <= 29
                                    ? String(parseFloat(input[key]) + 1)
                                    : "30",
                            })
                          }
                        >
                          <Add />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                  <Alert severity="info" sx={{ gridColumn: "1/-1", mt: -1.5 }}>
                    <Typography variant="body2">
                      <b>Cost Per Unit</b> should be greater than the{" "}
                      <b>Energy Tariff</b>
                    </Typography>
                  </Alert>
                  <FormControl>
                    <Typography className="label">
                      Settlement Frequency
                    </Typography>
                    <RadioGroup
                      value={settlementCycle}
                      onChange={(e) =>
                        setInput({ ...input, settlementCycle: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="BIWEEKLY"
                        control={<Radio size="small" />}
                        label="Biweekly"
                      />
                      <FormControlLabel
                        value="WEEKLY"
                        control={<Radio size="small" />}
                        label="Weekly"
                      />
                      <FormControlLabel
                        value="MONTHLY"
                        control={<Radio size="small" />}
                        label="Monthly"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Box>
                    <Typography className="label">Payment Method</Typography>
                    <Box>
                      {paymentMethodsLoading ? (
                        <Typography color="text.disabled">
                          Loading...
                        </Typography>
                      ) : (
                        <>
                          {paymentMethodsData?.data?.constructor === Array &&
                          paymentMethodsData.data.length > 0 ? (
                            <RadioGroup
                              value={paymentMethodId}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  paymentMethodId: e.target.value,
                                })
                              }
                            >
                              {paymentMethodsData.data.map(
                                (el: any, i: any) => (
                                  <FormControlLabel
                                    key={i}
                                    value={el._id}
                                    control={<Radio size="small" />}
                                    label={`${
                                      el.bankDetails.bankName
                                    } (•••• ${el.bankDetails.bankAccount.slice(
                                      -4,
                                    )})`}
                                  />
                                ),
                              )}
                            </RadioGroup>
                          ) : (
                            <Typography color="text.disabled">
                              No payment methods found
                            </Typography>
                          )}
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ mt: 1.5, textTransform: "none" }}
                            onClick={() =>
                              setPaymentDialog({
                                open: true,
                                data: user,
                              })
                            }
                          >
                            View/Edit Payment Methods
                          </Button>
                        </>
                      )}
                    </Box>
                    <PaymentDialog
                      open={paymentDialog.open}
                      handleClose={() =>
                        setPaymentDialog({ ...paymentDialog, open: false })
                      }
                      data={paymentDialog.data}
                    />
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </Box>
        )}

        {step === 2 && (
          <>
            <Box
              sx={{
                maxWidth: 560,
                mx: "auto",
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
              }}
            >
              {/* <Box
                sx={{
                  gridColumn: { sm: "span 2" },
                }}
              >
                <Typography className="label">Station Name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Station Name"
                  value={stationName}
                  onChange={(e: any) => {
                    setInput({
                      ...input,
                      stationName:
                        e.target.value?.length > 0
                          ? e.target.value.replace(/[^a-zA-Z ]/g, "")
                          : e.target.value,
                    });
                  }}
                />
              </Box> */}

              <Box sx={{ gridColumn: { sm: "span 2" } }}>
                <Typography className="label">Station Address</Typography>
                <Box
                  sx={{
                    maxWidth: 560,
                    mx: "auto",
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Box sx={{ gridColumn: { sm: "span 2" } }}>
                    <Typography fontSize={14} mb={1.5}>
                      Plot No., Street Name, Landmark
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Plot No., Street Name, Landmark"
                      value={address}
                      onChange={(e: any) => {
                        setInput({
                          ...input,
                          address: e.target.value,
                        });
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography fontSize={14} mb={1}>
                      City
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="City"
                      value={city}
                      onChange={(e: any) => {
                        if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                          setInput({
                            ...input,
                            city: e.target.value,
                          });
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography fontSize={14} mb={1}>
                      Pincode
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Pincode"
                      value={pincode}
                      onChange={(e: any) => {
                        if (/^[0-9]{0,6}$/.test(e.target.value))
                          setInput({
                            ...input,
                            pincode: e.target.value,
                          });
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography fontSize={14} mb={1}>
                      District
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="District"
                      value={district}
                      onChange={(e: any) => {
                        if (/^[a-zA-Z\s]{0,20}$/.test(e.target.value))
                          setInput({
                            ...input,
                            district: e.target.value,
                          });
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography fontSize={14} mb={1}>
                      State
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="State"
                      value={state}
                      onChange={(e: any) => {
                        if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                          setInput({
                            ...input,
                            state: e.target.value,
                          });
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography fontSize={14} mb={1}>
                      Latitude
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Latitude"
                      value={latitude}
                      onChange={(e: any) => {
                        if (/^[-]?[0-9.]{0,11}$/.test(e.target.value))
                          setInput({
                            ...input,
                            latitude: e.target.value,
                          });
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography fontSize={14} mb={1}>
                      Longitude
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Longitude"
                      value={longitude}
                      onChange={(e: any) => {
                        if (/^[-]?[0-9.]{0,11}$/.test(e.target.value))
                          setInput({
                            ...input,
                            longitude: e.target.value,
                          });
                      }}
                    />
                  </Box>
                  <Box sx={{ gridColumn: { sm: "span 2" } }}>
                    <Typography fontSize={14} mb={1}>
                      Country
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Country"
                      value={country}
                      onChange={(e: any) => {
                        if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                          setInput({
                            ...input,
                            country: e.target.value,
                          });
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <SetAvailability
                {...{
                  open,
                  days,
                  setDays,
                  twentyFourSeven,
                  setTwentyFourSeven,
                }}
              />
            </Box>
          </>
        )}

        {step === 3 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "User Info", onEdit: () => setStep(0) },
                  { label: "Owner Name", value: name },
                  { label: "Owner Phone", value: formatPhoneNumberIntl(phone) },
                  { label: "Owner Email", value: email },
                  {
                    label: "Maintainer Name (Optional)",
                    value: maintainerName,
                  },
                  {
                    label: "Maintainer Phone (Optional)",
                    value: maintainerPhone,
                  },
                  {
                    label: "Maintainer Email (Optional)",
                    value: maintainerEmail,
                  },

                  {
                    header: "Charger Info",
                    onEdit: () => setStep(1),
                    required: true,
                  },
                  { label: "Charger UID", value: chargerId, required: true },
                  { label: "Charger Name", value: chargerName, required: true },
                  {
                    label: "Charger Serial No.",
                    value: chargerSerial,
                    required: true,
                  },
                  { label: "RFID", value: rfid, required: true },
                  {
                    label: "Manufacturer",
                    value: manufacturer,
                    required: true,
                  },
                  { label: "Model", value: model, required: true },
                  {
                    label: "Connectors",
                    value: connectors
                      .map((el: any) => el.id + " - " + el.type)
                      .join("\n"),
                  },
                  { label: "Power Rating", value: powerRating || "" },
                  {
                    label: "Communication Mode",
                    value: communicationMode,
                    required: true,
                  },
                  { label: "Usage Type", value: usageType, required: true },
                  ...(isPaid
                    ? [
                        {
                          label: "Energy Tariff",
                          value: !!ownerCostPerkWh
                            ? `${
                                getSymbolFromCurrency(currency) ||
                                `${currency} `
                              }${ownerCostPerkWh}`
                            : "",
                          required: true,
                          isValid: isPriceValid(ownerCostPerkWh),
                        },
                        {
                          label: "Cost Per Unit (kWh)",
                          value: !!costPerkWh
                            ? `${
                                getSymbolFromCurrency(currency) ||
                                `${currency} `
                              }${costPerkWh}`
                            : "",
                          required: true,
                          isValid:
                            isPriceValid(costPerkWh) &&
                            parseFloat(costPerkWh) >
                              parseFloat(ownerCostPerkWh),
                        },
                        {
                          label: "Settlement Frequency",
                          value: settlementCycle || "",
                        },
                        {
                          label: "Payment Method",
                          value:
                            paymentMethodsData?.data?.constructor === Array &&
                            paymentMethodsData.data.length > 0 &&
                            paymentMethodId &&
                            paymentMethodsData.data.find(
                              (el: any) => el._id === paymentMethodId,
                            )
                              ? paymentMethodsData.data.find(
                                  (el: any) => el._id === paymentMethodId,
                                ).bankDetails.bankName +
                                " (•••• " +
                                paymentMethodsData.data
                                  .find((el: any) => el._id === paymentMethodId)
                                  .bankDetails.bankAccount.slice(-4) +
                                ")"
                              : "",
                          required: true,
                        },
                      ]
                    : []),

                  { header: "Station Info", onEdit: () => setStep(2) },
                  // { label: "Station Name", value: stationName },
                  {
                    label: "Plot No., Street Name, Landmark",
                    value: address,
                    required: true,
                  },
                  { label: "City", value: city, required: true },
                  { label: "Pincode", value: pincode, required: true },
                  { label: "District", value: district, required: true },
                  { label: "State", value: state, required: true },
                  { label: "Latitude", value: latitude, required: true },
                  { label: "Longitude", value: longitude, required: true },
                  { label: "Country", value: country, required: true },

                  { header: "Availability", onEdit: () => setStep(2) },
                  { label: "Sunday", value: returnTime(0) },
                  { label: "Monday", value: returnTime(1) },
                  { label: "Tuesday", value: returnTime(2) },
                  { label: "Wednesday", value: returnTime(3) },
                  { label: "Thursday", value: returnTime(4) },
                  { label: "Friday", value: returnTime(5) },
                  { label: "Saturday", value: returnTime(6) },
                ].map(
                  (
                    { header, onEdit, label, value, required, isValid },
                    i,
                    arr,
                  ) => {
                    const isFirst = arr[i - 1]?.header;
                    const isLast = !arr[i + 1] || arr[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <span>{header}</span>
                            <IconButton
                              sx={{ ml: 1.5 }}
                              children={<EditOutlined />}
                              color="primary"
                              size="small"
                              onClick={onEdit}
                            />
                          </td>
                        ) : (
                          <>
                            <td>{label}</td>
                            <td style={{ fontWeight: 500 }}>
                              {["", null, undefined].includes(value) &&
                              required ? (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    sx={{ mr: 0.5 }}
                                  />
                                  Required
                                </Box>
                              ) : (
                                <Box display="flex" alignItems="center">
                                  {value === "" ? "-" : value}
                                  {isValid === false && (
                                    <Box
                                      ml={2}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <WarningAmber
                                        fontSize="small"
                                        color="warning"
                                        sx={{ mr: 0.5 }}
                                      />
                                      Invalid
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          color={
            isComplete(step) || step === steps.length - 1
              ? "primary"
              : "inherit"
          }
          disableElevation
          disabled={isDisabled()}
        >
          {step === steps.length - 1
            ? "Save"
            : isComplete(step) || step === 0
              ? "Next"
              : "Skip"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddChargerDialog;
