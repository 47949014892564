import { AddRounded } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
// import { getPermissions } from "utils";
import AddFileOTADialog from "../AddFileOTADialog";

const AppBar = ({ ota }: any) => {
  // const { canWrite } = getPermissions("housekeeping:ota_management");
  const [modalopen, setModalOpen] = useState(false);
  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h2">Files</Typography>
        <Typography
          sx={{
            ml: 1,
            fontSize: { xs: 16, md: 20 },
            fontWeight: 600,
            color: (theme) => theme.customColors.text.grey,
          }}
        >
          ({ota?.meta?.totalCount || 0})
        </Typography>
      </Box>
      <AddFileOTADialog
        open={modalopen}
        handleClose={() => setModalOpen(false)}
      />
      <Box display="flex" alignItems="space-between">
        <Button
          sx={{
            px: 6,
            py: 1,
            borderRadius: 10,
            borderWidth: "1.5px !important",
            borderColor: (theme) => theme.palette.primary.main,
            textTransform: "none",
            fontWeight: 500,
          }}
          variant="outlined"
          startIcon={<AddRounded />}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Add File
        </Button>
      </Box>
    </Box>
  );
};

export default AppBar;
