import { Box, Button, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  GlobalState,
  authorizedFetch,
  drawer,
  getDarkModePreference,
} from "utils";
import { ReactComponent as DeleteIcon } from "assets/images/icons/delete.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import DeleteDialog from "./DeleteDialog";
import { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { useQuery } from "react-query";
import CircularLoader from "components/CircularLoader";

const InfoDrawer = ({ scm, tab, setTab, onButtonClick, openDialog }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );

  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
  });

  const { isLoading: componentLoading, data: componentData } = useQuery(
    ["getComponentDetails", scm],
    () =>
      authorizedFetch(
        `https://component-details.dev.revos.in/inventory/component/componentDetails/${scm._id}`,
      ),
  );

  const [table, setTable] = useState<any>([]);

  useEffect(() => {
    if (componentData && scm) {
      setTable([
        { header: "Component Information" },
        {
          label: "Company Name",
          value: scm?.oem?.name || "-",
        },
        {
          label: "Vehicle Model",
          value: scm?.vehicleModel?.name || "-",
        },
        {
          value: scm?.parentModel?.key.replace("CONTROLLER_", "") || "-",
          label: "Speedometer Type",
        },
        {
          label: "Serial Number",
          value: scm?.serialNumber || "-",
        },
        {
          label: "MAC ID",
          value: scm?.specs.macId,
        },
        {
          label: "Hardware Version",
          value: scm?.hardware || "-",
        },
        {
          label: "Primary Firmware Version",
          value: scm?.currentOta?.primary?.version || "-",
        },
        {
          label: "Secondary Firmware Version",
          value: scm?.currentOta?.secondary?.version || "-",
        },
        {
          label: "Warranty Details",
          value:
            scm?.warranty?.duration === undefined
              ? "-"
              : scm?.warranty?.duration + " days",
        },
        {
          value: moment(scm.createdAt).format("DD-MM-YYYY"),
          label: "Created On",
        },
        {
          label: "Assigned To",
          value: componentData?.data?.document?.component?.oem?.name || "-",
        },
        {
          label: "Assigned On (Invoice)",
          value: componentData?.data?.document?.component?.warranty?.assignedOn
            ? moment(
                componentData?.data?.document?.component?.warranty?.assignedOn,
              ).format("DD-MM-YYYY")
            : "N/A",
        },
        {
          label: "Warranty Start Date",
          value: componentData?.data?.document?.component?.warranty?.assignedOn
            ? moment(
                componentData?.data?.document?.component?.warranty?.assignedOn,
              ).format("DD-MM-YYYY")
            : "N/A",
        },
        {
          label: "Warranty End Date",
          value: componentData?.data?.document?.component?.warranty?.expiresOn
            ? moment(
                componentData?.data?.document?.component?.warranty?.expiresOn,
              ).format("DD-MM-YYYY")
            : "N/A",
        },
        {
          label: "Status",
          value: componentData?.data?.document?.component?.status || "-",
        },
        { header: "Vehicle Information" },
        {
          label: "Vin",
          value: componentData?.data?.document?.vehicle?.vin || "Not Assigned",
        },
        {
          label: "Vin Created On",
          value: componentData?.data?.document?.vehicle?.createdAt
            ? moment(componentData?.data?.document?.vehicle?.createdAt).format(
                "DD-MM-YYYY",
              )
            : "N/A",
        },
        {
          label: "Activation Status",
          value:
            componentData?.data?.document?.component?.runningStatus || "N/A",
        },
        {
          label: "Sold Status",
          value:
            componentData?.data?.document?.vehicle?.status === "SOLD"
              ? "SOLD"
              : "NOT SOLD" || "N/A",
        },
        { header: "Subscription Information" },
        {
          label: "Subscription Status",
          value: componentData?.data?.document?.subscriptionData
            ? componentData?.data?.document?.subscriptionData[0]?.subscription
                ?.status
            : "N/A",
        },
        {
          label: "Subscription Name",
          value:
            componentData?.data?.document?.subscriptionData &&
            componentData?.data?.document?.subscriptionData[0]?.plans
              ? componentData?.data?.document?.subscriptionData[0]?.plans[0]
                  ?.name
              : "N/A",
        },
        {
          label: "Subscribed On",
          value:
            componentData?.data?.document?.subscriptionData &&
            componentData?.data?.document?.subscriptionData[0]?.subscription
              ?.lastPayedAt
              ? moment(
                  componentData?.data?.document?.subscriptionData[0]
                    ?.subscription?.lastPayedAt,
                ).format("DD-MM-YYYY")
              : "N/A",
        },
        {
          label: "Subscription Validity",
          value:
            componentData?.data?.document?.subscriptionData &&
            componentData?.data?.document?.subscriptionData[0]?.subscription
              ?.lastPayedAt &&
            componentData?.data?.document?.subscriptionData[0]?.subscription
              ?.cycleEndsAt
              ? moment(
                  componentData?.data?.document?.subscriptionData[0]
                    ?.subscription?.cycleEndsAt,
                ).diff(
                  moment(
                    componentData?.data?.document?.subscriptionData[0]
                      ?.subscription?.lastPayedAt,
                  ),
                  "days",
                )
              : "N/A",
        },
        {
          label: "Subscription Ends On",
          value:
            componentData?.data?.document?.subscriptionData &&
            componentData?.data?.document?.subscriptionData[0]?.subscription
              ?.cycleEndsAt
              ? moment(
                  componentData?.data?.document?.subscriptionData[0]
                    ?.subscription?.cycleEndsAt,
                ).format("DD-MM-YYYY")
              : "N/A",
        },
      ]);
    }
  }, [componentData, scm]);

  return (
    <>
      <DeleteDialog
        open={deleteDialog.open}
        handleClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        scm={scm}
        onDelete={() => {
          drawer.close();
          setTab(1);
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {scm?.serialNumber || scm?.specs?.macId}
          <IconButton
            children={<CloseIcon />}
            color="inherit"
            size="small"
            onClick={() => drawer.close()}
          />
        </Box>
        <Box flexGrow={1} overflow="auto" pb={4}>
          <Box
            sx={{
              px: 3,
              pt: 2.5,
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.5,
                  px: 2,
                  "&.secondary": {
                    color: "text.secondary",
                  },
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  position: "relative",
                  "& td": {
                    pt: 2,
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 8.5,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center", // Optional: Align items vertically in the center
              }}
            >
              {tab === 1 ? (
                <Tooltip title="Delete">
                  <IconButton
                    sx={{ mr: 3, mt: 1, float: "right" }}
                    children={<DeleteIcon />}
                    color="inherit"
                    size="small"
                    onClick={() =>
                      setDeleteDialog({
                        open: true,
                      })
                    }
                  />
                </Tooltip>
              ) : (
                ""
              )}
              <Button
                sx={{
                  display: "flex",
                  height: 41,
                  maxWidth: 250,
                  float: "right",
                  borderRadius: 10,
                  borderWidth: "1.5px !important",
                  borderColor: (theme) => theme.palette.primary.main,
                  textTransform: "none",
                  fontWeight: 500,
                  px: 4,
                  ml: "auto",
                  marginRight: 0.5,
                  marginLeft: "8px",
                  backgroundColor: tab === 0 ? "#FA5F55" : "#26C72D",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: tab === 0 ? "#FF524D" : "#2DEA47",
                  },
                }}
                variant="contained"
                onClick={() => {
                  onButtonClick(scm._id);
                }}
              >
                {tab === 0 ? "Recall" : "Assign"}
              </Button>
            </Box>
            <br />
            <table className="table">
              <tbody>
                {componentLoading ? (
                  <CircularLoader />
                ) : (
                  table?.map(({ header, label, value }: any, i: any) => {
                    const isFirst = table[i - 1]?.header;
                    const isLast = !table[i + 1] || table[i + 1].header;
                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <Box
                              sx={{
                                mb: 2,
                                // px: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                height: 2,
                              }}
                            >
                              <span>{header.toUpperCase()}</span>
                              {tab === 1 &&
                              header === "Component Information" ? (
                                <Tooltip title="Edit">
                                  <IconButton
                                    sx={{ marginLeft: "auto" }}
                                    children={<EditIcon />}
                                    color="inherit"
                                    size="small"
                                    onClick={openDialog}
                                  />
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </Box>
                          </td>
                        ) : (
                          <>
                            <td className="bold">{label}</td>
                            <td
                              className={
                                value === "Loading..." ? "secondary" : ""
                              }
                            >
                              {value}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InfoDrawer;
