import {
  EditOutlined,
  HighlightOff,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { drawer, getDarkModePreference, GlobalState, titleCase } from "utils";

const DrawerContent = ({ application, setUpdateDialog }: any) => {
  const [showToken, setShowToken] = useState(false);
  const [applicationInfo, setApplicationInfo] = useState([
    { header: "Application Info" },
    { label: "Name", value: "" },
    { label: "Package", value: "" },
    { label: "Platform", value: "" },
    { label: "Token", value: "" },
    { label: "Kind", value: "" },
    { label: "Created At", value: "" },
  ]);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  const state = useSelector((state: GlobalState) => state.global);

  useEffect(() => {
    if (application) {
      setApplicationInfo([
        { header: "Application Info" },
        { label: "Name", value: application.name },
        { label: "Package", value: application.package },
        { label: "Platform", value: application.platform },
        { label: "Token", value: application.token },
        { label: "Kind", value: application.kind.join(" | ") },
        {
          label: "Created At",
          value: moment(application.createdAt).format("Do MMM, hh:mm a"),
        },
      ]);
    }
  }, [application, state.drawer]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            {application?.name}
            <Avatar
              variant="status"
              className={
                application?.status === "ACTIVE"
                  ? "green"
                  : application?.status === "DELETED"
                  ? "red"
                  : "grey"
              }
            >
              {application?.status
                ? application?.status.split("_").map(titleCase).join(" ")
                : "N/A"}
            </Avatar>
          </Box>
          <Box>
            <IconButton
              children={<EditOutlined />}
              color="inherit"
              size="small"
              onClick={() => {
                setUpdateDialog({
                  open: true,
                  data: application,
                });
              }}
            />
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>

        <>
          <Box flexGrow={1} overflow="auto">
            <Box
              sx={{
                px: 3,
                pt: 2.5,
                // pb: 10,
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 2,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                    width: 135,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",

                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      backgroundColor: (theme) => theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 3,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  {applicationInfo.map(({ header, label, value }: any, i) => {
                    const isFirst = applicationInfo[i - 1]?.header;
                    const isLast =
                      !applicationInfo[i + 1] || applicationInfo[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <span>{header}</span>
                            {/* <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                          /> */}
                          </td>
                        ) : (
                          <>
                            <td className="bold" width={100}>
                              {label === "Token" ? (
                                <Box
                                  display="flex"
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  {label}
                                  <IconButton
                                    onClick={() =>
                                      setShowToken((value) => !value)
                                    }
                                    color="inherit"
                                  >
                                    {application?.token &&
                                      (showToken ? (
                                        <VisibilityOff sx={{ fontSize: 18 }} />
                                      ) : (
                                        <Visibility sx={{ fontSize: 18 }} />
                                      ))}
                                  </IconButton>
                                </Box>
                              ) : (
                                label
                              )}
                            </td>
                            <td>
                              {label === "Token" ? (
                                <Box width={380}>
                                  <Typography fontSize={10} fontWeight="bold">
                                    {showToken ? value : "********"}
                                  </Typography>
                                </Box>
                              ) : (
                                value
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
};

export default DrawerContent;
