import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { authorizedFetch, drawer, setLoader, snackbar } from "utils";
import { LEASE_URL } from "utils/constants";

const ToggleDialog = ({
  open,
  handleClose,
  vendor,
  refetchVendors,
  refetchGraphVendors,
}: any) => {
  function handleToggle() {
    let body = {};
    if (vendor?.status === "ACTIVE") {
      body = {
        deactivate: true,
      };
    } else {
      body = {
        deactivate: false,
      };
    }

    setLoader(true);
    let url = `${LEASE_URL}/company/vendor/${vendor?._id}/deactivate`;
    authorizedFetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => {
        setLoader(false);
        if (
          (vendor?.status === "ACTIVE" && res.msg === "Vendor deactivated") ||
          (vendor?.status === "INACTIVE" && res.msg === "Vendor activated")
        ) {
          snackbar.success(res.msg);
          queryClient.resetQueries("getDistributors");
          refetchVendors();
          refetchGraphVendors();
          drawer.close();
          handleClose();
        } else {
          console.error(res);
          snackbar.error(
            vendor?.status === "ACTIVE"
              ? `Error deactivating vendor`
              : `Error activating vendor`
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(
          vendor?.status === "ACTIVE"
            ? `Error deactivating vendor`
            : `Error activating vendor`
        );
      });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {vendor?.status === "ACTIVE"
          ? "Deactivate Vendor?"
          : "Activate Vendor?"}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ my: 1, mr: 5 }}>
          Are you sure you want to{" "}
          {vendor?.status === "ACTIVE" ? "deactivate" : "activate"}{" "}
          <b>{vendor?.name}</b>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}> Cancel</Button>
        <Button variant="contained" onClick={handleToggle}>
          {vendor?.status === "ACTIVE" ? "Deactivate" : "Activate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToggleDialog;
