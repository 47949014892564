import {
  Cancel,
  CheckCircle,
  ContentCopy,
  HighlightOff,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { drawer, getDarkModePreference, GlobalState, snackbar } from "utils";

const DrawerContent = ({ data }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const [testData, setTestData] = useState([
    { label: "Connected to Register", value: "" },
    { label: "Charger Initialized", value: "" },
    { label: "Connected to Book", value: "" },
    { label: "Charging Booked", value: "" },
    { label: "Charging Started", value: "" },
    { label: "Charging Stopped", value: "" },
    { label: "Booking Ended", value: "" },
    { label: "Charger Booked to Test Emergency Button", value: "" },

    { label: "Emergency Button Tested", value: "" },
  ]);

  const [info, setInfo] = useState<any>([
    { label: "User Id", value: "" },
    { label: "Phone Number", value: "" },
    { label: "Charger Model", value: "" },
  ]);

  useEffect(() => {
    if (data) {
      setTestData([
        {
          label: "Connected to Register",
          value: data?.data?.connectedToRegister,
        },
        { label: "Charger Initialized", value: data?.data?.chargerInitialised },
        { label: "Connected to Book ", value: data?.data?.connectedToBook },
        { label: "Charger Booked", value: data?.data?.chargerBooked },
        { label: "Charging Started", value: data?.data?.chargingStarted },
        { label: "Charging Stopped", value: data?.data?.chargingStopped },

        { label: "Booking Ended ", value: data?.data?.bookingEnded },
        {
          label: "Charger Booked to Test Emergency Button ",
          value: data?.data?.chargerBookedToTestEmergency,
        },
        { label: "Emergency Button Tested", value: data?.data?.emergencyPause },
      ]);
      setInfo([
        { label: "User Id", value: data?.userId },
        { label: "Phone Number", value: data?.phoneNumber || "N/A" },
        { label: "Charger Model", value: data?.chargerModel || "N/A" },
      ]);
    }
  }, [data]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            Diagnostics Details for : {data?.bleMac}
          </Box>
          <Box>
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box flexGrow={1} overflow="auto">
          <Box
            sx={{
              px: 3,
              pt: 2.5,
              // pb: 10,
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  // py: 2,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                  width: 135,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",

                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 7,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {/* <tr className="header">
                  <td colSpan={2}>
                    <span className="label">Charger Owner Info</span>
                  </td>
                </tr>
                {info.map(({ label, value }: any, i: any) => {
                  <tr>
                    <td className="bold">{label}</td>
                    <td>{value}</td>
                  </tr>;
                })} */}
                <tr className={"header"}>
                  <td colSpan={2} style={{ paddingTop: 15, paddingBottom: 15 }}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Info
                    </span>
                  </td>
                </tr>
                {info.map(({ label, value }: any, i: any) => {
                  return (
                    <tr
                      key={i}
                      className={
                        i === 0 ? "first" : i === info.length - 1 ? "last" : ""
                      }
                    >
                      <td className="bold">
                        <Box display="flex" alignItems="center" mt={1}>
                          <Typography fontSize={14} width={250}>
                            {label}
                          </Typography>
                          <Typography fontSize={12}>{value}</Typography>
                        </Box>
                      </td>
                    </tr>
                  );
                })}
                <tr className={"header"}>
                  <td colSpan={2} style={{ paddingTop: 15, paddingBottom: 15 }}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Diagnostics Completed
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14}>
                          {data?.data?.diagnosticsCompleted === "PASS"
                            ? "Passed"
                            : "Failed"}
                        </Typography>
                        {data?.data?.diagnosticsCompleted === "PASS" ? (
                          <CheckCircle
                            color="success"
                            sx={{ fontSize: 16, ml: 1 }}
                          ></CheckCircle>
                        ) : (
                          <Cancel
                            color="error"
                            sx={{ fontSize: 16, ml: 1 }}
                          ></Cancel>
                        )}
                      </Box>
                    </span>
                  </td>
                </tr>
                {testData.map(({ label, value }, i) => {
                  return (
                    <tr
                      key={i}
                      className={
                        i === 0
                          ? "first"
                          : i === testData.length - 1
                          ? "last"
                          : ""
                      }
                    >
                      <td className="bold">
                        <Box display="flex" alignItems="center" mt={1}>
                          <Typography fontSize={14} width={300}>
                            {label}
                          </Typography>
                          {value === "PASS" ? (
                            <CheckCircle
                              color="success"
                              sx={{ fontSize: 16, ml: 1 }}
                            ></CheckCircle>
                          ) : (
                            <Cancel
                              color="error"
                              sx={{ fontSize: 16, ml: 1 }}
                            ></Cancel>
                          )}
                        </Box>
                      </td>
                    </tr>
                  );
                })}
                <tr className={"header"} style={{ marginBottom: 100 }}>
                  <td colSpan={2} style={{ paddingTop: 15, paddingBottom: 15 }}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Diagnostics Data
                      <Tooltip title="Copy JSON Data">
                        <IconButton
                          children={<ContentCopy sx={{ fontSize: 18 }} />}
                          color="inherit"
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              JSON.stringify(data, null, 2)
                            );
                            snackbar.success("JSON data copied to clipboard");
                          }}
                          sx={{ ml: 2 }}
                        />
                      </Tooltip>
                    </span>
                  </td>
                </tr>

                <Box mt={10}>
                  <Typography
                    component="pre"
                    sx={{ fontSize: 14, ml: 2, mb: 3 }}
                  >
                    {JSON.stringify(data, null, 2)}
                  </Typography>
                </Box>
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DrawerContent;
