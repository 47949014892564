import { Box, Paper, Skeleton, Typography, useTheme } from "@mui/material";

import CircularLoader from "components/CircularLoader";
import InfoLabel from "components/InfoLabel";
import { useMemo, useRef } from "react";
import { Bar } from "react-chartjs-2";

import { lerp } from "utils";

const TopVehicles = ({ statsData, isLoading }: any) => {
  const theme = useTheme();

  const chartRef = useRef();

  function convertToTitleCase(inputString: string) {
    // Split the input string into words using camel case pattern
    const words = inputString.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

    // Capitalize the first letter of each word
    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words to form the title case string
    const titleCaseString = titleCaseWords.join(" ");

    return titleCaseString;
  }

  const chartData = useMemo(() => {
    let chartData = (
      statsData?.data?.testCounts?.constructor === Array
        ? statsData.data.testCounts
        : []
    ).map((el: any) => ({
      x: convertToTitleCase(el.test),
      y: Number(el.failed),
    }));
    return chartData;
  }, [statsData]);

  return (
    <Paper
      sx={{
        py: { xs: 3, md: 3 },
        px: { xs: 2, md: 5 },
        display: "flex",
        flexDirection: "column",
        gridColumn: { lg: "span 2" },
      }}
    >
      <Box
        sx={{
          mb: 4,
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" mr={1.25}>
          Top Failed Tests
        </Typography>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width={80}
            height={24}
            sx={{ borderRadius: 10 }}
          />
        ) : (
          <InfoLabel
            dense
            label="Total Failed Tests"
            value={statsData?.data?.total?.totalAssetsFailed}
          />
        )}
      </Box>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            minHeight: 0,
          }}
        >
          <Bar
            ref={chartRef}
            data={{
              labels: chartData?.map((el: any) => el.x) || [],
              datasets: [
                {
                  data: chartData?.map((el: any) => el.y) || [],
                  borderWidth: 0,
                  backgroundColor(ctx: any) {
                    const maxIndex = chartData?.length;
                    const alpha = lerp(1, 0.5, ctx.dataIndex / maxIndex);
                    return `rgba(204, 82, 226, ${alpha})`;
                  },
                  borderRadius: 1,
                  maxBarThickness: 12,
                },
              ],
            }}
            options={{
              onHover: (event: any, chartElement) => {
                const target = event.native
                  ? event.native.target
                  : event.target;
                target.style.cursor = chartElement[0] ? "pointer" : "default";
              },
              indexAxis: "y",
              scales: {
                yAxis: {
                  title: {
                    display: true,
                    text: "Name of Test",
                  },
                  grid: {
                    display: false,
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                  ticks: {
                    display: true,
                    color: theme.palette.text.primary,
                  },
                },
                xAxis: {
                  title: {
                    display: true,
                    text: "No. of Times the Test Failed",
                    padding: {
                      top: 8,
                      bottom: 0,
                    },
                    color: theme.customColors.text.grey,
                    font: {
                      weight: "400",
                      size: 12,
                    },
                  },
                  ticks: {
                    display: true,
                    precision: 0,
                  },
                  grid: {
                    borderDash: [5],
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                },
              },
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  caretSize: 5,
                  mode: "y",
                  intersect: false,
                  yAlign: "center",
                  displayColors: false,
                  caretPadding: 16,
                  titleFont: {
                    weight: "400",
                  },
                  bodyFont: {
                    weight: "500",
                  },
                  callbacks: {
                    title: (xDatapoint) => {
                      return xDatapoint[0].label;
                    },
                    label: (yDatapoint) => {
                      return (
                        yDatapoint.formattedValue +
                        ` / ${statsData?.data?.total?.totalAssetsFailed}`
                      );
                    },
                  },
                },
              },
              interaction: {
                mode: "y",
                intersect: false,
              },
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default TopVehicles;
