// import { Info } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import TableComponent from "components/Table";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { authorizedFetch, getDuration, GlobalState } from "utils";
import { LEASE_URL, RETAIL_URL } from "utils/constants";
import VehicleMonitoring from "./VehicleMonitoring";
import TripsMap from "./VehicleMonitoring/TripsMap";

const RecentTrips = ({ vehicle }: any) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [trip, setTrip] = useState(null);

  // const [driverInfoDialog, setDriverInfoDialog] = useState({
  //   open: false,
  //   data: null,
  // })

  const { vin } = vehicle;
  const { activeSection, company } = useSelector(
    (state: GlobalState) => state.global
  );

  const isFMS = activeSection === "rental";

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const isVeghAccount =
    company.id === "655af967b1118f8efd43d0e5" ? true : false;

  // const url = `${DATAFEED_URL}/v2/trips?token=${company.token}&id=${company.id}&vin=${vin}&first=${first}&skip=${skip}`;

  const vmsTripsUrl = `${RETAIL_URL}/trips/vehicle/${vin}?first=${first}&skip=${skip}`;
  const { isLoading: vmsTripsLoading, data: vmsTripsData } = useQuery(
    ["getVMSTrips", vin, first, skip],
    () => authorizedFetch(vmsTripsUrl),
    { enabled: !isFMS }
  );

  const fmsTripsUrl = `${LEASE_URL}/company/vehicle/${vin}/trips?first=${first}&skip=${skip}&heatMap=true`;
  const { isLoading: fmsTripsLoading, data: fmsTripsData } = useQuery(
    ["getFMSTrips", vin, first, skip],
    () => authorizedFetch(fmsTripsUrl),
    { enabled: isFMS }
  );

  return trip === null ? (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isFMS ? "250px 1fr" : "1fr",
        // gridTemplateColumns: "1fr",
        gap: 2,
      }}
    >
      {isFMS ? <TripsMap data={fmsTripsData?.data?.trips || []} /> : ""}

      <Box width={1} overflow="hidden">
        <TableComponent
          idKey="tripId"
          px={0}
          serverSidePagination
          rowCount={
            isFMS
              ? fmsTripsData?.data?.count || 0
              : vmsTripsData?.meta?.totalCount || 0
          }
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={isFMS ? fmsTripsLoading : vmsTripsLoading}
          rows={
            isFMS
              ? fmsTripsData?.data?.trips?.constructor === Array
                ? fmsTripsData.data.trips
                : []
              : vmsTripsData?.data?.constructor === Array
              ? vmsTripsData.data
              : []
          }
          columns={[
            {
              key: "startTime",
              label: "Start Time",
              format: (value) => moment(value).format("MMM DD, YYYY HH:mm"),
            },
            {
              key: "duration",
              label: "Duration",
              Render: (row) =>
                getDuration(
                  moment
                    .duration(moment(row.endTime).diff(moment(row.startTime)))
                    .asMinutes()
                ),
            },
            {
              key: "maxGpsSpeed",
              label: "Max Speed",
              Render: ({ maxVehicleSpeed, maxGpsSpeed }: any) => {
                return maxVehicleSpeed
                  ? maxVehicleSpeed.toFixed(0) + " km/h"
                  : maxGpsSpeed
                  ? maxGpsSpeed.toFixed(0) + " km/h"
                  : "0 km/h";
              },
            },
            {
              key: "avgGpsSpeed",
              label: "Avg Speed",
              Render: ({ avgVehicleSpeed, avgGpsSpeed }: any) => {
                return avgVehicleSpeed
                  ? avgVehicleSpeed.toFixed(0) + " km/h"
                  : avgGpsSpeed
                  ? avgGpsSpeed.toFixed(0) + " km/h"
                  : "0 km/h";
              },
            },
            // {
            //   key: "startingVoltate",
            //   label: "Starting Voltage",
            //   Render: (trip) =>
            //     trip.batteryVoltageAdc.length > 0 &&
            //     trip.batteryVoltageAdc[trip.batteryVoltageAdc.length - 1] &&
            //     trip.batteryVoltageAdc[trip.batteryVoltageAdc.length - 1]
            //       .voltage
            //       ? trip.batteryVoltageAdc[
            //           trip.batteryVoltageAdc.length - 1
            //         ].voltage.toFixed(2) + "V"
            //       : "N/A",
            // },
            // {
            //   key: "endingVoltage",
            //   label: "Ending Voltage",
            //   Render: (trip) =>
            //     trip.batteryVoltageAdc.length > 0 &&
            //     trip.batteryVoltageAdc[0] &&
            //     trip.batteryVoltageAdc[0].voltage
            //       ? trip.batteryVoltageAdc[0].voltage.toFixed(2) + "V"
            //       : "N/A",
            // },
            {
              key: "distance",
              label: "Distance",
              format: (value) =>
                `${(parseFloat(value || 0) / 1000).toFixed(2)} km`,
            },
            ...(isVeghAccount
              ? []
              : [
                  {
                    key: "driverScore",
                    label: "Effieciency",
                    Render: ({ efficiency }: any) => {
                      return efficiency
                        ? efficiency.toFixed(2) + " Wh/km"
                        : efficiency === 0
                        ? efficiency + " Wh/km"
                        : "NA";
                    },
                  },
                ]),

            // {
            //   key: "driverScore",
            //   label: "Driver Score",
            //   Render: (row) => (
            //     <DriverScore row={row} setDriverInfoDialog={() => {}} />
            //   ),
            // },
            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <Button
                  size="small"
                  variant="action"
                  onClick={() => setTrip(row)}
                >
                  View
                </Button>
              ),
            },
          ]}
        />
      </Box>
    </Box>
  ) : (
    <VehicleMonitoring
      vehicle={vehicle}
      trip={trip}
      setTrip={setTrip}
      isTripsPage
    />
  );
};

// const DriverScore = ({ row }: any) => {
//   const url = `${DATAFEED_URL}/driverscoretrip?tripid=${row?.tripId}&vin=${row?.vin}&token=1234&startTime=${row?.startTime}&endTime=${row?.endTime}`;

//   const { isLoading, data } = useQuery(["getDriverScore", row?.tripId], () =>
//     fetch(url).then((res) => res.json())
//   );

//   return isLoading ? (
//     <CircularProgress size={18} />
//   ) : (
//     <Box display="flex" alignItems={"center"}>
//       <Box mr={1}>
//         {data?.driverScore ? parseFloat(data.driverScore).toFixed(2) : "N/A"}
//       </Box>

//       {/* {data?.userDetails && (
//         <Tooltip title="Driver Info">
//           <IconButton
//             size="small"
//             onClick={() => {
//               setDriverInfoDialog({ open: true, data: data?.userDetails });
//             }}
//           >
//             <Info sx={{ fontSize: 18, opacity: 0.7 }} />
//           </IconButton>
//         </Tooltip>
//       )} */}
//     </Box>
//   );
// };

export default RecentTrips;
