import { useState } from "react";
// import { getPermissions } from "utils";
import {
  Badge,
  Box,
  Button,
  FormControl,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Download, FilterAltOutlined } from "@mui/icons-material";
import FiltersDrawer from "./FiltersDrawer";
import RangeSelector from "components/RangeSelector";

const AppBar = ({
  filters,
  setFilters,
  filterLabels,
  setReportDialog,
  tab,
  setTab,
}: any) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          width: 1,
          py: 3,
          px: { xs: 2, md: 2 },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Diagnostics</Typography>

          <Box ml={3}>
            <Tabs
              className="dense"
              value={tab}
              onChange={(e, value) => setTab(value)}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  letterSpacing: "normal",
                },
              }}
            >
              <Tab label="New API" />
              <Tab label="Old API" />
            </Tabs>
          </Box>
        </Box>

        <Box>
          <Hidden mdUp>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton onClick={() => setDrawerOpen(true)}>
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={![filters.tests].some((el) => el !== "")}
                >
                  <FilterAltOutlined />
                </Badge>
              </IconButton>
            </Box>
            <FiltersDrawer
              {...{
                open: drawerOpen,
                onClose: () => setDrawerOpen(false),
                filters,
                setFilters,
                filterLabels,
              }}
            />
          </Hidden>
          <Hidden mdDown>
            <Box
              sx={{
                display: "flex",
                // flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                mr: 5,
              }}
            >
              <Tooltip
                title="Filter shows only the latest tests performed on a bleMac when LATEST is selected"
                placement="top"
              >
                <FormControl size="small">
                  <Select
                    className="shadow rounded"
                    sx={{ width: 200, mr: 3 }}
                    value={filters.tests}
                    onChange={(e) =>
                      setFilters((prev: any) => ({
                        ...prev,
                        tests: e.target.value,
                      }))
                    }
                    displayEmpty
                    renderValue={(value) =>
                      "Test Type: " +
                      Object.keys(filterLabels.tests).find(
                        (key) => filterLabels.tests[key] === filters.tests,
                      )
                    }
                  >
                    {Object.keys(filterLabels.tests).map((el, i) => (
                      <MenuItem key={i} value={filterLabels.tests[el]}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>

              <FormControl size="small">
                <Select
                  className="shadow rounded"
                  sx={{ width: 200, mr: 3 }}
                  value={filters.sort}
                  onChange={(e) =>
                    setFilters((prev: any) => ({
                      ...prev,
                      sort: e.target.value,
                    }))
                  }
                  displayEmpty
                  renderValue={(value) =>
                    "Sort By: " +
                    Object.keys(filterLabels.sort).find(
                      (key) => filterLabels.sort[key] === filters.sort,
                    )
                  }
                >
                  {Object.keys(filterLabels.sort).map((el, i) => (
                    <MenuItem key={i} value={filterLabels.sort[el]}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <RangeSelector
                initialRange="Last 3 months"
                setRange={(range: any) => {
                  setFilters((prev: any) => ({
                    ...prev,
                    time: range,
                  }));
                }}
              />
              {/* <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 240 }}
                value={filters.tests}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    tests: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) =>
                  "Test Type: " +
                  Object.keys(filterLabels.tests).find(
                    (key) => filterLabels.tests[key] === filters.tests
                  )
                }
              >
                {Object.keys(filterLabels.tests).map((el, i) => (
                  <MenuItem key={i} value={filterLabels.tests[el]}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
              <Button
                sx={{
                  width: 250,
                  ml: 3,
                  px: 6,
                  py: 1,
                  borderRadius: 10,
                  borderWidth: "1.5px !important",
                  borderColor: (theme) => theme.palette.primary.main,
                  textTransform: "none",
                  fontWeight: 500,
                }}
                variant="outlined"
                startIcon={<Download />}
                onClick={() => setReportDialog(true)}
              >
                Download Report
              </Button>
            </Box>
          </Hidden>
        </Box>
      </Box>
    </>
  );
};

export default AppBar;
