import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  // OpenInFull,
} from "@mui/icons-material";
import {
  // Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";

import { LEASE_URL, PAYMENTS_URL } from "utils/constants";
import { useMutation } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

const PaymentDialog = ({
  open,
  data,
  handleClose,
  amount,
  resetQueries,
}: any) => {
  const [isRunning, setIsRunning] = useState(true);

  console.log(amount);

  const [orderStatus, setOrderStatus] = useState("ACTIVE");
  const getStatus = `${PAYMENTS_URL}/v1/payments/orders/cashfree/orderstatus`;

  const startTime = Date.now() / 1000;
  const endTime = startTime + 300;

  const remainingTime = endTime - startTime;

  const intializePay = `${LEASE_URL}/company/booking/${data}/payment/init`;

  const {
    data: initializeData,
    mutate: intializeMutation,
    isLoading: initializeLoading,
  } = useMutation(
    `initPayment`,
    () =>
      authorizedFetch(intializePay, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          token: "1234",
        },
      }),
    {
      onSuccess: (data) => {
        if (data.msg === "Payment initialised") {
          setLoader(false);
          snackbar.success(data.msg);
          if (amount === 0) {
            confirmMutation();
            setOrderStatus("PAID");
          }
        } else {
          snackbar.error(data.msg);
        }
        setLoader(false);
      },
    }
  );

  useEffect(() => {
    if (open && data) {
      intializeMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data]);

  const {
    data: statusData,
    mutate: statusMutation,
    // isLoading: amountLoading,
  } = useMutation(
    `getPaymentStatus`,
    () =>
      authorizedFetch(getStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          orderId: initializeData?.data?.paymentOrder?.order_id,
        },
      }),
    {
      onSuccess: () => {
        setLoader(false);
      },
      onError: () => {},
    }
  );

  useEffect(() => {
    if (open && amount !== 0 && initializeData && orderStatus === "ACTIVE") {
      const status = setInterval(() => {
        statusMutation();
      }, 5000);
      return () => {
        clearInterval(status);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, initializeData, orderStatus]);

  const confirm = `${LEASE_URL}/company/booking/${data}/payment/confirm`;

  const { mutate: confirmMutation } = useMutation(
    `addVehicleBooking`,
    () =>
      authorizedFetch(confirm, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {},
      }),
    {
      onSuccess: (data) => {
        if (data?.msg === "Payment confirmed, Vehicle is booked") {
          snackbar.success(data.msg);
        } else {
          snackbar.error(data.msg);
        }
        setLoader(false);

        resetQueries();
      },
    }
  );

  const fail = `${LEASE_URL}/company/booking/${data}/payment/fail`;

  const { mutate: failMutation } = useMutation(
    `addVehicleBooking`,
    () =>
      authorizedFetch(fail, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {},
      }),
    {
      onSuccess: () => {
        setLoader(false);
        snackbar.success("Payment Cancelled");
        resetQueries();
      },
    }
  );

  useEffect(() => {
    if (statusData) {
      setOrderStatus(statusData?.details?.orderDetails?.orderStatus);
      if (statusData?.details?.orderDetails?.orderStatus === "PAID") {
        console.log(statusData?.details?.orderDetails?.orderStatus);
        confirmMutation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData]);

  useEffect(() => {
    if (!open) {
      setOrderStatus("ACTIVE");
    }
  }, [open]);

  const timerProps = {
    isPlaying: true,
    size: 200,
    strokeWidth: 3,
  };

  const children = ({ remainingTime }: any) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    if (remainingTime === 0) {
      setIsRunning(false);
    }

    return (
      <Typography fontSize={32} color="#00000090">{`${minutes}:${
        seconds < 10 ? `0${seconds}` : seconds
      }`}</Typography>
    );
  };

  useEffect(() => {
    if (!isRunning) {
      failMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 700,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          {/* Add Lease{" "} */}
          {/* <span
            style={{ fontSize: "14px", color: "#00000060", fontWeight: 800 }}
          >
            : Payment
          </span> */}
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: 5,
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontSize={14}
          textAlign="center"
          sx={{ mb: 5 }}
        >
          {orderStatus === "ACTIVE" ? (
            isRunning ? (
              <>
                {initializeLoading ? (
                  <>Generating Payment Link...</>
                ) : (
                  <>
                    A{" "}
                    <a
                      href={initializeData?.data?.paymentOrder?.payment_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Payment link
                    </a>{" "}
                    has been sent to the <strong>Email Id</strong> &{" "}
                    <strong>Phone Number</strong> of the User.
                    <br />
                    Please complete the transaction before the timer ends.
                  </>
                )}
              </>
            ) : (
              <>Payment window is closed. Please create another Booking.</>
            )
          ) : orderStatus === "PAID" ? (
            <Box display="flex" alignItems="center">
              {amount === 0 ? <>Booking Created</> : <>Payment Successful</>}
            </Box>
          ) : (
            <>Error Processing Payment</>
          )}
        </Typography>
        {orderStatus === "ACTIVE" ? (
          <CountdownCircleTimer
            {...timerProps}
            children={children}
            colors="#3CB99E"
            duration={300}
            initialRemainingTime={300}
            onComplete={(totalElapsedTime) => ({
              shouldRepeat: remainingTime - totalElapsedTime > 0,
            })}
          ></CountdownCircleTimer>
        ) : orderStatus === "PAID" ? (
          <CheckCircleOutlineOutlined color="primary" sx={{ fontSize: 100 }} />
        ) : (
          <ErrorOutlineOutlined
            sx={{ fontSize: 100, color: "red" }}
          ></ErrorOutlineOutlined>
        )}

        {orderStatus === "ACTIVE" ? (
          <Button
            sx={{ mt: 5 }}
            variant="outlined"
            size="large"
            onClick={() => {
              failMutation();
              resetQueries();
              handleClose();
            }}
          >
            Cancel Booking
          </Button>
        ) : orderStatus === "PAID" ? (
          <Button
            sx={{ mt: 5 }}
            variant="outlined"
            size="large"
            onClick={() => {
              handleClose();
            }}
          >
            Back to Leases
          </Button>
        ) : (
          <Button
            sx={{ mt: 5 }}
            variant="outlined"
            size="large"
            onClick={() => {
              handleClose();
            }}
          >
            Back to Leases
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;
