import { AddRounded, FilterAltOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  FormControl,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
// import { getPermissions } from "utils";
import FiltersDrawer from "./FiltersDrawer";

const AppBar = ({
  setCreateDialog,
  filters,
  setFilters,
  filterLabels,
}: any) => {
  // const { canWrite } = getPermissions("housekeeping:charger_tickets");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  console.log(isMdDown);

  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h2">Developer Section</Typography>
        {/* {!tripsLoading && (
          <Typography
            sx={{
              ml: 1,
              fontSize: { xs: 16, md: 20 },
              fontWeight: 600,
              color: (theme) => theme.customColors.text.grey,
            }}
          >
            ({(counts?.all || 0).toLocaleString()})
          </Typography>
        )} */}
      </Box>
      <Box>
        <Hidden mdUp>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => setDrawerOpen(true)}>
              <Badge
                color="primary"
                variant="dot"
                invisible={
                  ![filters.status, filters.type].some((el) => el !== "")
                }
              >
                <FilterAltOutlined />
              </Badge>
            </IconButton>
            <Button
              sx={{
                ml: 2,
                px: 0.5,
                minWidth: 0,
              }}
              variant="outlined"
              onClick={setCreateDialog}
            >
              <AddRounded />
            </Button>
          </Box>
          <FiltersDrawer
            {...{
              open: drawerOpen,
              onClose: () => setDrawerOpen(false),
              filters,
              setFilters,
              filterLabels,
            }}
          />
        </Hidden>

        {
          <Hidden mdDown>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                alignItems: "center",
                "& > :not(:first-of-type)": {
                  ml: 3,
                },
              }}
            >
              <FormControl size="small">
                <Select
                  className="shadow rounded"
                  sx={{ width: 240 }}
                  value={filters.sortParams}
                  onChange={(e) =>
                    setFilters((prev: any) => ({
                      ...prev,
                      sortParams: e.target.value,
                    }))
                  }
                  displayEmpty
                  renderValue={(value) =>
                    "Sort By: " +
                    Object.keys(filterLabels.sortParams).find(
                      (key) =>
                        filterLabels.sortParams[key] === filters.sortParams
                    )
                  }
                >
                  {Object.keys(filterLabels.sortParams).map((el, i) => (
                    <MenuItem key={i} value={filterLabels.sortParams[el]}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <Select
                  className="shadow rounded"
                  sx={{ width: 160 }}
                  value={filters.status}
                  onChange={(e) =>
                    setFilters((prev: any) => ({
                      ...prev,
                      status: e.target.value,
                    }))
                  }
                  displayEmpty
                  renderValue={(value) =>
                    "Status: " +
                    Object.keys(filterLabels.status).find(
                      (key) => filterLabels.status[key] === filters.status
                    )
                  }
                >
                  {Object.keys(filterLabels.status).map((el, i) => (
                    <MenuItem key={i} value={filterLabels.status[el]}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                sx={{
                  // width: { md: 300 },
                  ml: 3,
                  px: 6,
                  py: 1,
                  borderRadius: 10,
                  borderWidth: "1.5px !important",
                  borderColor: (theme) => theme.palette.primary.main,
                  textTransform: "none",
                  fontWeight: 500,
                }}
                variant="outlined"
                startIcon={<AddRounded />}
                onClick={() => setCreateDialog(true)}
              >
                Add New
              </Button>
            </Box>
          </Hidden>
        }
      </Box>
    </Box>
  );
};

export default AppBar;
