import { Download, NotificationsOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { setGlobalState } from "actions";
import moment from "moment";
// import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "utils";
// import { TRIGGER_URL } from "utils/constants";
import { queryClient } from "index";
import { io } from "socket.io-client";
import { CONSUMER_REPORTS_URL, TRIGGER_WS_URL } from "utils/constants";
import NotificationsDrawer from "./NotificationsDrawer";

function isValidNotification(notification: any) {
  if (!notification) return false;
  if (!notification?.message) return false;
  if (!notification?.timestamp) return false;

  return true;
}

const NotificationsPopover = () => {
  const {
    company,
    // notifications,
    lastReadNotification,
    activeSection,
  } = useSelector((state: GlobalState) => state.global);

  const isCMS = activeSection === "charger";

  const dispatch = useDispatch();

  const socketRef = useRef<any>(null);
  const reportsSocketRef = useRef<any>(null);

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(true);

  const [notifications, setNotifications] = useState<any>([]);
  const [reportsNotifications, setReportsNotifications] = useState<any>([]);

  const [unreadCount, setUnreadCount] = useState(0);
  const [reportsUnreadCount, setReportsUnreadCount] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (socketRef.current === null) {
      socketRef.current = io(`${TRIGGER_WS_URL}/triggers`, {
        path: "/socket.io",
        transports: ["websocket", "xhr-polling", "jsonp-polling", "polling"],
      });
      const socket = socketRef.current;

      socket.emit("roomId", company?.id);
      socket.on("notification", (data: any) => {
        setLoading(false);

        if (!isValidNotification(data)) return;

        if (
          !lastReadNotification ||
          moment(lastReadNotification).valueOf() <
            moment(data.timestamp).valueOf()
        )
          setUnreadCount((prev: any) => prev + 1);

        setNotifications((prev: any) => {
          prev.unshift(data);
          return prev;
        });
      });
      setInterval(() => {
        setLoading(false);
      }, 5000);
    }

    if (reportsSocketRef.current === null) {
      reportsSocketRef.current = io(`${CONSUMER_REPORTS_URL}/report`, {
        path: "/socket.io",
        transports: ["websocket", "xhr-polling", "jsonp-polling", "polling"],
      });
      const reportsSocket = reportsSocketRef.current;

      reportsSocket.emit("roomId", company?.id);
      reportsSocket.on("notification", (data: any) => {
        setLoading(false);

        let notification: any = data;

        if (!isValidNotification(data)) return;

        if (data.message.includes("Report is ready")) {
          queryClient.resetQueries(["get reports"]);
          notification = {
            message: JSON.parse(data.message).message,
            link: JSON.parse(data.message).link,
            timestamp: data.timestamp,
          };
        }

        if (
          !lastReadNotification ||
          moment(lastReadNotification).valueOf() <
            moment(data.timestamp).valueOf()
        ) {
          setUnreadCount((prev: any) => prev + 1);
          setReportsUnreadCount((prev: any) => prev + 1);
        }

        setNotifications((prev: any) => {
          prev.unshift(notification);
          return prev;
        });
        setReportsNotifications((prev: any) => {
          prev.unshift(notification);
          return prev;
        });
      });
      setInterval(() => {
        setLoading(false);
      }, 5000);
    }

    return () => {
      socketRef.current?.close();
      reportsSocketRef.current?.close();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (notifications.length === 0 || anchor === null) return;

    dispatch(setGlobalState({ lastReadNotification: notifications[0].time }));
    setUnreadCount(0);
    setReportsUnreadCount(0);

    // eslint-disable-next-line
  }, [anchor, notifications]);

  return (
    <Box>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="top"
        PaperProps={{
          sx: { width: 1 },
        }}
      >
        <NotificationsDrawer
          notifications={isCMS ? reportsNotifications : notifications}
          onClose={() => setDrawerOpen(false)}
        />
      </Drawer>
      <Tooltip title="Notifications">
        <IconButton
          onClick={(e) => setAnchor(e.currentTarget)}
          color="inherit"
          sx={{
            "& .MuiBadge-badge": {
              color: "white",
            },
          }}
        >
          <Badge
            color="primary"
            overlap="circular"
            badgeContent={isCMS ? reportsUnreadCount : unreadCount}
          >
            <NotificationsOutlined />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        sx={{
          "& .header": {
            marginLeft: "0.5em",
            fontSize: "1.25em",
            fontWeight: 500,
          },
          "& .MuiPaper-root": {
            boxShadow: (theme) => theme.shadows[3],
            p: 0,
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 280,
            maxWidth: 320,
            height: 400,
          },
        }}
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : notifications.length === 0 ? (
          "No new notifications"
        ) : (
          <Box
            sx={{
              width: 1,
              height: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: 1,
                p: 1,
                px: 1.5,
                pb: 0.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                Notifications
              </Typography>
              <Button
                sx={{ textTransform: "none" }}
                size="small"
                // color="info"
                onClick={() => {
                  setAnchor(null);
                  setDrawerOpen(true);
                }}
              >
                View All
              </Button>
            </Box>
            <Divider />
            <List dense sx={{ flexGrow: 1, overflow: "auto" }}>
              {(isCMS ? reportsNotifications : notifications)
                ?.slice(0, 10)
                .map((notification: any, i: number) => (
                  <ListItem key={i}>
                    <Box width={1}>
                      <Typography variant="body2">
                        {notification?.message || "..."}
                      </Typography>
                      {notification.link && (
                        <Button
                          sx={{ textTransform: "none", mt: 0.5, mb: -0.5 }}
                          size="small"
                          variant="outlined"
                          className="rounded"
                          startIcon={<Download fontSize="small" />}
                          onClick={() =>
                            window.open(notification.link, "_blank")
                          }
                        >
                          Download
                        </Button>
                      )}
                      <Typography
                        variant="caption"
                        sx={{
                          display: "block",
                          textAlign: "right",
                          color: "text.disabled",
                        }}
                      >
                        {moment(notification?.timestamp).calendar()}
                      </Typography>
                    </Box>
                  </ListItem>
                ))}
            </List>
          </Box>
        )}
      </Popover>
    </Box>
  );
};

export default NotificationsPopover;
