import { Box, Paper, Skeleton, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

const colors: any = {
  pass: "#01A229",
  fail: "#EB5C5C",
};

const styles: any = {};

Object.keys(colors).forEach((label) => {
  styles[`&.${label.replaceAll(" ", "_")}::before`] = {
    bgcolor: colors[label],
  };
});

const TestOverview = ({ statsData, isLoading }: any) => {
  const [data, setData] = useState<any>({
    total: 0,
    pass: 0,
    fail: 0,
  });

  useEffect(() => {
    if (statsData) {
      setData({
        total: statsData?.data?.total?.totalAssetsTested,
        pass: statsData?.data?.total?.totalAssetsPassed,
        fail: statsData?.data?.total?.totalAssetsFailed,
      });
    }
  }, [statsData]);

  return (
    <Paper
      sx={{
        py: { xs: 3, md: 4 },
        px: { xs: 2, md: 5 },
        gridColumn: { lg: "span 1" },
      }}
    >
      <Box
        sx={{
          minWidth: { xs: 0, md: 360 },
          mb: { xs: 2, md: 7.5 },
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 20,
        }}
      >
        <Typography variant="h3" mr={2.5} mb={{ xs: 2.5, md: 0 }}>
          Test Overview
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "140px auto",
            md: "minmax(0, 265px) auto",
          },
          gap: { xs: 3, md: 4 },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              width: 1,
              position: "relative",
              pt: "100%",
            }}
          >
            <Skeleton
              variant="circular"
              sx={{
                position: "absolute",
                top: 0,
                width: 1,
                height: 1,
              }}
            />
          </Box>
        ) : (
          <Box width={1} alignSelf="center" position="relative">
            <Doughnut
              style={{ position: "relative", zIndex: 2 }}
              data={(canvas) => {
                return {
                  datasets: [
                    {
                      data: [data.pass, data.fail],
                      backgroundColor: Object.values(colors),
                      hoverBackgroundColor: Object.values(colors),
                      borderWidth: 0,
                      cutout: "85%",
                      hoverOffset: 3,
                    },
                  ],
                  labels: ["Tests Passed", "Tests Failed"],
                };
              }}
              options={{
                // onClick: (e: any, element: any) => {
                //   let dataIndex = element[0].index;

                //   history.push(
                //     "/vehicles?status=" +
                //       e?.chart?.data?.labels[dataIndex].replace(/\s+/, "")
                //   );
                // },
                onHover: (event: any, chartElement) => {
                  const target = event.native
                    ? event.native.target
                    : event.target;
                  target.style.cursor = chartElement[0] ? "pointer" : "default";
                },
                layout: {
                  padding: 5,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                  },
                },
              }}
            />
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "35%",
                right: 0,
                left: 0,
                mx: "auto",
                pointerEvents: "none",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              <Typography fontSize={{ xs: 18, md: 32 }} fontWeight={700}>
                {data.total}
              </Typography>
              <Typography fontSize={14}>Total</Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: 1,
            minWidth: 0,
            maxWidth: 210,
            justifySelf: "end",
            height: "fit-content",
            ml: 2,
            display: "grid",
            gridTemplateColumns: "1fr auto",
            rowGap: 4,
            columnGap: 1,
            fontSize: { xs: 12, md: 16 },
            "& .label": {
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                mt: 0.25,
                transform: "translateX(-15.96px)",
                borderRadius: 10,
                width: "6.39px",
                height: "17.89px",
              },
              "&.Passed::before": {
                bgcolor: colors.pass,
              },
              "&.Failed::before": {
                bgcolor: colors.fail,
              },
            },
            "& .value": {
              // textAlign: "right",
              fontWeight: 700,
            },
          }}
        >
          {[
            {
              label: "Passed",
              value: data.pass,
            },
            {
              label: "Failed",
              value: data.fail,
            },
          ].map((el, i) => (
            <Fragment key={i}>
              {isLoading ? (
                <Skeleton width={150} />
              ) : (
                <span className={`label ${el.label.replaceAll(" ", "_")}`}>
                  {el.label}
                </span>
              )}
              {isLoading ? (
                <Skeleton width={20} />
              ) : (
                <span className="value">{el.value} </span>
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default TestOverview;
