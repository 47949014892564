import {
  CurrencyRupeeOutlined,
  FileDownloadOutlined,
  FilterAltOutlined,
  SearchOutlined,
  Sort,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Search from "components/Search";
import { saveAs } from "file-saver";
import moment from "moment";
import { useState } from "react";
import { authorizedFetch, setLoader, snackbar, GlobalState } from "utils";
import { REPORTS_URL } from "utils/constants";
import FiltersDrawer from "./FiltersDrawer";
import RevenueSplitDialog from "./RevenueSplitDialog";
import { useSelector } from "react-redux";

const filterLabels: any = {
  sortBy: {
    CREATED_AT_DESC: "Date Initialized (Newest First)",
    CREATED_AT_ASC: "Date Initialized (Oldest First)",
    BOOKINGS_DESC: "Total Bookings (High to Low)",
    BOOKINGS_ASC: "Total Bookings (Low to High)",
    AMOUNT_DESC: "Earnings (High to Low)",
    AMOUNT_ASC: "Earnings (Low to High)",
    CHARGE_PER_HOUR_DESC: "Cost Per Hour (High to Low)",
    CHARGE_PER_KWH_DESC: "Cost Per kWh (High to Low)",
  },
  health: {
    HEALTHY: "Used Within Last 15 Days",
    MODERATE: "Used Between Last 16-30 Days",
    CRITICAL: "Used Between Last 31-45 Days",
    INACTIVE: "Used Before 45 Days",
  },
  usageType: {
    PUBLIC_FREE: "Public Free",
    PUBLIC_PAID: "Public Paid",
    PRIVATE: "Private",
    RESTRICTED_FREE: "Restricted Free",
    RESTRICTED_PAID: "Restricted Paid",
  },
  mode: {
    BLE: "BLE",
    SIM: "SIM",
    WIFI: "Wi-Fi",
  },
  // protocol: {
  //   OCPP: "OCPP",
  //   LITE: "LITE",
  //   MQTT: "MQTT",
  // },
  productType: {
    BOLT_V2: "Bolt.Earth V2",
    BOLT_LITE: "Bolt.Earth Lite",
    BOLT_LEVEL_2: "Bolt.Earth Level 2",
    BOLT_LEVEL_3: "Bolt.Earth Level 3",
    BOLT_PRO: "Bolt.Earth Pro",
    LEVAC_BLE_LEVEL_1: "Bolt.Earth LEVAC",
  },
};

const downloadChargerReport = (params: any) => {
  const chargerReportURL = `${REPORTS_URL}/v2/charger/reports/filter?${params}`;
  setLoader(true);
  authorizedFetch(chargerReportURL)
    .then((data) => {
      setLoader(false);
      if (data.file) {
        const fileUrl = data.file;
        let fileName = `Charger Report - ${moment(new Date()).format(
          "DD-MM-YYYY"
        )}.xlsx`;
        saveAs(fileUrl, fileName);
        snackbar.success("Report downloaded");
      } else if (data.message) {
        snackbar.error(data.message);
      }
    })
    .catch((error) => {
      setLoader(false);
      console.error(error);
      snackbar.error("An error occurred");
    });
};

const ChargerFilters = ({ filters, setFilters, urlParams }: any) => {
  const { company } = useSelector((state: GlobalState) => state.global);
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";
  const [sortAnchorEl, setSortAnchorEl] = useState<any>(null);
  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);
  const [revenueSplitDialog, setRevenueSplitDialog] = useState({ open: false });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {!isCUGMonitorAccount && (
        <>
          <Tooltip title="Revenue Splitting" placement="top">
            <IconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={() =>
                setRevenueSplitDialog({ ...revenueSplitDialog, open: true })
              }
              children={<CurrencyRupeeOutlined fontSize="medium" />}
            />
          </Tooltip>
          <RevenueSplitDialog
            open={revenueSplitDialog.open}
            handleClose={() =>
              setRevenueSplitDialog({ ...revenueSplitDialog, open: false })
            }
          />
          <Tooltip title="Download Reports" placement="top">
            <IconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={() => downloadChargerReport(urlParams)}
              children={<FileDownloadOutlined fontSize="medium" />}
            />
          </Tooltip>
          <Tooltip
            title={`Sort by: ${filterLabels.sortBy[filters.sortBy]}`}
            placement="top"
          >
            <IconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={(e) => setSortAnchorEl(e.currentTarget)}
            >
              <Sort />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={sortAnchorEl}
            open={Boolean(sortAnchorEl)}
            onClose={() => setSortAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {Object.entries(filterLabels.sortBy).map(
              ([value, label]: any, i) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    setFilters({ ...filters, sortBy: value });
                    setSortAnchorEl(null);
                  }}
                  selected={filters.sortBy === value}
                >
                  {label}
                </MenuItem>
              )
            )}
          </Menu>
          <Tooltip title="Filter" placement="top">
            <IconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={() => setFiltersDrawerOpen(true)}
            >
              <Badge
                color="primary"
                variant="dot"
                invisible={
                  ![
                    filters.health,
                    filters.usageType,
                    filters.mode,
                    filters.productType,
                    filters.city,
                    filters.hidden,
                  ].some((el) => !!el)
                }
              >
                <FilterAltOutlined />
              </Badge>
            </IconButton>
          </Tooltip>
          <FiltersDrawer
            open={filtersDrawerOpen}
            onClose={() => setFiltersDrawerOpen(false)}
            {...{ filters, setFilters, filterLabels }}
          />
        </>
      )}
      <Tooltip title="Search by..." placement="top">
        <Select
          sx={{
            mr: 1,
            "& .MuiInputBase-input": {
              fontSize: 14,
            },
          }}
          size="small"
          className="rounded"
          value={filters.searchBy}
          onChange={(e) => setFilters({ ...filters, searchBy: e.target.value })}
          startAdornment={
            <SearchOutlined
              fontSize="small"
              color="action"
              sx={{ ml: -0.5, mr: 0.5 }}
            />
          }
        >
          <MenuItem value="chargerId">Charger UID</MenuItem>
          <MenuItem value="owner">Owner</MenuItem>
          <MenuItem value="phone">Phone</MenuItem>
        </Select>
      </Tooltip>
      <Search
        value={filters.search}
        handleSearch={(value) => {
          setFilters({ ...filters, search: value });
        }}
        persist
        enableClear
        hideIcon
      />
    </Box>
  );
};

export default ChargerFilters;
