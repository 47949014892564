import { EditOutlined, HighlightOff, InfoOutlined } from "@mui/icons-material";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,

  // InputAdornment,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";

import { AddPhotoAlternate } from "@mui/icons-material";

import { LEASE_URL } from "utils/constants";

const AddPricingDialog = ({
  open,
  handleClose,
  data,
  type,
  drawer,
  resetQueries,
}: any) => {
  const [step, setStep] = useState(0);
  const steps = ["Image Info", "Finish"];

  const [selectedImage, setSelectedImage] = useState<any>([]);

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  // function isComplete(step: number) {
  //   switch (step) {
  //     case 0:
  //       return selectedImage.length !== 0;
  //     default:
  //       break;
  //   }
  // }

  const [form, setForm] = useState<any>();

  // const disabled = selectedImage.length === 0;

  useEffect(() => {
    if (!open) {
      setSelectedImage([]);
      setStep(0);
    }
  }, [open]);

  const [urls, setUrls] = useState([""]);

  const addURL = `${LEASE_URL}/company/booking/${data?.id}/images?type=${type}`;

  useEffect(() => {
    if (selectedImage) {
      let formData = new FormData();

      // eslint-disable-next-line
      selectedImage?.map((el: any) => {
        formData.append("files", el);
      });

      setForm(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  const addMutation = useMutation(
    "addImages",
    () =>
      authorizedFetch(addURL, {
        method: "POST",
        headers: {
          token: "1234",
        },
        onlyBody: form,
      }),
    {
      onSuccess: (data) => {
        setLoader(false);

        if (data && data.msg === "Images uploaded successfully") {
          snackbar.success(data.msg);
        } else {
          snackbar.error(data.msg);
        }

        drawer.close();
        resetQueries();
        handleClose();
      },
      onError: () => {
        setLoader(false);
        snackbar.error(`Error adding image`);
      },
    }
  );

  useEffect(() => {
    if (selectedImage) {
      let imgArray: any = [];
      // eslint-disable-next-line
      selectedImage.map((el: any, i: any) => {
        imgArray.push({ url: URL.createObjectURL(el), id: `Image ${i + 1}` });
      });
      setUrls(imgArray);
    }
  }, [selectedImage]);

  const startURL = `${LEASE_URL}/company/booking/${data?.id}/start`;

  const startMutation = useMutation(
    `startBooking`,
    () =>
      authorizedFetch(startURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: (data) => {
        if (data && data.msg === "Booking started") {
          snackbar.success(data.msg);
        } else {
          snackbar.error(data.msg);
        }
        drawer.close();
        resetQueries();
        setLoader(false);
      },
      onError: () => {
        snackbar.error("Error starting booking");
      },
    }
  );

  const [extraFields, setExtraFields] = useState<any>([]);
  const [inputFields, setInputFields] = useState<any>([]);

  const [addedFields, setAddedFields] = useState([]);

  useEffect(() => {
    if (extraFields) {
      const arr = extraFields.map((el: any) => {
        return {
          name: el.name,
          key: el.key,
          value: inputFields[el.key],
        };
      });
      setAddedFields(arr);
    }
  }, [extraFields, inputFields]);

  const fields =
    extraFields?.length !== 0
      ? {
          fields: {
            stage: "end",
            fields: addedFields.map(({ key, name, value }) => ({
              key,
              name,
              value: [null, undefined].includes(value)
                ? key === "TotalPackets"
                  ? 0
                  : ""
                : value,
            })),
          },
        }
      : [];

  const displayFields: any =
    extraFields?.length !== 0 && type === "end"
      ? addedFields.map((el: any) => {
          return {
            label: el.name,
            value: inputFields[el.key],
            required: true,
          };
        })
      : [];

  const endURL = `${LEASE_URL}/company/booking/${data?.id}/end`;

  const endMutation = useMutation(
    `endBooking`,
    () =>
      authorizedFetch(endURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          ...fields,
        },
      }),
    {
      onSuccess: () => {
        snackbar.success("Booking Ended");
        // if (data && data.msg === "Booking ended") {
        //   console.log(data);
        //   snackbar.success(data.msg);
        // } else {
        //   snackbar.error(data.msg);
        // }
        drawer.close();
        resetQueries();
        setLoader(false);
      },
      onError: () => {
        snackbar.error("Error ending booking");
      },
    }
  );

  // get fields

  const fieldsUrl = `${LEASE_URL}/company/fields`;
  const { data: fieldsData } = useQuery(
    ["getFields"],
    () => authorizedFetch(fieldsUrl),
    {
      enabled: open && type === "end",
    }
  );

  useEffect(() => {
    if (fieldsData && Object.keys(fieldsData?.data).length !== 0) {
      const endFields: any = fieldsData?.data
        ?.filter((el: any) => el.stage === "end")
        ?.map((el: any) => el.fields[0]);

      const inputFields: any = {};

      endFields?.forEach((el: any) => {
        inputFields[el.key] = el.type === "integer" ? null : "";
      });

      setExtraFields(endFields);
      setInputFields(inputFields);
    }
  }, [fieldsData]);

  const handleSave = () => {
    setLoader(true);
    if (selectedImage.length !== 0) {
      addMutation.mutate();
    }
    if (type === "start") {
      startMutation.mutate();
    } else {
      endMutation.mutate();
    }
    handleClose();
  };

  const [display, setDisplay] = useState<any>([
    { label: `Image`, value: "", required: true },
  ]);

  useEffect(() => {
    if (urls.length !== 0) {
      let displayImages: any = [];
      // eslint-disable-next-line
      urls.map((el: any, i: any) => {
        displayImages.push({ label: `Image No. ${i + 1}`, value: el.url });
      });
      setDisplay(displayImages);
    }
    if (urls.length === 0) {
      let displayImages: any = [];
      displayImages.push({ label: `Image`, value: "", required: true });
      setDisplay(displayImages);
    }
  }, [urls]);

  useEffect(() => {
    setInputFields([]);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        {type === "start" ? "Start" : "End"} Booking
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
                rowGap: 5,
              }}
            >
              {type === "end"
                ? extraFields?.map((el: any) => {
                    return (
                      <>
                        <Box
                          // sx={{
                          //   "& .required": {
                          //     color: "red",
                          //   },
                          // }}
                          key={el.key}
                          gridColumn={{ sm: "span 2" }}
                        >
                          <Typography
                            className="label"
                            sx={{
                              display: "flex",
                              // justifyContent: "center",
                              alignItems: "center",
                              // ml: 2.5,
                            }}
                          >
                            {el.name}{" "}
                            <Tooltip
                              title={`${
                                data?.customFields
                                  ? data?.customFields[0]?.fields[0]?.value
                                  : "-"
                              } packets to be delivered`}
                            >
                              <InfoOutlined
                                sx={{ fontSize: 15, ml: 0.5, opacity: 0.5 }}
                              />
                            </Tooltip>
                          </Typography>

                          <TextField
                            fullWidth
                            type="number"
                            size="small"
                            value={
                              inputFields[el.key] !== 0
                                ? inputFields[el.key]
                                : null
                            }
                            placeholder={
                              el.type === "integer" ? "0" : "Add Value"
                            }
                            autoComplete="off"
                            required
                            onChange={(e: any) => {
                              if (e.target.value.length <= 3) {
                                setInputFields({
                                  ...inputFields,
                                  [el.key]:
                                    e.target.value < 0
                                      ? 0
                                      : Number(e.target.value),
                                });
                              }
                            }}
                            error={
                              inputFields[el.key] >
                              data?.customFields[0]?.fields[0]?.value
                            }
                            helperText={
                              inputFields[el.key] >
                              data?.customFields[0]?.fields[0]?.value
                                ? `Delivered packets can't be more than ${data?.customFields[0]?.fields[0]?.value}`
                                : ""
                            }
                          />
                        </Box>
                      </>
                    );
                  })
                : ""}
              <Box gridColumn={{ sm: "span 2" }}>
                {selectedImage.length ? (
                  <>
                    {selectedImage?.map((el: any, i: any) => {
                      return (
                        <Box
                          width={1}
                          height="auto"
                          display="flex"
                          justifyContent="space-between"
                          mb={4}
                          key={i}
                        >
                          <Box>
                            <Typography className="label">
                              Image No. {i + 1}
                            </Typography>
                            <Button
                              sx={{
                                fontSize: 10,
                                mt: -2,
                                maxHeight: 20,
                                maxWidth: 12,
                              }}
                              variant="outlined"
                              onClick={() => {
                                let newArray: any = [];
                                // eslint-disable-next-line
                                selectedImage.map((el2: any) => {
                                  if (el.name !== el2.name) {
                                    newArray.push(el2);
                                  }
                                });
                                setSelectedImage(newArray);
                              }}
                            >
                              Remove
                            </Button>
                          </Box>

                          <img
                            src={URL.createObjectURL(el)}
                            style={{
                              width: "50%",
                              height: "auto",
                              objectFit: "contain",
                            }}
                            alt={el.name}
                          />
                        </Box>
                      );
                    })}

                    <Box sx={{ width: "100%", display: "flex", gap: 2, my: 3 }}>
                      <Button
                        sx={{ width: "50%" }}
                        variant="contained"
                        component="label"
                      >
                        Add Images
                        <input
                          type="file"
                          hidden
                          multiple
                          name="myImage"
                          accept="image/*"
                          onChange={(event: any) => {
                            if (
                              selectedImage.length + event.target.files.length >
                              6
                            ) {
                              alert("You can upload maximum 6 images");
                            } else {
                              setSelectedImage([
                                ...selectedImage,
                                ...event.target.files,
                              ]);
                            }
                          }}
                        />
                      </Button>
                      <Button
                        onClick={() => {
                          setSelectedImage([]);
                        }}
                        sx={{ width: "50%" }}
                        variant="outlined"
                      >
                        Remove All Images
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      my: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: 2,
                        minHeight: 150,
                        minWidth: 150,
                      }}
                      component="label"
                    >
                      <AddPhotoAlternate sx={{ fontSize: 50 }} />
                      <input
                        type="file"
                        hidden
                        name="myImage"
                        accept="image/*"
                        onClick={() => {
                          console.log("click");
                        }}
                        onChange={(event: any) => {
                          if (event.target.files.length > 6) {
                            alert("You can upload maximum 6 images");
                          } else {
                            console.log(event.target.files[0]);
                            setSelectedImage([...event.target.files]);
                            console.log([...event.target.files]);
                          }
                        }}
                        multiple
                      />
                    </Button>
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: 14,
                        fontWeight: "medium",
                        opacity: 0.6,
                      }}
                    >
                      Upload Images
                    </Typography>
                    <Typography
                      sx={{
                        // mt: 2,
                        fontSize: 12,
                        fontWeight: "medium",
                        opacity: 0.6,
                      }}
                    >
                      (Optional)
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Booking Info", onEdit: () => setStep(0) },
                  ...displayFields,
                  ...display,
                ].map(({ header, onEdit, label, value, required }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          {value !== "" ? (
                            <>
                              <td>{label}</td>
                              <td>
                                {label.includes("Image") ? (
                                  <img
                                    style={{
                                      width: "auto",
                                      height: "300px",
                                      maxWidth: "100%",
                                      objectFit: "contain",
                                    }}
                                    alt={value}
                                    src={value}
                                  />
                                ) : (
                                  value
                                )}
                              </td>
                            </>
                          ) : (
                            ""
                          )}
                          {value === "" ? (
                            <>
                              <td>Images</td>
                              <td className="bold">
                                <Box display="flex" alignItems="center">
                                  {/* <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    sx={{ mr: 1 }}
                                  /> */}
                                  Not Added
                                </Box>
                              </td>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={"contained"}
          disableElevation
          // disabled={step === steps.length - 1 && disabled}
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPricingDialog;
