import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";

const DeleteDialog = ({
  open,
  handleClose,
  otaId,
  onDelete,
  refectData,
}: any) => {
  function handleDelete() {
    setLoader(true);
    let url = `${RETAIL_URL}/inventory/ota/${otaId}`;
    authorizedFetch(url, {
      method: "DELETE",
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success === true) {
          snackbar.success(`Success. Firmware file Replaced sucessfully`);
          refectData();
          handleClose();
          onDelete();
        } else {
          snackbar.error(res.msg);
          handleClose();
          onDelete();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(`Error deleting OTA File`);
        handleClose();
        onDelete();
      });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete OTA?</DialogTitle>
      <DialogContent>
        <Typography sx={{ my: 1, mr: 5 }}>
          Are you sure you want to delete OTA file?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
