import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { appBar, authorizedFetch } from "utils";
import { AUTH_URL } from "utils/constants";

import AppBar from "./AppBar";
import List from "./List";
import AddApplicationDialog from "./AddApplicationDialog";
import UpdateApplicationDialog from "./UpdateApplicationDialog";

const filterLabels: any = {
  sortParams: {
    "Date Created (Newest First)": "-1|createdAt",
    "Date Created (Oldest First)": "1|createdAt",
  },
  status: {
    All: "",
    Active: "ACTIVE",
    Inactive: "INACTIVE",
    Deleted: "DELETED",
  },
};

const ChargerTickets = () => {
  const [createDialog, setCreateDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState({
    open: false,
    data: {},
  });

  const [filters, setFilters] = useState<any>({
    sortParams: filterLabels.sortParams["Date Created (Newest First)"],
    status: filterLabels.status["All"],
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const { sortParams, status } = filters;

  const sort = sortParams.split("|")[0];
  const sortBy = sortParams.split("|")[1];

  const applicationsURL = `${AUTH_URL}/application/all?first=${first}&skip=${skip}&status=${status}&sort=${sort}&sortBy=${sortBy}&search=${search}`;

  const { isLoading, data, refetch } = useQuery(
    [
      "GET APPLICATIONS /application/all",
      first,
      skip,
      status,
      search,
      sort,
      sortBy,
    ],
    () => authorizedFetch(applicationsURL)
  );

  useEffect(() => {
    setPage(1);
  }, [filters, search, pageSize]);

  useEffect(() => {
    appBar.open(
      <AppBar {...{ setCreateDialog, filters, setFilters, filterLabels }} />
    );
    return () => {
      appBar.close();
    };
  }, [filters]);

  return (
    <>
      <List
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setSearch={setSearch}
        isLoading={isLoading}
        data={data}
        setUpdateDialog={setUpdateDialog}
      />
      <AddApplicationDialog
        open={createDialog}
        handleClose={() => setCreateDialog(false)}
        refetchApplications={refetch}
      />
      <UpdateApplicationDialog
        open={updateDialog.open}
        handleClose={() => setUpdateDialog({ ...updateDialog, open: false })}
        application={updateDialog.data}
        refetchApplications={refetch}
      />
    </>
  );
};

export default ChargerTickets;
