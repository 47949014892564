import {
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { AUTH_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchApplications: any;
}

type inputData = {
  applicationName: string;
  packageName: string;
  platform: string;
};

const AddPricingDialog: React.FC<Props> = ({
  open,
  handleClose,
  refetchApplications,
}) => {
  const [step, setStep] = useState(0);
  const steps = ["Application Info", "Finish"];

  const [input, setInput] = useState<inputData>({
    applicationName: "",
    packageName: "",
    platform: "",
  });

  const [searchPackage, setSearchPackage] = useState(false);

  console.log(searchPackage);

  const [kind, setKind] = useState<string[]>([]);

  const { applicationName, packageName, platform } = input;

  const addApplicationURL = `${AUTH_URL}/application/create`;

  const mutation = useMutation(
    `addApplication`,
    () =>
      authorizedFetch(addApplicationURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          name: applicationName,
          package: packageName,
          platform: platform,
          kind: kind,
        },
      }),
    {
      onSuccess: (data) => {
        if (data?.msg === "App created") {
          snackbar.success(`Application Added`);
          refetchApplications();
          setLoader(false);
        } else {
          snackbar.error(`Error creating application`);

          setLoader(false);
        }
      },
      onError: () => {
        snackbar.error(`Error creating application`);
      },
    }
  );

  function handleChange(key: string, value: string) {
    setInput((prevInput: inputData) => ({ ...prevInput, [key]: value }));
  }

  const handleSave = () => {
    setLoader(true);
    mutation.mutate();
    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          ![applicationName, packageName, platform].includes("") &&
          kind.length !== 0 &&
          !searchPackage
        );

      default:
        break;
    }
  }

  const disabled =
    [applicationName, packageName, platform].includes("") ||
    kind.length === 0 ||
    searchPackage;

  useEffect(() => {
    if (!open) {
      setInput({
        applicationName: "",
        packageName: "",
        platform: "",
      });
      setKind([]);
      setStep(0);
      setSearchPackage(false);
    }
  }, [open]);

  const checkPackageUrl = `${AUTH_URL}/application/all?first=10&skip=0&search=${packageName}`;
  const { data: allApplicationData } = useQuery(
    ["getApplicationPackage", packageName],
    () => authorizedFetch(checkPackageUrl),
    {
      enabled: packageName.length > 0,
    }
  );

  function handlePricingSearch(search: any) {
    const check = allApplicationData?.data?.apps?.find(
      (item: any) => item.package === search
    );
    if (check === undefined) {
      setSearchPackage(false);
    } else {
      setSearchPackage(true);
    }
  }

  const handleChangeKind = (event: SelectChangeEvent<typeof kind>) => {
    const {
      target: { value },
    } = event;
    setKind(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Add Application
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <>
            <Box sx={{ maxWidth: { xs: 280, sm: 650 }, mx: "auto" }}>
              <Typography sx={{ fontSize: "0.875rem" }}>
                All fields with ( * ) are required
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 650 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "1fr 1fr",
                },
                gap: 3,
                "& .required": {
                  color: "red",
                },
              }}
            >
              <Box>
                <Box display="flex" alignItems="center">
                  <Typography className="label">
                    Application Name <span className="required">&#x2a;</span>
                  </Typography>
                  <Tooltip title="Application Name can have any character upto 50 in total">
                    <InfoOutlined
                      fontSize="inherit"
                      sx={{
                        ml: 0.5,
                        mt: -1.7,
                        cursor: "pointer",
                        color: "#00000080",
                      }}
                    />
                  </Tooltip>
                </Box>

                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={applicationName}
                  placeholder="Pricing Name"
                  onChange={(e) => {
                    handleChange("applicationName", e.target.value);
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>
              <Box>
                <Box display="flex" alignItems="center">
                  <Typography className="label">
                    Package Name <span className="required">&#x2a;</span>
                  </Typography>
                  <Tooltip title="Package Name can have only alphanumeric & dot character upto 50 in total">
                    <InfoOutlined
                      fontSize="inherit"
                      sx={{
                        ml: 0.5,
                        mt: -1.7,
                        cursor: "pointer",
                        color: "#00000080",
                      }}
                    />
                  </Tooltip>
                </Box>

                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={packageName}
                  placeholder="Package Name"
                  onChange={(e) => {
                    handleChange(
                      "packageName",
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9-.]/, "")
                        : e.target.value
                    );
                    handlePricingSearch(e.target.value);
                  }}
                  inputProps={{ maxLength: 50 }}
                  error={searchPackage ? true : false}
                  helperText={
                    searchPackage ? "Package Name already exists" : ""
                  }
                />
              </Box>
              <Box>
                <Typography className="label">
                  Platform <span className="required">&#x2a;</span>
                </Typography>
                <Select
                  sx={{ height: 46 }}
                  fullWidth
                  value={platform}
                  onChange={(e: any) => {
                    handleChange("platform", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {["ANDROID", "IOS", "WEB"].map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Typography className="label">
                  Kind <span className="required">&#x2a;</span>
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{ mt: -0.5 }}
                    id="demo-multiple-checkbox-label"
                  >
                    Kind
                  </InputLabel>
                  <Select
                    sx={{ height: 46 }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={kind}
                    onChange={handleChangeKind}
                    placeholder={"Select Kind..."}
                    input={<OutlinedInput label="Kind" />}
                    renderValue={(selected) => {
                      let selectedValues = selected.map((id: any) =>
                        ["COMPANION", "FLEET", "CHARGING"].find(
                          (el: any) => el === id
                        )
                      );

                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {selectedValues.map((value: any, i: number) => (
                            <Chip
                              key={i}
                              label={value}
                              sx={{
                                fontSize: 10,
                              }}
                            />
                          ))}
                        </Box>
                      );
                    }}
                  >
                    {["COMPANION", "FLEET", "CHARGING"].map((kindType) => (
                      <MenuItem key={kindType} value={kindType}>
                        <Checkbox checked={kind.indexOf(kindType) > -1} />
                        <ListItemText primary={kindType} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </>
        )}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  {
                    header: "Pricing Info",
                    onEdit: () => setStep(0),
                  },
                  {
                    label: "Application Name",
                    value: applicationName,
                    required: true,
                  },
                  {
                    label: "Package Name",
                    value: packageName,
                    required: true,
                  },
                  {
                    label: "Platform",
                    value: platform,
                    required: true,
                  },
                  {
                    label: "Kind",
                    value: kind.join(" , "),
                    required: true,
                  },
                ].map(({ header, onEdit, label, value, required }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">
                            {value ||
                              (required && (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    style={{
                                      marginRight: 8,
                                    }}
                                  />
                                  Required
                                </Box>
                              ))}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={
            // step === steps.length - 1
            // &&
            disabled
          }
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPricingDialog;
