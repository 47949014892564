import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/system";
import RangePicker from "components/RangePicker";
import { format, sub } from "date-fns";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { BOLT_URL } from "utils/constants";
import { snackbar } from "../../../../utils";

const Stats = ({ vendorsData }: any) => {
  const theme = useTheme();

  const filters = ["Energy", "Bookings", "Earnings", "Users"];

  const vendorList = [
    { id: "all", label: "All Vendors" },
    ...(vendorsData?.data?.constructor === Array ? vendorsData.data : []).map(
      (el: any) => ({ id: el._id, label: el.name })
    ),
  ];

  const [vendorsChartData, setVendorsChartData] = useState<any>(null);
  const [selectedVendor, setSelectedVendor] = useState(vendorList[0]);
  const [statsFilter, setStatsFilter] = useState(filters[0]);
  const [range, setRange] = useState<any>([
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);

  let dateFrom = format(range[0], "yyyy-MM-dd");
  let dateTo = format(range[1], "yyy-MM-dd");

  const vendorUrl = `${BOLT_URL}/company/stats/vendor/date?orderBy=BOOKING_TIME_ASC&dateFrom=${dateFrom}&dateTo=${dateTo}&vendorId=${
    selectedVendor?.id === "all" ? "" : selectedVendor?.id
  }`;

  const { isLoading, data: vendorStatsData } = useQuery(
    ["getVendorStatsByDate", dateFrom, dateTo, selectedVendor],
    () => authorizedFetch(vendorUrl),
    {
      onError: () => snackbar.error("Error fetching data"),
    }
  );

  useEffect(() => {
    if (vendorStatsData?.data?.stats?.constructor === Array) {
      let chartData: any = {
        bookings: [],
        earnings: [],
        energy: [],
        users: [],
      };
      // eslint-disable-next-line
      vendorStatsData?.data?.stats?.map((el: any) => {
        let date = format(new Date(el.date), "MMM d, yyyy");
        chartData.bookings.push({ x: date, y: el.totalBookings });
        chartData.earnings.push({ x: date, y: el.totalEarnings });
        chartData.energy.push({ x: date, y: el.totalEnergyConsumed });
        chartData.users.push({ x: date, y: el.totalUsers });
      });

      setVendorsChartData(chartData);
    }
  }, [vendorStatsData]);

  return (
    <Paper
      sx={{
        height: 438,
        p: { xs: 2, md: 3 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        mb={4}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "start", sm: "center" }}
      >
        <Typography variant="h3" mr={3}>
          Stats
        </Typography>
        <Box
          width={{ xs: 1, sm: "auto" }}
          display="grid"
          alignItems="center"
          justifyItems={{ xs: "center", sm: "end" }}
          gridTemplateColumns={{ xs: "auto", sm: "repeat(3, auto)" }}
          gap={3}
        >
          <Autocomplete
            size="small"
            sx={{
              width: 180,
              "& .MuiOutlinedInput-root": {
                borderRadius: 10,
                height: 48,
                fontSize: 16,
              },
              "& .MuiAutocomplete-popupIndicatorOpen": {
                color: (theme) => theme.palette.primary.main,
              },
            }}
            disableClearable
            popupIcon={<KeyboardArrowDown />}
            value={selectedVendor}
            onChange={(e: any, newValue: any) => {
              console.log(newValue);
              setSelectedVendor(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={vendorList}
            renderInput={(params: any) => (
              <TextField
                {...params}
                placeholder="Vendor"
                sx={{
                  "& input": { pr: "16px !important" },
                }}
              />
            )}
          />
          <Select
            className="primary rounded"
            value={statsFilter}
            onChange={(e: any) => setStatsFilter(e.target.value)}
            sx={{
              width: "110px !important",
              height: 48,
              "& .MuiSelect-select": {
                fontSize: 16,
              },
            }}
          >
            {filters.map((filter, i) => (
              <MenuItem key={i} value={filter}>
                {filter}
              </MenuItem>
            ))}
          </Select>
          <Box
            gridColumn={{ xs: "span 2", sm: "span 1" }}
            gridRow={{ xs: 1, sm: "unset" }}
          >
            <RangePicker range={range} setRange={setRange} />
          </Box>
        </Box>
      </Box>
      <Box
        flexGrow={1}
        minHeight={0}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Line
            data={(canvas) => {
              let color = "#FFCC27";
              let labels =
                statsFilter === "Bookings"
                  ? vendorsChartData?.bookings?.map(
                      (el: any) => el.x.split(",")[0]
                    ) || []
                  : statsFilter === "Earnings"
                    ? vendorsChartData?.earnings?.map(
                        (el: any) => el.x.split(",")[0]
                      ) || []
                    : statsFilter === "Energy"
                      ? vendorsChartData?.energy?.map(
                          (el: any) => el.x.split(",")[0]
                        ) || []
                      : statsFilter === "Users"
                        ? vendorsChartData?.users?.map(
                            (el: any) => el.x.split(",")[0]
                          ) || []
                        : [];

              return {
                labels,
                datasets: [
                  {
                    fill: true,
                    data:
                      statsFilter === "Bookings"
                        ? vendorsChartData?.bookings?.map((el: any) => el.y) ||
                          []
                        : statsFilter === "Earnings"
                          ? vendorsChartData?.earnings?.map(
                              (el: any) => el.y
                            ) || []
                          : statsFilter === "Energy"
                            ? vendorsChartData?.energy?.map(
                                (el: any) => el.y
                              ) || []
                            : statsFilter === "Users"
                              ? vendorsChartData?.users?.map(
                                  (el: any) => el.y
                                ) || []
                              : [],
                    borderColor: color,
                    borderWidth: 2,
                    backgroundColor: alpha(color, 0.05),
                    tension: 0.4,
                    pointRadius: 0,
                    pointHoverRadius: 4,
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderWidth: 3,
                  },
                ],
              };
            }}
            options={{
              scales: {
                xAxis: {
                  // type: 'time',
                  grid: {
                    display: false,
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                  title: {
                    display: true,
                    text: "Day",
                    padding: {
                      top: 12,
                      bottom: 0,
                    },
                    color: theme.customColors.grey,
                    font: {
                      size: 12,
                    },
                  },
                  ticks: {
                    color: theme.palette.text.secondary,
                  },
                },
                yAxis: {
                  title: {
                    display: true,
                    text:
                      statsFilter === "Bookings"
                        ? "Bookings"
                        : statsFilter === "Earnings"
                          ? "Earnings (₹)"
                          : statsFilter === "Energy"
                            ? "Energy (kWh)"
                            : "",
                    padding: {
                      top: 0,
                      bottom: 12,
                    },
                    color: theme.customColors.grey,
                    font: {
                      size: 12,
                    },
                  },
                  ticks: {
                    color: theme.palette.text.secondary,
                  },
                  min: 0,
                  // suggestedMin: 0,
                  grid: {
                    borderDash: [5],
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  caretSize: 0,
                  mode: "index",
                  intersect: false,
                  yAlign: "center",
                  displayColors: false,
                  caretPadding: 16,
                  titleFont: {
                    weight: "400",
                  },
                  bodyFont: {
                    weight: "500",
                  },
                },
              },
              interaction: {
                mode: "index",
                intersect: false,
              },
            }}
          />
        )}
      </Box>
    </Paper>
  );
};

export default Stats;
