import { FileDownload, InfoOutlined, SaveAlt } from "@mui/icons-material";
import { Avatar, Box, IconButton, Paper, Tooltip } from "@mui/material";
import Search from "components/Search";
import TableComponent from "components/Table";
import { saveAs } from "file-saver";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  GlobalState,
  setLoader,
  snackbar,
  titleCase,
} from "utils";
import { REPORTS_URL } from "utils/constants";
import DrawerContent from "./DrawerContent";

const List = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  setSearch,
  isLoading,
  data,
}: any) => {
  const { company } = useSelector((state: GlobalState) => state.global);

  function downloadLeaseDoc(lease: any, docType: "report" | "invoice") {
    setLoader(true);
    authorizedFetch(
      `${REPORTS_URL}/v2/lease/${docType}?format=pdf&id=${lease.id}&vin=${lease.vin}`
    )
      .then((res) => {
        setLoader(false);
        if (res.file) {
          const fileURL: any = res.file;
          saveAs(
            fileURL,
            `${lease.id}_${moment(lease.bookingTime).format("DD-MM-YYYY")}.pdf`
          );
          snackbar.success(`Lease ${docType} downloaded`);
        } else {
          snackbar.error(`Error downloading lease ${docType}`);
          console.error(res);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(`Error downloading lease ${docType}`);
      });
  }

  return (
    <Paper
      sx={{
        borderRadius: "14px",
        boxShadow: (theme) => theme.customShadows.card,
      }}
    >
      <Box
        sx={{
          p: 2.5,
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Search handleSearch={setSearch} persist enableClear />
      </Box>
      <TableComponent
        idKey="id"
        rowCount={data?.data?.count}
        serverSidePagination={true}
        activePage={page}
        activePageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        loading={isLoading}
        rows={data?.data?.bookings || []}
        columns={[
          ...(company.id === "5f44a1e3e03dd800075dd466"
            ? [
                {
                  key: "download",
                  label: "",
                  Render: (row: any) => (
                    <Box
                      sx={{
                        width: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Download Report" followCursor>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => downloadLeaseDoc(row, "report")}
                        >
                          <SaveAlt fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                },
              ]
            : []),
          {
            key: "vin",
            label: "vin",
          },
          {
            key: "startTime",
            label: "Start Time",
            format: (value) => moment(value).format("DD/MM/YY, hh:mm a"),
          },
          {
            key: "endTime",
            label: "End Time",
            format: (value) => moment(value).format("DD/MM/YY, hh:mm a"),
          },
          // {
          //   key: "duration",
          //   label: "Duration",
          //   valueGetter: (row) => moment.duration(moment(row.endTime).diff(moment(row.startTime))).asMinutes(),
          //   format: (value) => getDuration(value),
          // },
          {
            key: "type",
            label: "Type",
          },
          {
            key: "status",
            label: "Status",
            Render: ({ status }) => {
              return (
                <Avatar
                  variant="status"
                  className={
                    ["ACTIVE", "BOOKED"].includes(status)
                      ? "green"
                      : status === "PENDING_PAYMENT"
                      ? "yellow"
                      : status === "PAYMENT_INITIALISED"
                      ? "blue"
                      : ["TERMINATED", "TERMINATED_FAILED_PAYMENT"].includes(
                          status
                        )
                      ? "red"
                      : "grey"
                  }
                >
                  {status ? status.split("_").map(titleCase).join(" ") : "N/A"}
                </Avatar>
              );
            },
          },
          {
            key: "bookingAmount",
            label: "Amount",
            format: (value) => "₹" + (value?.toLocaleString() || 0),
          },
          {
            key: "actions",
            label: "Actions",
            Render: (row) => (
              <Box display="flex" alignItems="center" gap={1}>
                <IconButton
                  size="small"
                  sx={{
                    ml: 0.5,
                    color: (theme) => theme.customColors.action,
                  }}
                  children={<InfoOutlined fontSize="small" />}
                  onClick={() =>
                    drawer.open(
                      <DrawerContent
                        key={row.id}
                        lease={row}
                        downloadLeaseDoc={downloadLeaseDoc}
                      />
                    )
                  }
                />

                {company?.id === "5f44a1e3e03dd800075dd466" && (
                  <Tooltip title="Download Report">
                    <IconButton
                      size="small"
                      sx={{ color: (theme: any) => theme.customColors.grey }}
                      onClick={() => downloadLeaseDoc(row, "report")}
                      children={<FileDownload fontSize="small" />}
                    />
                  </Tooltip>
                )}

                {/* {["BOOKED"].includes(row?.status) ? (
                  <Tooltip title="Start Time must be more than current time">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setImageDialog({
                          open: true,
                          data: row,
                          type: "start",
                        });
                      }}
                      disabled={
                        new Date(row.startTime).getTime() >
                          new Date().getTime() ||
                        new Date(row.endTime).getTime() < new Date().getTime()
                      }
                    >
                      Start
                    </Button>
                  </Tooltip>
                ) : (
                  ""
                )}
                {["BOOKED", "ACTIVE"].includes(row?.status) ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setImageDialog({ open: true, data: row, type: "end" });
                    }}
                    disabled={
                      new Date(row.startTime).getTime() >
                        new Date().getTime() ||
                      new Date(row.endTime).getTime() < new Date().getTime()
                    }
                  >
                    End
                  </Button>
                ) : (
                  ""
                )}

                {["PENDING_PAYMENT", "PAYMENT_INITIALISED"].includes(
                  row?.status
                ) ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setPayDialog({ open: true, data: row });
                    }}
                  >
                    Pay
                  </Button>
                ) : (
                  ""
                )}
                {["BOOKED"].includes(row?.status) ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setCancelDialog({ open: true, data: row });
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  ""
                )}
                {["PENDING_PAYMENT", "PAYMENT_INITIALISED"].includes(
                  row?.status
                ) ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setTerminateDialog({ open: true, data: row });
                    }}
                  >
                    TERMINATE
                  </Button>
                ) : (
                  ""
                )} */}
                {/* <IconButton
                  size="small"
                  sx={{
                    ml: 0.5,
                    color: (theme) => theme.customColors.action,
                  }}
                  children={<InfoOutlined fontSize="small" />}
                  onClick={() =>
                    drawer.open(<DrawerContent key={row.id} vehicle={row} />)
                  }
                /> */}
              </Box>
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default List;
