import { HighlightOff } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import RangePicker from "components/RangePicker";
import { sub } from "date-fns";
import moment from "moment";
import { saveAs } from "file-saver";

import { useState } from "react";

import { authorizedFetch, setLoader, snackbar } from "utils";
import { REPORTS_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const DownloadReportDialog: React.FC<Props> = ({ open, handleClose }) => {
  // const disabled = true;

  const [range, setRange] = useState<any>([
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);

  let startTime = moment(range[0]).toISOString();
  let endTime = moment(range[1]).toISOString();

  // const REPORTS_END = "http://localhost:8000";

  function downloadReport() {
    setLoader(true);
    const reportURL = `${REPORTS_URL}/diagnostics/charger/summary?startTime=${startTime}&endTime=${endTime}`;

    authorizedFetch(reportURL).then((data) => {
      setLoader(false);
      if (data.file) {
        const formatted = (time: any) => moment(time).format("DD-MM-YYYY");
        const fileUrl = data.file;
        const fileName = `DiagnosticReport_${formatted(startTime)}–${formatted(
          endTime
        )}.pdf`;
        saveAs(fileUrl, fileName);
        snackbar.success("Report downloaded");
      }
    });
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            margin: 2,
          },
        }}
        PaperProps={{
          sx: {
            maxWidth: 360,
            width: 1,
            p: 0,
            "& .MuiInputBase-root": {
              fontSize: 14,
              borderRadius: 1,
              p: "3.5px 5px",
              m: 0,
            },
            "& .MuiPaper-root": {
              margin: 0,
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          Download Report
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </DialogTitle>
        <DialogContent sx={{ pb: "16px !important" }}>
          <Typography sx={{ mb: 2, mt: 1 }}>Pick a Range</Typography>
          <RangePicker range={range} setRange={setRange} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={downloadReport}
            variant={"contained"}
            disableElevation
            // disabled={disabled}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DownloadReportDialog;
