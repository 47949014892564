import { EditOutlined, InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Search from "components/Search";
import TableComponent from "components/Table";

import moment from "moment";

import { drawer, titleCase } from "utils";

import DrawerContent from "./DrawerContent";

const List = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  setSearch,
  isLoading,
  data,
  setUpdateDialog,
}: any) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Paper
        sx={{
          borderRadius: "14px",
          boxShadow: (theme) => theme.customShadows.card,
        }}
      >
        <Box
          sx={{
            px: isMdDown ? 1 : 2.5,
            py: 2.5,
            display: isMdDown ? "" : "flex",
            flexDirection: isMdDown ? "" : "row-reverse",
            width: "100%",
          }}
        >
          <Search
            handleSearch={setSearch}
            persist
            enableClear
            placeholder="Search a Name or Package..."
          />
        </Box>
        <TableComponent
          px={isMdDown ? 1 : 2.5}
          idKey="_id"
          rowCount={data?.data?.count}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={isLoading}
          rows={data?.data?.apps || []}
          columns={[
            {
              key: "name",
              label: "Name",
            },
            {
              key: "package",
              label: "Package",
            },
            {
              key: "platform",
              label: "Platform",
            },
            {
              key: "Status",
              label: "Status",
              Render: ({ status }) => {
                return (
                  <Avatar
                    variant="status"
                    className={
                      status === "ACTIVE"
                        ? "green"
                        : status === "DELETED"
                        ? "red"
                        : "grey"
                    }
                  >
                    {status
                      ? status.split("_").map(titleCase).join(" ")
                      : "N/A"}
                  </Avatar>
                );
              },
            },
            {
              key: "createdAt",
              label: "Created At  ",
              format: (value) => moment(value).format("Do MMM, hh:mm a"),
            },

            {
              key: "actions",
              label: "Actions",
              Render: (row: any) => (
                <Box display="flex" alignItems="center">
                  <IconButton
                    size="small"
                    sx={{
                      // ml: 0.5,
                      color: (theme) => theme.customColors.action,
                    }}
                    children={<InfoOutlined fontSize="small" />}
                    onClick={() =>
                      drawer.open(
                        <DrawerContent
                          key={row.id}
                          application={row}
                          onClose={drawer.close}
                          setUpdateDialog={setUpdateDialog}
                        />
                      )
                    }
                  />
                  <IconButton
                    size="small"
                    sx={{
                      // mr: 0.5,
                      color: (theme) => theme.customColors.action,
                    }}
                    children={<EditOutlined fontSize="small" />}
                    onClick={() => {
                      setUpdateDialog({
                        open: true,
                        data: row,
                      });
                    }}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Paper>
    </>
  );
};

export default List;
