import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  GlobalState,
} from "utils";

import { HighlightOff } from "@mui/icons-material";
import CircularLoader from "components/CircularLoader";
import Info from "./Info";
import Stats from "./Stats";
import { useQuery } from "react-query";
import { RETAIL_URL } from "utils/constants";

const VehicleDrawer = ({ id, openToTab = 0, onClose, row }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const [tab, setTab] = useState(0);
  const { activeSection } = useSelector((state: GlobalState) => state.global);
  let isRetail = activeSection === "retail";

  const url = `${RETAIL_URL}/assembly/vehicle/${id}`;
  const { isLoading, data } = useQuery(["getVehicle", id], () =>
    authorizedFetch(url)
  );

  const hubUrl = `https://retail-hubs.dev.revos.in/assembly/vehicle/${id}`;
  const { data: hubData } = useQuery(["getVehicleHub", id], () =>
    authorizedFetch(hubUrl)
  );

  const vehicle = data?.data?.[0];
  useEffect(() => {
    setTab(openToTab);
  }, [openToTab]);
  console.log(row);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 1,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          px: 3,
          py: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
          fontWeight: 500,
          color: "#fff",
        }}
      >
        {isLoading ? "Loading..." : vehicle?.vin || "N/A"}
        <Box display="grid" gridTemplateColumns="repeat(2, auto)" gap={1}>
          {/* <IconButton
          children={<DeleteOutline />}
          color="inherit"
          size="small"
          onClick={() => setDeleteDialog(true)}
        /> */}
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            size="small"
            onClick={() => (onClose ? onClose() : drawer.close())}
          />
        </Box>
      </Box>
      <Box flexGrow={1} overflow="auto">
        {isLoading ? (
          <Box mt={2}>
            <CircularLoader />
          </Box>
        ) : vehicle ? (
          <>
            {!isRetail && (
              <Box p={2}>
                <Tabs
                  className="less-dense"
                  value={tab}
                  onChange={(e, tab) => setTab(tab)}
                >
                  <Tab label="Info" />
                  <Tab label="Stats" />
                </Tabs>
              </Box>
            )}
            <Box>
              {tab === 0 ? (
                <Info
                  vehicle={vehicle}
                  hubData={hubData?.data[0]}
                  isRetail={isRetail}
                  row={row}
                />
              ) : (
                <Stats vehicle={vehicle} />
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              pt: 4,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              No data available
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VehicleDrawer;
