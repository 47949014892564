import { Box } from "@mui/material";
// import { gql, useQuery as gqlUseQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import AppBar from "./AppBar";
import { appBar, authorizedFetch, drawer } from "utils";
import List from "./List";
import { BOLT_URL } from "utils/constants";
import { useQuery } from "react-query";
import DownloadReportDialog from "./DownloadReportDialog";

import moment from "moment";
import Cards from "./Cards";

const filterLabels: any = {
  sort: {
    "Date Created (Newest First)": "desc",
    "Date Created (Oldest First)": "asc",
  },
  tests: {
    All: "",
    Latest: "Latest",
  },
};

const Tracking = () => {
  const [filters, setFilters] = useState<any>({
    sort: filterLabels.sort["Date Created (Newest First)"],
    tests: filterLabels.tests["Latest"],

    time: [moment().subtract({ days: 7 }), moment()],
  });
  const [reportDialog, setReportDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const [tab, setTab] = useState(0);
  const dateFrom = filters.time[0].toISOString();
  const dateTo = filters.time[1].toISOString();

  const diagnosticsURLV2 = `${BOLT_URL}/v1/diagnostics/all?page=${page}&size=${pageSize}&search=${search}&sortBy=${
    filters.sort
  }&startTime=${dateFrom}&endTime=${dateTo}&latest=${
    filters.tests === "Latest" ? true : false
  }`;

  const { data: diagnosticsDataV2, isLoading: diagnosticsDataLoadingV2 } =
    useQuery(
      [
        "GET Diagnostic Data V2",
        page,
        pageSize,
        search,
        filters,
        dateTo,
        dateFrom,
      ],
      () => authorizedFetch(diagnosticsURLV2),
      {
        enabled: tab === 1,
      },
    );

  const diagnosticsURLV3 = `${BOLT_URL}/v2/misc/charger/diagnostics?page=${page}&size=${pageSize}&search=${search}&sortBy=${
    filters.sort
  }&startTime=${dateFrom}&endTime=${dateTo}&latest=${
    filters.tests === "Latest" ? true : false
  }`;

  const { data: diagnosticsDataV3, isLoading: diagnosticsDataLoadingV3 } =
    useQuery(
      [
        "GET Diagnostic Data V3",
        page,
        pageSize,
        search,
        filters,
        dateTo,
        dateFrom,
      ],
      () => authorizedFetch(diagnosticsURLV3),
      {
        enabled: tab === 0,
      },
    );

  console.log(diagnosticsDataV3);

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          filters,
          setFilters,
          filterLabels,
          setReportDialog,
          tab,
          setTab,
        }}
      />,
    );
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [filters, tab]);
  return (
    <Box>
      <Cards
        {...{
          dateFrom,
          dateTo,
          filters,
          tab,
        }}
      />
      <List
        {...{
          tab,
          page,
          setPage,
          pageSize,
          setPageSize,
          setSearch,
          diagnosticsDataV2,
          diagnosticsDataV3,
          diagnosticsDataLoadingV2,
          diagnosticsDataLoadingV3,
        }}
      />
      <DownloadReportDialog
        open={reportDialog}
        handleClose={() => {
          setReportDialog(false);
        }}
      />
    </Box>
  );
};

export default Tracking;
