const RedAsterisk = () => {
  return (
    <span
      style={{
        fontSize: "16px",
        fontWeight: 600,
        color: "#26C72D",
      }}
    >
      *
    </span>
  );
};

export default RedAsterisk;
