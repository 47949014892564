import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { filterLabels } from "..";

const AppBar = ({ usersLoading, count, filters, setFilters }: any) => {
  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h2">Users</Typography>
        {!usersLoading && (
          <Typography
            sx={{
              ml: 1,
              fontSize: { xs: 16, md: 20 },
              fontWeight: 600,
              color: (theme) => theme.customColors.text.grey,
            }}
          >
            ({(count || 0).toLocaleString()})
          </Typography>
        )}
      </Box>
      <FormControl size="small">
        <Select
          className="shadow rounded"
          sx={{ width: 155 }}
          value={filters.status}
          onChange={(e) =>
            setFilters((prev: any) => ({
              ...prev,
              status: e.target.value,
            }))
          }
          displayEmpty
          renderValue={(value) =>
            "Status: " +
            Object.keys(filterLabels.status).find(
              (key) => filterLabels.status[key] === filters.status
            )
          }
        >
          {Object.keys(filterLabels.status).map((el, i) => (
            <MenuItem key={i} value={filterLabels.status[el]}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default AppBar;
