import { Box, Typography } from "@mui/material";

const AppBar = ({ tripsLoading, count }: any) => {
  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h2">Trips</Typography>
        {!tripsLoading && (
          <Typography
            sx={{
              ml: 1,
              fontSize: { xs: 16, md: 20 },
              fontWeight: 600,
              color: (theme) => theme.customColors.text.grey,
            }}
          >
            ({(count || 0).toLocaleString()})
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AppBar;
