import { FilterAltOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  FormControl,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
// import RangeSelector from "components/RangeSelector";
import { useState } from "react";
// import { getPermissions } from "utils";

import FiltersDrawer from "./FiltersDrawer";

const AppBar = ({ filters, setFilters, filterLabels }: any) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const { canWrite } = getPermissions("rental:leases");

  // const [addDialogOpen, setAddDialogOpen] = useState(false);

  // function onAddLeaseClick() {
  //   setAddDialogOpen(true);
  // }

  return (
    <>
      <Box
        sx={{
          width: 1,
          py: 3,
          px: { xs: 2, md: 7 },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Track</Typography>
        <Hidden mdUp>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => setDrawerOpen(true)}>
              <Badge
                color="primary"
                variant="dot"
                invisible={
                  ![filters.status, filters.type].some((el) => el !== "")
                }
              >
                <FilterAltOutlined />
              </Badge>
            </IconButton>
          </Box>
          <FiltersDrawer
            {...{
              open: drawerOpen,
              onClose: () => setDrawerOpen(false),
              filters,
              setFilters,
              filterLabels,
            }}
          />
        </Hidden>
        <Hidden mdDown>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignItems: "center",
              "& > :not(:first-of-type)": {
                ml: 3,
              },
            }}
          >
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 200 }}
                value={filters.sortParams}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    sortParams: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) =>
                  "Sort By: " +
                  Object.keys(filterLabels.sortParams).find(
                    (key) => filterLabels.sortParams[key] === filters.sortParams
                  )
                }
              >
                {Object.keys(filterLabels.sortParams).map((el, i) => (
                  <MenuItem key={i} value={filterLabels.sortParams[el]}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 200 }}
                value={filters?.operation}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    operation: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) =>
                  "Operation: " +
                  Object.keys(filterLabels.operation).find(
                    (key) => filterLabels.operation[key] === filters.operation
                  )
                }
              >
                {Object.keys(filterLabels.operation).map((el, i) => (
                  <MenuItem key={i} value={filterLabels.operation[el]}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 200 }}
                value={filters.feature}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    feature: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) =>
                  "Feature: " +
                  Object.keys(filterLabels.feature).find(
                    (key) => filterLabels.feature[key] === filters.feature
                  )
                }
              >
                {Object.keys(filterLabels.feature).map((el, i) => (
                  <MenuItem key={i} value={filterLabels.feature[el]}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <RangeSelector
              setRange={(range: any) => {
                setFilters((prev: any) => ({
                  ...prev,
                  time: range,
                }));
              }}
            /> */}
          </Box>
        </Hidden>
      </Box>
    </>
  );
};

export default AppBar;
