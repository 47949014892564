import { InfoOutlined } from "@mui/icons-material";
import { Avatar, Box, IconButton, Paper } from "@mui/material";
import Search from "components/Search";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { appBar, authorizedFetch, drawer, titleCase } from "utils";
import { LEASE_URL } from "utils/constants";
import AppBar from "./AppBar";
import DrawerContent from "./DrawerContent";

export const filterLabels: any = {
  status: {
    All: "",
    Active: "ACTIVE",
    Inactive: "INACTIVE",
    Deleted: "DELETED",
    "Pending Approval": "PENDING_APPROVAL",
    "Incomplete Profile": "INCOMPLETE_PROFILE",
  },
};

const Users = () => {
  const [filters, setFilters] = useState<any>({
    status: "",
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const first = pageSize;
  const skip = pageSize * (page - 1);
  const { status } = filters;

  const usersUrl = `${LEASE_URL}/company/users/all?first=${first}&skip=${skip}&search=${search}&status=${status}`;

  const { isLoading, data } = useQuery(
    ["getUsers", first, skip, search, status],
    () => authorizedFetch(usersUrl)
  );

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  useEffect(() => {
    setPage(1);
  }, [pageSize, search, filters]);

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          filters,
          setFilters,
          usersLoading: isLoading,
          count: data?.data?.count || 0,
        }}
      />
    );
    return () => {
      appBar.close();
    };
  }, [filters, isLoading, data]);

  return (
    <Paper
      sx={{
        width: 1,
        boxShadow: (theme) => theme.customShadows.small,
        borderRadius: 2,
        pt: 3,
      }}
    >
      <Box
        sx={{
          width: 1,
          px: 3,
          pb: 2.75,
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
          flexDirection: "row-reverse",
        }}
      >
        <Search
          handleSearch={(value) => {
            setSearch(value);
          }}
          persist
          enableClear
        />
      </Box>

      <Table
        loading={isLoading}
        rowCount={data?.data?.count || 0}
        serverSidePagination
        activePage={page}
        activePageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        rows={data?.data?.users?.filter((el: any) => el !== null) || []}
        columns={[
          {
            key: "firstName",
            label: "Name",
            Render: (row) => {
              let name = row.firstName
                ? row.firstName.charAt(0).toUpperCase() +
                  row.firstName.slice(1) +
                  " " +
                  row.lastName.charAt(0).toUpperCase() +
                  row.lastName.slice(1)
                : "Not Found";
              return name;
            },
          },
          {
            key: "phone",
            label: "Phone Number",
            Render: (row) => {
              let phone = row.phone
                ? row.phone.charAt(0) === `+`
                  ? row.phone.slice(0, 3) + " - " + row.phone.slice(3)
                  : "+91 - " + row.phone
                : "Not Found";
              return phone;
            },
          },
          {
            key: "email",
            label: "Email",
            format: (value) => (value ? value.toLowerCase() : "Not Found"),
          },
          {
            key: "status",
            label: "Status",
            Render: ({ status }) => (
              <Avatar
                variant="status"
                className={
                  status === "ACTIVE"
                    ? "green"
                    : status === "INACTIVE"
                    ? "grey"
                    : status === "DELETED"
                    ? "red"
                    : status === "PENDING_APPROVAL"
                    ? "blue"
                    : status === "INCOMPLETE_PROFILE"
                    ? "yellow"
                    : ""
                }
              >
                {status ? status.split("_").map(titleCase).join(" ") : "N/A"}
              </Avatar>
            ),
          },
          {
            key: "actions",
            label: "Actions",
            Render: (row) => (
              <IconButton
                size="small"
                sx={{
                  color: (theme) => theme.customColors.action,
                }}
                children={<InfoOutlined fontSize="small" />}
                onClick={() =>
                  drawer.open(<DrawerContent key={row.id} user={row} />)
                }
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default Users;
