import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import TableComponent from "components/Table";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import CloseDialogIcon from "assets/images/icons/dialog-close.svg";
import { queryClient } from "index";
import { RETAIL_URL } from "utils/constants";

const UpdateAvailableDialog = ({ open, handleClose, data }: any) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const URL = `${RETAIL_URL}/assembly/model/${data?._id}/version-data`;
  const { data: updateData } = useQuery(
    ["getUpdates", data],
    () => authorizedFetch(URL),
    { enabled: open },
  );

  useEffect(() => {
    if (open) setSelectedRows([]);
  }, [open]);

  let rows =
    updateData?.data?.constructor === Array
      ? updateData.data
          .flatMap((item: any) => {
            const primaryData = item.primary
              .filter(
                (primaryItem: any) =>
                  primaryItem.currentVersion !== null &&
                  primaryItem.nextVersion !== null,
              )
              .map((primaryItem: any) => ({
                type: "PRIMARY",
                currentVersion: primaryItem.currentVersion || "",
                currentVersionId: primaryItem.currentVersionId || "",
                nextVersion: primaryItem.nextVersion || "",
                nextVersionId: primaryItem.nextVersionId || "",
                count: primaryItem.count || 0,
              }));

            const secondaryData = item.secondary
              .filter(
                (secondaryItem: any) =>
                  secondaryItem.currentVersion !== null &&
                  secondaryItem.nextVersion !== null,
              )
              .map((secondaryItem: any) => ({
                type: "SECONDARY",
                currentVersion: secondaryItem.currentVersion || "",
                currentVersionId: secondaryItem.currentVersionId || "",
                nextVersion: secondaryItem.nextVersion || "",
                nextVersionId: secondaryItem.nextVersionId || "",
                count: secondaryItem.count || 0,
              }));

            return [...primaryData, ...secondaryData];
          })
          .map((item: any, index: number) => ({
            ...item,
            serialNumber: index + 1,
            idKey: index,
          }))
      : [];

  const selectedCountSum = selectedRows.reduce(
    (sum, rowIndex) => sum + rows[rowIndex]?.count,
    0,
  );

  function handleUpdate() {
    setLoader(true);
    let url = `${RETAIL_URL}/inventory/ota/bulk-mark`;

    const selectedVersions = selectedRows.map((rowIndex: any) => ({
      currentVersionId: rows[rowIndex]?.currentVersionId || "",
      nextVersionId: rows[rowIndex]?.nextVersionId || "",
      type: rows[rowIndex]?.type || "",
    }));

    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        versions: selectedVersions,
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(
            `Success. ${selectedCountSum} vehicle updated sucessfully`,
          );
          queryClient.resetQueries("getUpdates");
          queryClient.resetQueries("getModels");
          handleClose();
        } else {
          console.error(res);
          snackbar.error(res.msg);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(err.msg);
      });
  }

  let rowCount = updateData?.meta?.totalCount || 0;
  console.log(rows, "rows");

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">Update Available</Typography>
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="text.secondary">{data?.name}</Typography>
        </div>
        <IconButton
          sx={{ position: "absolute", top: 10, right: 10 }}
          onClick={handleClose}
        >
          <img src={CloseDialogIcon} alt="close icon" style={{ width: 23 }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 1, mb: -3 }}>
        <TableComponent
          idKey="idKey"
          px={0}
          small
          selectable
          selectOnClick
          rowCount={rowCount}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          // loading={isLoading}
          rows={rows}
          columns={[
            { key: "serialNumber", label: "SL No." },
            {
              key: "type",
              label: "Type",
              // Render: (row: any) =>
              //     row.type === "primary" ? "PRIMARY" : "SECONDARY",
            },
            { key: "currentVersion", label: "Current Version" },
            { key: "nextVersion", label: "Update Available" },
            { key: "count", label: "No. of vehicles" },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{
            borderRadius: 10,
            color: "#000",
            borderColor: "#000",
            ":hover": { borderColor: "black" },
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          sx={{ borderRadius: 10 }}
          variant="contained"
          disabled={selectedRows.length === 0}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateAvailableDialog;
