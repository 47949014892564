// import { EditOutlined, InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  // Box,
  // IconButton,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import Search from "components/Search";
import TableComponent from "components/Table";

import moment from "moment";

import { titleCase } from "utils";

// import DrawerContent from "./DrawerContent";

const List = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  setSearch,
  userData,
  userDataLoading,
}: any) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Paper
      sx={{
        borderRadius: "14px",
        boxShadow: (theme) => theme.customShadows.card,
        pt: 3,
      }}
    >
      {/* <Box
        sx={{
          px: isMdDown ? 1 : 2.5,
          py: 2.5,
          display: isMdDown ? "" : "flex",
          flexDirection: isMdDown ? "" : "row-reverse",
          width: "100%",
        }}
      >
        <Search
          handleSearch={setSearch}
          persist
          enableClear
          placeholder="Search a UID..."
        />
      </Box> */}
      <TableComponent
        px={isMdDown ? 1 : 2.5}
        idKey="_id"
        rowCount={userData?.meta?.count}
        serverSidePagination={true}
        activePage={page}
        activePageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        loading={userDataLoading}
        rows={userData?.data || []}
        columns={[
          {
            key: "name",
            label: "Name",
          },
          {
            key: "email",
            label: "Email",
          },

          {
            key: "operation",
            label: "Operation",
            Render: ({ operation }) => {
              return (
                <Avatar
                  variant="status"
                  className={
                    ["APPROVED"].includes(operation)
                      ? "green"
                      : operation === "REJECTED"
                      ? "red"
                      : "grey"
                  }
                >
                  {operation
                    ? operation.split("_").map(titleCase).join(" ")
                    : "N/A"}
                </Avatar>
              );
            },
          },

          {
            key: "feature",
            label: "Feature",
          },

          {
            key: "assetName",
            label: "Asset Name",
          },
          {
            key: "createdAt",
            label: "time",
            format: (value) => moment(value).format("Do MMM, hh:mm a"),
          },
        ]}
      />
    </Paper>
  );
};

export default List;
