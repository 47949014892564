import { EditOutlined, ErrorOutline, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { authorizedFetch, setLoader, snackbar, drawer } from "utils";
import { AUTH_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchApplications: any;
  application: any;
}

type inputData = {
  applicationName: string;
  status: string;
};

const AddPricingDialog: React.FC<Props> = ({
  open,
  handleClose,
  refetchApplications,
  application,
}) => {
  const [step, setStep] = useState(0);
  const steps = ["Application Info", "Finish"];

  const [input, setInput] = useState<inputData>({
    applicationName: "",
    status: "",
  });

  useEffect(() => {
    if (application) {
      setInput({
        applicationName: application.name,
        status: application.status,
      });
    }
  }, [application, open]);

  const { applicationName, status } = input;

  const addApplicationURL = `${AUTH_URL}/application/update/${application?._id}`;

  const mutation = useMutation(
    `addApplication`,
    () =>
      authorizedFetch(addApplicationURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          name: applicationName,
          status: status,
        },
      }),
    {
      onSuccess: (data) => {
        if (data?.msg === "App updated") {
          snackbar.success(`Application Updated`);
          refetchApplications();
          drawer.close();
          setLoader(false);
        } else {
          snackbar.error(`Error updating application`);

          setLoader(false);
        }
      },
      onError: () => {
        snackbar.error(`Error updating application`);
      },
    }
  );

  function handleChange(key: string, value: string) {
    setInput((prevInput: inputData) => ({ ...prevInput, [key]: value }));
  }

  const handleSave = () => {
    setLoader(true);
    mutation.mutate();
    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          applicationName !== application?.name ||
          status !== application?.status
        );

      default:
        break;
    }
  }

  const disabled =
    applicationName === application?.name && status === application?.status;

  useEffect(() => {
    if (!open) {
      setInput({
        applicationName: "",
        status: "",
      });
      setStep(0);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Update Application
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <>
            <Box sx={{ maxWidth: { xs: 280, sm: 650 }, mx: "auto" }}>
              <Typography sx={{ fontSize: "0.875rem" }}>
                All fields with ( * ) are required
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 650 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
                "& .required": {
                  color: "red",
                },
              }}
            >
              <Box>
                <Typography className="label">
                  Application Name <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={applicationName}
                  placeholder="Pricing Name"
                  onChange={(e) => {
                    handleChange(
                      "applicationName",
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9-_ ]/, "")
                        : e.target.value
                    );
                  }}
                  inputProps={{ maxLength: 17 }}
                />
              </Box>

              <Box>
                <Typography className="label">
                  Status <span className="required">&#x2a;</span>
                </Typography>
                <Select
                  sx={{ height: 46 }}
                  fullWidth
                  value={status}
                  onChange={(e: any) => {
                    handleChange("status", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {["ACTIVE", "INACTIVE", "DELETE"].map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </>
        )}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Pricing Info", onEdit: () => setStep(0) },
                  {
                    label: "Application Name",
                    value: applicationName,
                    required: true,
                  },
                  { label: "Status", value: status, required: true },
                ].map(({ header, onEdit, label, value, required }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">
                            {value ||
                              (required && (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    style={{ marginRight: 8 }}
                                  />
                                  Required
                                </Box>
                              ))}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={
            // step === steps.length - 1
            // &&
            disabled
          }
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPricingDialog;
