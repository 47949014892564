import { AddRounded, FilterAltOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  FormControl,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import RangeSelector from "components/RangeSelector";
import { useState } from "react";
import { getPermissions } from "utils";
import AddDialog from "../Components/AddDialogJuspay";
import FiltersDrawer from "./FiltersDrawer";

const AppBar = ({ filters, setFilters, filterLabels }: any) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { canWrite } = getPermissions("rental:leases");

  const [addDialogOpen, setAddDialogOpen] = useState(false);

  function onAddLeaseClick() {
    setAddDialogOpen(true);
  }

  return (
    <>
      <AddDialog
        open={addDialogOpen}
        handleClose={() => setAddDialogOpen(false)}
      />
      <Box
        sx={{
          width: 1,
          py: 3,
          px: { xs: 2, md: 7 },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Leases</Typography>
        <Hidden mdUp>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                mr: 2,
                px: 0.5,
                minWidth: 0,
              }}
              variant="outlined"
              onClick={onAddLeaseClick}
            >
              <AddRounded />
            </Button>
            <IconButton onClick={() => setDrawerOpen(true)}>
              <Badge
                color="primary"
                variant="dot"
                invisible={
                  ![filters.status, filters.type].some((el) => el !== "")
                }
              >
                <FilterAltOutlined />
              </Badge>
            </IconButton>
          </Box>
          <FiltersDrawer
            {...{
              open: drawerOpen,
              onClose: () => setDrawerOpen(false),
              filters,
              setFilters,
              filterLabels,
            }}
          />
        </Hidden>
        <Hidden mdDown>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignItems: "center",
              "& > :not(:first-of-type)": {
                ml: 3,
              },
            }}
          >
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 155 }}
                value={filters.sortParams}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    sortParams: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) =>
                  "Sort By: " +
                  Object.keys(filterLabels.sortParams).find(
                    (key) =>
                      filterLabels.sortParams[key] === filters.sortParams,
                  )
                }
              >
                {Object.keys(filterLabels.sortParams).map((el, i) => (
                  <MenuItem key={i} value={filterLabels.sortParams[el]}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 140 }}
                value={filters.status}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    status: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) =>
                  "Status: " +
                  Object.keys(filterLabels.status).find(
                    (key) => filterLabels.status[key] === filters.status,
                  )
                }
              >
                {Object.keys(filterLabels.status).map((el, i) => (
                  <MenuItem key={i} value={filterLabels.status[el]}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 130 }}
                value={filters.type}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    type: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) =>
                  "Type: " +
                  Object.keys(filterLabels.type).find(
                    (key) => filterLabels.type[key] === filters.type,
                  )
                }
              >
                {Object.keys(filterLabels.type).map((el, i) => (
                  <MenuItem key={i} value={filterLabels.type[el]}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <RangeSelector
              setRange={(range: any) => {
                setFilters((prev: any) => ({
                  ...prev,
                  time: range,
                }));
              }}
            />
            {canWrite && (
              <Button
                sx={{
                  // width: { md: 300 },
                  ml: 3,
                  borderRadius: 10,
                  borderWidth: "1.5px !important",
                  borderColor: (theme) => theme.palette.primary.main,
                  textTransform: "none",
                  fontWeight: 500,
                }}
                variant="outlined"
                startIcon={<AddRounded />}
                onClick={onAddLeaseClick}
              >
                Add Lease
              </Button>
            )}
          </Box>
        </Hidden>
      </Box>
    </>
  );
};

export default AppBar;
