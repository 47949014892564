import { InfoOutlined } from "@mui/icons-material";
import { Box, IconButton, Paper, Tooltip } from "@mui/material";
import Search from "components/Search";
import TableComponent from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { appBar, authorizedFetch, drawer, getDuration } from "utils";
import { LEASE_URL } from "utils/constants";
import AppBar from "./AppBar";
import DrawerContent from "./DrawerContent";

const Trips = () => {
  // const [filters, setFilters] = useState<any>({
  //   time: [moment().subtract({ months: 1 }), moment()],
  // });

  // const dateFrom = filters.time[0].format("YYYY-MM-DD");
  // const dateTo = filters.time[1].format("YYYY-MM-DD");

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const first = pageSize;
  const skip = pageSize * (page - 1);
  const tripsURL = `${LEASE_URL}/company/trips?first=${first}&search=${search}&skip=${skip}`;

  const { isLoading: tripsLoading, data: tripsData } = useQuery(
    ["getTrips", first, skip, search],
    () => authorizedFetch(tripsURL)
  );
  const count = tripsData?.data?.count || 0;

  useEffect(() => {
    if (search) {
      setPage(1);
      setPageSize(10);
    }
  }, [search]);

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          tripsLoading,
          count,
          // filters,
          // setFilters,
        }}
      />
    );
    return () => {
      appBar.close();
    };
  }, [tripsLoading, count]);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 7 },
        pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
        "& > .MuiPaper-root": {
          borderRadius: "14px",
          boxShadow: (theme) => theme.customShadows.card,
        },
      }}
    >
      <Paper>
        <Box
          sx={{
            p: 2.5,
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Search handleSearch={setSearch} persist enableClear />
        </Box>
        <TableComponent
          rowCount={tripsData?.data?.count}
          serverSidePagination
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={tripsLoading}
          rows={tripsData?.data?.trips || []}
          columns={[
            {
              key: "vin",
              label: "VIN",
              format: (value) =>
                `${value.length > 17 ? value.slice(0, 17) : value}`,
            },
            {
              key: "startTime",
              label: "Start Time",
              format: (value) => moment(value).format("DD/MM/YY, hh:mm a"),
            },
            {
              key: "duration",
              label: "Duration",
              valueGetter: (row) => {
                let duration = moment.duration(
                  moment(row.endTime).diff(moment(row.startTime))
                );

                return duration.asMinutes();
              },
              format: (value) => getDuration(value),
            },
            {
              key: "maxGpsSpeed",
              label: "Max Speed",
              valueGetter: (row) => {
                return row.maxVehicleSpeed === 0
                  ? `${
                      typeof row.maxGpsSpeed === "number"
                        ? row.maxGpsSpeed.toFixed(0)
                        : 0
                    } km/h`
                  : `${
                      typeof row.maxVehicleSpeed === "number"
                        ? row.maxVehicleSpeed.toFixed(0)
                        : 0
                    } km/h`;
              },
            },
            {
              key: "avgGpsSpeed",
              label: "Avg Speed",
              valueGetter: (row) => {
                return row.avgVehicleSpeed === 0
                  ? `${
                      typeof row.avgGpsSpeed === "number"
                        ? row.avgGpsSpeed.toFixed(2)
                        : 0
                    } km/h`
                  : `${
                      typeof row.avgVehicleSpeed === "number"
                        ? row.avgVehicleSpeed.toFixed(2)
                        : 0
                    } km/h`;
              },
            },
            {
              key: "distance",
              label: "Distance",
              format: (value) =>
                `${
                  typeof value === "number" ? (value / 1000).toFixed(2) : 0
                } km`,
            },
            {
              key: "driverScore",
              label: "Driver Score",
              format: (value) => value || "N/A",
            },
            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <Tooltip title="Info" followCursor>
                  <IconButton
                    size="small"
                    sx={{
                      color: (theme) => theme.customColors.action,
                    }}
                    children={<InfoOutlined fontSize="small" />}
                    onClick={() =>
                      drawer.open(<DrawerContent key={row?._id} trip={row} />)
                    }
                  />
                </Tooltip>
              ),
            },
          ]}
        />
      </Paper>
    </Box>
  );
};

export default Trips;
