import { Box } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { appBar, authorizedFetch, drawer, GlobalState } from "utils";
// import { LEASE_URL } from "utils/constants";
import AppBar from "./AppBar";
import Cards from "./Cards";
import List from "./List";

const filterLabels: any = {
  sortParams: {
    // in the format of "<sort>|<sortBy>"
    "Date Created (Newest First)": "-1|createdAt",
    "Date Created (Oldest First)": "1|createdAt",
    "VIN (A to Z)": "1|vin",
    "VIN (Z to A)": "-1|vin",
    "Amount (Low to High)": "1|bookingAmount",
    "Amount (High to Low)": "-1|bookingAmount",
  },
  status: {
    All: "",
    Active: "ACTIVE",
    Booked: "BOOKED",
    Terminated: "TERMINATED",
    "Terminated Failed Payment": "TERMINATED_FAILED_PAYMENT",
    Ended: "ENDED",
  },
  type: {
    All: "",
    "Short Term": "SHORT_TERM",
    "Long Term": "LONG_TERM",
  },
};

const Vehicles = () => {
  const { masterView } = useSelector((state: GlobalState) => state.global);

  const [filters, setFilters] = useState<any>({
    time: [moment().subtract({ months: 1 }), moment()],
    sortParams: filterLabels.sortParams["Date Created (Newest First)"],
    status: filterLabels.status["All"],
    type: filterLabels.type["All"],
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const dateFrom = filters.time[0].format("YYYY-MM-DD");
  const dateTo = filters.time[1].format("YYYY-MM-DD");
  const first = pageSize;
  const skip = pageSize * (page - 1);

  const { sortParams, status, type } = filters;

  const sort = sortParams.split("|")[0];
  const sortBy = sortParams.split("|")[1];

  const leasesURL = `https://bookings-juspay.dev.revos.in/company/bookings?first=${first}&skip=${skip}&sort=${sort}&sortBy=${sortBy}&status=${status}&search=${search}&type=${type}`;

  const { isLoading, data } = useQuery(
    [
      "GET FMS /company/bookings",
      first,
      skip,
      sort,
      sortBy,
      status,
      type,
      search,
    ],
    () => authorizedFetch(leasesURL),
  );

  useEffect(() => {
    setPage(1);
  }, [filters, search, pageSize]);

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          filters,
          setFilters,
          filterLabels,
        }}
      />,
    );
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [filters]);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 7 },
        pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <Cards
        {...{
          dateFrom,
          dateTo,
          masterView,
        }}
      />
      <List
        {...{
          page,
          setPage,
          pageSize,
          setPageSize,
          setSearch,
          isLoading,
          data,
        }}
      />
    </Box>
  );
};

export default Vehicles;
