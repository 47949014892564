import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";

const options = [
  {
    value: "Last 7 days",
    range: [moment().subtract(7, "days"), moment()],
  },
  {
    value: "Last 30 days",
    range: [moment().subtract(1, "month"), moment()],
  },
  {
    value: "Last 3 months",
    range: [moment().subtract(3, "months"), moment()],
  },
  {
    value: "Last 6 months",
    range: [moment().subtract(6, "months"), moment()],
  },
  {
    value: "Last 1 year",
    range: [moment().subtract(1, "year"), moment()],
  },
  { value: "Custom" },
];

const RangeSelector = ({
  initialRange,
  range,
  setRange,
  setRangeInWords,
  disableMaxLimit = true,
  disableShadow = false,
}: any) => {
  const [dateRangePickerDialogOpen, setDateRangePickerDialog] = useState(false);
  const [value, setValue] = useState(initialRange || "Last 30 days");

  const handleSubmit = (from: Moment, to: Moment) => {
    setRange([from, to]);
    setValue("Custom");
    setDateRangePickerDialog(false);
  };

  const isCustomRangeSelected = value === "Custom" && !!range;
  function getSelectedRangeText(verbose = false) {
    const differentYears = range[0].year() !== range[1].year();
    const format = differentYears
      ? verbose
        ? "DD MMM YYYY"
        : "DD MMM 'YY"
      : "D MMM";
    return `${range[0].format(format)} – ${range[1].format(format)}`;
  }

  return (
    <>
      <Tooltip
        placement="top"
        title={isCustomRangeSelected ? getSelectedRangeText(true) : undefined}
      >
        <Select
          size="small"
          className={`rounded${disableShadow ? "" : " shadow"}`}
          sx={{ width: 170 }}
          value={value}
          onChange={(e) => {
            if (e.target.value !== "Custom") {
              setValue(e.target.value);
              setRange(
                options.find((el) => el.value === e.target.value)!.range,
              );
            }
            if (setRangeInWords) {
              setRangeInWords(e.target.value);
            }
          }}
          renderValue={(selected) =>
            isCustomRangeSelected ? getSelectedRangeText() : selected
          }
        >
          {options.map((el, i) => (
            <MenuItem
              key={i}
              value={el.value}
              onClick={() => {
                if (el.value === "Custom") setDateRangePickerDialog(true);
              }}
            >
              {el.value}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
      <DateRangePickerDialog
        open={dateRangePickerDialogOpen}
        handleClose={() => setDateRangePickerDialog(false)}
        range={range}
        setRange={setRange}
        handleSubmit={handleSubmit}
        disableMaxLimit={disableMaxLimit}
      />
    </>
  );
};

const DateRangePickerDialog = ({
  open,
  handleClose,
  range,
  handleSubmit,
  disableMaxLimit = false,
}: any) => {
  const [from, setFrom] = useState<Moment | null>(
    !!range?.[0] ? moment(range[0]) : null,
  );
  const [to, setTo] = useState<Moment | null>(
    !!range?.[1] ? moment(range[1]) : null,
  );

  useEffect(() => {
    if (range && range[0] && range[1]) {
      setFrom(moment(range[0]));
      setTo(moment(range[1]));
    }
  }, [range]);

  const isMoreThanMaxLimit = disableMaxLimit
    ? false
    : from && to && to.diff(from, "months", true) > 6.01; // 6 months + ~1 day

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Select Date Range</DialogTitle>
      <DialogContent>
        <Box py={4} mx="auto" maxWidth={250} width={1}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="From"
              value={from}
              onChange={(newValue) => {
                setFrom(newValue);
                if (!!to && moment(newValue).isAfter(to)) {
                  setTo(null);
                }
              }}
              format="DD/MM/YYYY"
              disableFuture
              // shouldDisableDate={(day) => {
              //   return to ? moment(day).isAfter(to) : false;
              // }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: isMoreThanMaxLimit as boolean,
                },
              }}
            />
            <DatePicker
              sx={{ mt: 3.5 }}
              label="To"
              value={to}
              onChange={(newValue) => {
                setTo(newValue);
                if (!!from && moment(newValue).isBefore(from)) {
                  setFrom(null);
                }
              }}
              format="DD/MM/YYYY"
              disableFuture
              // shouldDisableDate={(day) => {
              //   return from ? moment(day).isBefore(from) : false;
              // }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: isMoreThanMaxLimit as boolean,
                  helperText: isMoreThanMaxLimit
                    ? "Date range should be less than 6 months"
                    : undefined,
                },
              }}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => handleSubmit(from, to)}
          disabled={!from || !to || (isMoreThanMaxLimit as boolean)}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RangeSelector;
